import { ref, computed } from "vue";
import widgetPreview from './widgetPreview.vue';
export default {
  name: "widgetPresenter",
  components: {
    widgetPreview
  },
  props: {
    data: {
      type: Object,
      default: () => {
        return {};
      }
    },
    isPreview: {
      type: Boolean,
      default: false
    },
    type: {
      type: String,
      default: ''
    },
    theme: {
      type: Object,
      default: () => {
        return {};
      }
    }
  },
  setup(props, {
    emit
  }) {
    let token = ref('');
    let locale = window.Kf.locale.value.replace("-", "");
    token.value = localStorage.getItem("KC_TOKEN");
    let showStatePanel = ref(false);
    const artPreClose = function () {
      showStatePanel.value = false;
      //emit("update:isPreview", false);
      emit("close", false);
    };
    const widgetType = computed(() => {
      //return props?.data?.spaceFlag === 1 ? 'space' : 'configuration'
      return 'space';
    });
    const widgetName = computed(() => {
      var _props$data, _props$data2, _props$data3;
      let _name = (props === null || props === void 0 || (_props$data = props.data) === null || _props$data === void 0 ? void 0 : _props$data.name) || (props === null || props === void 0 || (_props$data2 = props.data) === null || _props$data2 === void 0 || (_props$data2 = _props$data2.sceneData) === null || _props$data2 === void 0 ? void 0 : _props$data2.name);
      if (props !== null && props !== void 0 && (_props$data3 = props.data) !== null && _props$data3 !== void 0 && _props$data3.labelInterpret && props.data.labelInterpret !== null) {
        var _props$data4, _props$data5;
        if (typeof (props === null || props === void 0 || (_props$data4 = props.data) === null || _props$data4 === void 0 ? void 0 : _props$data4.labelInterpret) === 'string') {
          const _label = JSON.parse(props.data.labelInterpret);
          if (_label !== null && _label !== void 0 && _label[locale]) {
            _name = _label[locale];
          }
        }
        if (props !== null && props !== void 0 && (_props$data5 = props.data) !== null && _props$data5 !== void 0 && (_props$data5 = _props$data5.labelInterpret) !== null && _props$data5 !== void 0 && _props$data5[locale]) {
          _name = props.data.labelInterpret[locale];
        }
      }
      return _name;
    });
    return {
      token,
      artPreClose,
      widgetType,
      widgetName,
      showStatePanel
    };
  }
};