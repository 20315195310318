import "core-js/modules/es.error.cause.js";
import { computed, onMounted, ref, nextTick, watch, watchEffect, reactive } from "vue";
import userModel from "../../model/user";
import contentModel, { isTronThemeDetails } from "../../model/newContent";
import projectModel from "@/model/project";
import aboutDialog from "../header/about.vue";
import contactUs from "../header/contactUs";
import { ElMessage, ElMessageBox } from 'element-plus';
import userService from '@/service/user';
import tools from "@/utils/tools";
import projectService from "@/service/project";
import { removeToken } from "@/utils/auth";
import customization from "@/model/customization";
import oemManager from '@/utils/oem';
import Const from "@/utils/const";
import { useRoute } from "vue-router";
import { setToken, getToken } from "@/utils/auth";
import i18n, { languageList } from '@/i18n';
import url from "../../styles/img/userImg.png";
export default {
  name: "mainContentLeft",
  components: {
    contactUs,
    aboutDialog
  },
  setup() {
    const loginUserInfo = computed(() => {
      var _userModel$user$value;
      let user = JSON.parse(localStorage.getItem('KC_USER'));
      if ((_userModel$user$value = userModel.user.value) !== null && _userModel$user$value !== void 0 && _userModel$user$value.userName) {
        user = userModel.user.value;
      }
      return user;
    });
    let editGroup = ref(false);
    let addGroupVisible = ref(false);
    let newGroupName = ref('');
    let dialogKingfisherAbout = ref(false);
    let dialogContactUs = ref(false);
    let tutorialVideoVisible = ref(false);
    let currMenu = ref({});
    let searchGroup = ref('');
    let menuList = ref([{
      name: Kf.t('MyFile2'),
      code: 'file',
      key: 'MyFile2'
    }, {
      name: Kf.t('Tutorial'),
      code: 'tutorial',
      key: 'Tutorial'
    }, {
      name: Kf.t('Library'),
      code: 'library',
      key: 'Library'
    }]);
    let show = ref(true);
    const menuList1 = computed(() => {
      return [{
        name: Kf.t('Wastebin'),
        code: "frecycleBin",
        key: 'Wastebin'
      }, /* {name: Kf.t('HelpDocument'), code: "updateDocs", key: 'HelpDocument'},*/
      {
        name: Kf.t('AboutUs'),
        code: "contact",
        key: 'AboutUs'
      }];
    });
    // 不需要项目分组的白名单
    const noProGroupWhite = reactive(["frecycleBin"]);
    const handleMenu = function (item, isDomClick) {
      var _contentModel$currMen;
      if (isDomClick && ((_contentModel$currMen = contentModel.currMenu.value) === null || _contentModel$currMen === void 0 ? void 0 : _contentModel$currMen.code) === item.code) {
        return;
      }
      contentModel.currHeaderTab.value = '';
      contentModel.isProject.value = false;
      contentModel.currHeaderTab.value = '';
      currMenu.value = item;
      contentModel.currMenu.value = item;
      isTronThemeDetails.value = false;
      if (item.code === 'file') {
        projectModel.loadProject();
      } else {
        contentModel.loadMyProject(0, res => {
          if (item.code === 'library') {
            contentModel.loadCategory(res); //查询所有空间组件
            //contentModel.loadThemeGroups(res)
          }
        });
      }
      projectModel.sceneList.value = [];
      // contentModel.currLibTab.value = [];
      // projectModel.pageList.value = [];
    };
    let host = ref('');
    let groupValue = ref(Kf.t('Default'));
    onMounted(() => {
      const route = useRoute();
      if (route.params.ticket) {
        const svsLogin = ticket => {
          window.Kf = window.Kf || {};
          window.Kf.onLoginSuccess = result => {
            userModel.updateUser(result);
            console.log('user', result);
            setToken(result.kfSysMsg);
            localStorage.setItem('KC_USER', JSON.stringify(result.data));
            localStorage.setItem('KC_TOKEN', result.kfSysMsg);
            init();
          };
          localStorage.setItem('KC_TOKEN', ticket);
          let autoLogin = function () {
            if (window.Kf.login && window.Kf.ssoReady) {
              clearInterval(timer);
              window.Kf.login("", "");
            }
          };
          let timer = setInterval(autoLogin, 50);
        };
        svsLogin(route.params.ticket);
      } else {
        init();
      }
    });
    const init = function () {
      var _window;
      if (((_window = window) === null || _window === void 0 || (_window = _window.performance) === null || _window === void 0 || (_window = _window.navigation) === null || _window === void 0 ? void 0 : _window.type) === 0) {
        contentModel.isProject.value = false;
        contentModel.currMenu.value = {};
      }
      projectModel.spaceList.value = [];
      contentModel.myProject.value = {};
      isTronThemeDetails.value = false;
      if (!contentModel.isProject.value) {
        if (contentModel.currMenu.value && contentModel.currMenu.value.code === undefined) {
          currMenu.value = menuList.value[0];
          contentModel.currMenu.value = menuList.value[0];
          handleMenu(contentModel.currMenu.value);
        } else {
          var _contentModel$currMen2;
          if (((_contentModel$currMen2 = contentModel.currMenu.value) === null || _contentModel$currMen2 === void 0 ? void 0 : _contentModel$currMen2.code) === 'file') {
            currMenu.value = contentModel.currMenu.value;
            handleMenu(currMenu.value);
          } else {
            handleMenu(contentModel.currMenu.value);
          }
        }
      } else {
        if (contentModel.currGroup.value === undefined) {
          handleGroup(Kf.t('Default'));
        } else {
          groupValue.value = contentModel.currGroup.value;
        }
      }
      contentModel.loadComponentLib();
      contentModel.loadFileData();
      let url = window.location.href;
      host.value = url.substring(0, url.indexOf("/#/"));
      if ('development' === process.env.NODE_ENV) {
        host.value = 'http://dev.kingfisher.live:8000/cloud';
      }
    };
    const handleGroup = function (group) {
      groupValue.value = group;
      contentModel.isProject.value = true;
      contentModel.currMenu.value = {};
      contentModel.currGroup.value = groupValue.value;
      contentModel.currLeftContentName.value = '';
      projectModel.sceneList.value = [];
      contentModel.currLeftContent.value = {};
      projectModel.currProject.value = {};
    };
    const addGroup = function () {
      addGroupVisible.value = true;
      newGroupName.value = '';
    };
    const doAddGroup = function () {
      projectModel.projectGroupList.value[newGroupName.value] = [];
      addGroupVisible.value = false;
    };
    const change = function (val) {
      dialogKingfisherAbout.value = val;
    };
    const handleMenu1 = function (menu) {
      switch (menu.code) {
        case 'frecycleBin':
          isTronThemeDetails.value = false;
          currMenu.value = menu;
          contentModel.currMenu.value = menu;
          break;
        case 'updateDocs':
          let _url = '/cloud/resources/doc/tronDoc.pdf';
          if (_url.indexOf('3dc/') === -1) {
            _url = _url.replace('3dc/', '');
          }
          window.open(_url, "_blank");
          break;
        case 'contact':
          dialogContactUs.value = true;
          break;
        // case 'market':
        //     let token = getToken()
        //     let _sourceHost = contentModel.getHost()
        //     const url = tronMarketUrl.value + `3dc/index.html#/login/${token}?param=` + _sourceHost;
        //     window.open(url, "_blank");
        //     break;
        // case 'deploymentEnvironmentDownload':
        //     if (tronRunTimePack.value) {
        //         window.open(tronRunTimePack.value, "_blank");
        //     }
        //     break;
        // case 'SDKFiles':
        //     let url1 = '/cloud/resources/doc/tronSdk.pdf';
        //     if (url1.indexOf('3dc/') === -1) {
        //         url1 = url1.replace('3dc/', '');
        //     }
        //     window.open(url1, "_blank");
        //     break;
        // case 'DeploymentDocument':
        //     let url2 = '/cloud/resources/doc/tronRuntime.pdf';
        //     if (url2.indexOf('3dc/') === -1) {
        //         url2 = url2.replace('3dc/', '');
        //     }
        //     window.open(url2, "_blank");
        //     break;
      }
    };
    const changeContactUs = function (val) {
      dialogContactUs.value = val;
    };
    let editUserVisible = ref(false);
    let editUserData = ref({
      orgPwd: '',
      newPwd: '',
      sureNewPwd: '',
      userName: '',
      userActor: ''
    });
    let checknewPwd = function (value, callback) {
      if (value == '') {
        if (callback) {
          callback(new Error(Kf.t('TheNewPasswordCannotBeEmpty')));
        }
        return false;
      } else {
        const reg = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[$@$!%*?&])[A-Za-z\d$@$!%*?&]{8,}/;
        if (!reg.test(value)) {
          if (callback) {
            callback(new Error(Kf.t('NewPasswordFormatError')));
          }
          return false;
        }
      }
      return true;
    };
    const checkSureNewPwd = function (value, callback) {
      if (value == '') {
        if (callback) {
          callback(new Error(Kf.t('ConfirmThatTheNewPasswordIsNotEmpty')));
        }
        return false;
      } else {
        if (value != editUserData.value.newPwd) {
          if (callback) {
            callback(new Error(Kf.t('ConfirmThatTheNewPasswordDoesNotMatchTheNewPassword')));
          }
          return false;
        }
      }
      return true;
    };
    let check = function (rule, value, callback) {
      if (rule.field === 'newPwd') {
        checknewPwd(value, callback);
      }
      if (rule.field === 'sureNewPwd') {
        checkSureNewPwd(value, callback);
      }
    };
    let spaceData = ref({
      used: 0,
      total: 0,
      percentage: 0
    });
    const loadUseSpace = function () {
      projectService.getUseSpace().then(result => {
        if (result) {
          spaceData.value = result;
        }
      });
    };
    const handleEditUser = function () {
      loadUseSpace();
      editUserVisible.value = true;
      editUserData.value.userName = loginUserInfo.value.userName || '';
      editUserData.value.userId = loginUserInfo.value.userId || '';
      editUserData.value.screenshot = loginUserInfo.value.screenshot || '';
      /* if (loginUserInfo.value?.userId) {
           editUserVisible.value = true;
           editUserData.value = {
               orgPwd: '',
               newPwd: '',
               sureNewPwd: ''
           }
           editUserData.value.userName = loginUserInfo.value.userName
           editUserData.value.userId = loginUserInfo.value.userId;
           editUserData.value.screenshot = loginUserInfo.value.screenshot ? loginUserInfo.value.screenshot : '';
       } else {
           ElMessage({
               type: 'warning',
               message: Kf.t('InformationAnomaly') + Kf.t('Comma') + Kf.t('PleaseReenterAndTryAgain'),
               grouping: true
           });
       }*/
    };
    let uploadImgBox1 = ref(null);
    const uploadResource1 = function (item) {
      let param = new FormData();
      param.append('file', item.file);
      projectService.uploadUserImg(param).then(result => {
        if (result && result.code == 200) {
          doUploadSuccess1(result);
        } else {
          ElMessage({
            type: 'warning',
            message: result.message,
            grouping: true
          });
        }
      });
    };
    const doUploadSuccess1 = function (response, file, fileList) {
      uploadImgBox1.value.clearFiles();
      if ((response === null || response === void 0 ? void 0 : response.code) == 200) {
        editUserData.value.screenshot = response.data;
        userModel.userScreenshot.value = response.data;
        loginUserInfo.value.screenshot = response.data;
      } else {
        ElMessage({
          type: 'warning',
          message: response.message,
          grouping: true
        });
      }
    };
    const userImage = computed(() => {
      let res = require('../../styles/img/userImg.png');
      if (userModel.userScreenshot.value) {
        res = host.value + Const.servicePath.value + '/api/repo/kf-pub-resource/load-file?uuid=' + userModel.userScreenshot.value;
      }
      return res;
    });
    let userRules = ref({
      orgPwd: [{
        required: true,
        message: Kf.t('TheOldPasswordCantBeEmpty'),
        trigger: 'blur'
      }],
      newPwd: [{
        required: true,
        trigger: 'blur',
        validator: check
      }],
      sureNewPwd: [{
        required: true,
        trigger: 'blur',
        validator: check
      }]
    });
    const doEditUser = function () {
      if (editUserData.value.orgPwd === '' && editUserData.value.newPwd === '' && editUserData.value.sureNewPwd === '') {
        userService.updatePwd(editUserData.value).then(result => {
          ElMessage({
            type: "success",
            customClass: 'kf-3dc-create-success',
            message: Kf.t('ModifiedSuccessfully'),
            grouping: true
          });
          loginUserInfo.value.userName = editUserData.value.userName;
          loginUserInfo.value.screenshot = editUserData.value.screenshot;
          localStorage.setItem('KC_USER', JSON.stringify(loginUserInfo.value));
          editUserVisible.value = false;
        });
      } else {
        if (editUserData.value.orgPwd === '') {
          ElMessage({
            type: 'warning',
            message: Kf.t('TheOldPasswordCantBeEmpty') + ',' + Kf.t('PleaseEnter'),
            grouping: true
          });
          return;
        }
        if (checknewPwd(editUserData.value.newPwd) && editUserData.value.orgPwd !== '' && checkSureNewPwd(editUserData.value.newPwd)) {
          userService.updatePwd(editUserData.value).then(result => {
            ElMessage({
              type: "success",
              grouping: true,
              customClass: 'kf-3dc-create-success',
              message: Kf.t('ItsBeenModifiedPleaseReland')
            });
            localStorage.removeItem('KC_USER');
            localStorage.removeItem('KC_TOKEN');
            router.replace({
              path: '/'
            });
          });
        }
      }
    };
    const getGroups = function () {
      return Object.keys(projectModel.projectGroupList.value).filter(comp => {
        return !searchGroup.value || tools.pinyinMatch(comp, searchGroup.value);
      });
    };
    const editGroups = function (item, event) {
      if (item == Kf.t('Default')) {
        return false;
      }
      editGroup.value = true;
      event.target.contentEditable = true;
      event.target.focus();
      document.execCommand('selectAll');
    };
    const changeGroup = function (item, event) {
      if (event.target.innerText == '') {
        ElMessage({
          type: "warning",
          message: Kf.t('CannotBeEmpty'),
          grouping: true
        });
      } else {
        var _list;
        editGroup.value = false;
        let list = [];
        list = projectModel.projectGroupList.value[contentModel.currGroup.value];
        list.forEach(project => {
          project.groups = event.target.innerText.trim();
        });
        event.target.innerHTML = event.target.innerText.trim();
        if (((_list = list) === null || _list === void 0 ? void 0 : _list.length) > 0) {
          projectService.updateProjects(list).then(function (res) {
            if ((res === null || res === void 0 ? void 0 : res.status) == '406') {
              ElMessage({
                type: "error",
                message: Kf.t('UserSRemainingSpaceIsNotEnough'),
                grouping: true
              });
            } else if (res == 'success') {
              projectModel.loadProject(event.target.innerText.trim());
              groupValue.value = event.target.innerText.trim();
              contentModel.isProject.value = true;
            } else {
              ElMessage({
                type: "error",
                message: res,
                grouping: true
              });
            }
          });
        }
      }
    };
    const doLoginOut = function () {
      localStorage.removeItem('KC_USER');
      localStorage.removeItem('KC_TOKEN');
      removeToken();
      contentModel.currHeaderTab.value = {};
      //contentModel.currLeftContent.value = {};
      projectModel.currProject.value = {};
      userModel.updateUser({});
      router.replace({
        path: '/'
      });
    };
    let logo = ref();
    const currLanguage = computed(() => {
      const {
        locale,
        t
      } = i18n.global;
      return locale.value;
    });
    const changeLocale = function (_locale) {
      ElMessageBox.confirm(Kf.t('AreYouSureYouWantToSwitchLanguagesAndReload'), Kf.t('Hint'), {
        confirmButtonText: Kf.t('Confirm'),
        cancelButtonText: Kf.t('Cancel'),
        type: "warning"
      }).then(() => {
        i18n.global.value = _locale;
        localStorage.setItem('locale', _locale);
        console.log("changeLocale", i18n.global, window.Kf, Kf);
        window.Kf.locale.value = _locale;
        window.location.reload();
      }).catch(() => {});
    };
    return {
      logo,
      change,
      changeContactUs,
      dialogKingfisherAbout,
      loginUserInfo,
      dialogContactUs,
      currMenu,
      menuList,
      handleMenu,
      menuList1,
      projectList: projectModel.projectGroupList,
      currProject: projectModel.currProject,
      isProject: contentModel.isProject,
      groupValue,
      addGroup,
      addGroupVisible,
      doAddGroup,
      newGroupName,
      handleMenu1,
      handleGroup,
      noProGroupWhite,
      tutorialVideoVisible,
      handleEditUser,
      doEditUser,
      editUserVisible,
      editUserData,
      checkSureNewPwd,
      checknewPwd,
      userRules,
      searchGroup,
      getGroups,
      show,
      editGroup,
      changeGroup,
      editGroups,
      spaceData,
      doLoginOut,
      customization,
      uploadImgBox1,
      doUploadSuccess1,
      userImage,
      uploadResource1,
      host,
      currLanguage,
      languageList: languageList,
      changeLocale
    };
  }
};