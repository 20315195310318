<template>
    <div class="">
        <el-dialog custom-class="kf-3dc-dialog3 kf-no-footer" :title="$t('Copy2')" v-model="showDialog" width="800px"
                   :close-on-click-modal="false" :show-close="false"
                   draggable>
            <div class="kf-3dc-dialog-body mt16">


                <div class="kf-3dc-dialog-contents" v-if="isCreate">
                    <div class="kf-3dc-dialog-select-label">{{ $t('User') }}</div>
                    <div class="kf-3dc-dialog-select-con kingfisher_select-new1" style="height: 32px">
                        <el-select v-model="addUsers" :placeholder="$t('PleaseSelect')"
                                   collapse-tags collapse-tags-tooltip
                                   multiple filterable clearable popper-class="kf-mul-select-option">
                            <el-option v-for="(item,index) in userList"
                                       :key="item.userId"
                                       :label="item.userName"
                                       :value="item.userId+'-/'+item.userName"></el-option>
                        </el-select>
                    </div>
                </div>
                <div class="kf-3dc-dialog-contents" v-else>
                    <div class="kf-3dc-dialog-select-label">{{ $t('UserName') }}</div>
                    <div class="kf-3dc-dialog-select-con kingfisher_input-new1" style="height: 35px">
                        <el-input v-model="editUserData.userName" readonly></el-input>
                    </div>
                </div>

                <div class="kf-3dc-dialog-contents mt16">
                    <div class="kf-3dc-dialog-select-label">{{ $t('Role') }}</div>
                    <div class="kf-3dc-dialog-select-con kingfisher_select-new1" style="height: 32px">
                        <el-select v-model="editUserData.role" :placeholder="$t('PleaseSelect')" popper-class="kf-mul-select-option">
                            <el-option v-for="(item,index) in roleOptions"
                                       :key="item.value"
                                       :label="item.name"
                                       :value="item.value"></el-option>
                        </el-select>
                    </div>
                </div>

                <div class="kf-3dc-dialog-contents mt16">
                    <div class="kf-3dc-dialog-select-label">{{ $t('PeriodOfValidity') }}</div>
                    <div class="kf-3dc-dialog-select-con kingfisher_select-new1 pr" style="height: 32px">
                        <div class="col_authority_time">{{ timeLabel }}</div>
                        <el-date-picker :locale="locale"
                                        v-model="editUserData.timeout"
                                        type="date"
                                        :clearable="false"
                                        popper-class="col_authority_date"
                                        :shortcuts="shortcuts"
                                        @change="editTimeChange"
                        >
                        </el-date-picker>
                    </div>
                </div>

            </div>
            <div class="kf-3dc-dialog-footer mt20 mb10">
                <div class="kf-dialog-left-btn-cancel mr15" @click="showDialog = false">{{ $t('Cancel') }}
                </div>
                <div class="kf-dialog-left-btn-sure " @click="doEditUser()">{{ $t('Confirm') }}
                </div>
            </div>
        </el-dialog>
    </div>
</template>

<script>
import {ref, watch} from 'vue'
import authorityService from "@/service/authority";
import projectModel from "@/model/project";
import {ElMessage} from "element-plus";
import {deepCopyObject} from '@/utils/tools'

export default {
    name: "userEditDialog",
    props: {
        show: {
            type: Boolean,
            default: false,
        },
        isCreate: {
            type: Boolean,
            default: false,
        },
        currUser: {
            type: Object,
            default: () => {
                return {}
            },
        }
    },
    setup(props, {emit}) {
        let showDialog = ref(false);
        watch(showDialog, () => {
            emit('propChange', showDialog.value);
        });
        let editUserData = ref({})
        let addUserList = ref({})
        let addUsers = ref([]);
        let userList = ref([])
        let timeLabel = ref('')
        let locale = window.Kf.locale.value.replace("-", "");
        let roleOptions = ref([
            {name: Kf.t('ProjectAdministrator'), value: 'management'},
            {name: Kf.t('Editor'), value: 'editorial'},
            {name: Kf.t('Viewers'), value: 'observer'}
        ]);
        let shortcuts = ref([
            {text: Kf.t('ForeverValid'), value: "all"}
        ]);
        watch(props, (newValue) => {
            showDialog.value = newValue.show;
            if (showDialog.value) {
                editUserData.value = {};
                if (newValue.isCreate) {
                    editUserData.value.isCreate = true;
                    addUsers.value = [];
                    authorityService.getUser(projectModel.currProject.value.uuid).then(result => {
                        userList.value = result
                    });
                } else {
                    editUserData.value = deepCopyObject(newValue.currUser);
                    editUserData.value.isCreate = false;
                    editUserData.value.users = [];
                    editUserData.value.users.push({
                        userId: newValue.currUser.userId,
                        userName: newValue.currUser.userName
                    });
                    console.log("--watch",newValue.currUser,newValue.currUser.timeout);
                    timeLabel.value = newValue.currUser.timeout;
                    if (newValue.currUser.timeout === 0 || newValue.currUser.timeout === '0') {
                        timeLabel.value = Kf.t('ForeverValid');
                        editUserData.value.timeout = "";
                    }
                }
                console.log("--watch-timeLabel",timeLabel.value);
            }
        });
        const editTimeChange = function (val) {
            let _today=getTime(new Date())
            let currDate=getTime(val)
            if (val.toString() === 'Invalid Date') {
                editUserData.value.timeout = 0;
                timeLabel.value = Kf.t('ForeverValid');
            } else if(currDate<_today){
                ElMessage({
                    message: Kf.t('PleaseSelectTheCorrectDate'),
                    type: 'warning',group:true
                });
            }else{
                timeLabel.value = currDate;
                editUserData.value.timeout = currDate;
            }
        }
        const checkTime = function (item) {
            if (item < 10) {
                return '0' + item;
            } else {
                return item
            }
        }
        const getTime = function (val) {
            return val.getFullYear() + "-" + checkTime(val.getMonth() + 1) + '-' + checkTime(val.getDate())
        };

        const doEditUser = function () {
            if (props.isCreate) {
                if (addUsers.value.length > 0) {
                    let user = [];
                    addUsers.value.forEach(item => {
                        user.push({userId: item.split('-/')[0], userName: item.split('-/')[1]});
                    })
                    editUserData.value.users = user;
                }
            }
            update(editUserData.value);
        }
        const update = function (data) {
            if (data.userId !== '' && (data.timeout && data.timeout !== '' || data.timeout == 0) && data.role !== '') {
                data.projectId = projectModel.currProject.value.uuid;
                authorityService.update(data).then(result => {
                    if (result === 'success') {
                        ElMessage({
                            message: props.isCreate ? Kf.t('AddedSuccessfully') : Kf.t('ModifiedSuccessfully'),
                            type: 'success',group:true
                        });
                        showDialog.value = false
                        projectModel.loadAuthorityList(projectModel.currProject.value);
                    } else {
                        ElMessage({
                            message: result,
                            type: 'warning',group:true
                        });
                    }
                });
            } else {
                ElMessage({
                    message: Kf.t('CannotBeEmpty'),
                    type: 'warning',group:true
                })
            }
        }
        return {
            showDialog,
            addUsers, timeLabel, userList, addUserList, editUserData,
            locale, roleOptions, shortcuts, doEditUser, editTimeChange
        }
    }
}
</script>

<style lang="scss">
.kf-3dc-dialog3.kf-no-footer{
    .el-dialog__footer{
        height: 0;
    }
    .kingfisher_select-new1{
        .el-select{
            width: 100%;
            .el-input__inner{
                height: 32px;
                line-height: 32px;
                box-shadow: none;
                color: rgba(255, 255, 255, 0.7);
            }
        }
        .el-input{
            .el-input__inner{
                color: rgba(255, 255, 255, 0.7);
            }
        }
        .el-date-editor.el-input{
            width: 100%;
        }
    }
    .col_authority_time{
        position: absolute;
        z-index: 99;
        top: 1px;
        background: #000;
        left:30px;
        height: calc(100% - 2px);
        width: calc(100% - 30px - 40px);
        color: rgba(255, 255, 255, 0.7);
        font-size: 14px;
        display: flex;
        align-items: center;
        justify-content: flex-start;
    }
    .kf-3dc-dialog-select-con.kingfisher_input-new1{
        .el-input{
            width: 100%;
            .el-input__inner{
                color: rgba(255, 255, 255, 0.7);
            }
        }

    }

    .kf-3dc-dialog-select-con.kingfisher_select-new1{
.el-select{
    .select-trigger{
        .el-select__tags{
            .el-select-tags-wrapper{
                .el-tag{
                    background: #303030;
                    color: rgba(255,255,255,0.5);

                }
            }
        }
    }
}
    }
}
.kf-mul-select-option.el-select__popper.el-popper{
    .kf-mul-select-option{
        .el-scrollbar{
            .el-scrollbar__view{
                .el-select-dropdown__item{

                }
                .el-select-dropdown__item.hover{
                    background: transparent;
                    color: #fff;
                }
                .el-select-dropdown__item.selected{
                    background: transparent;
                    color: #0094FF;
                }
                .el-select-dropdown__item.selected.hover{
                    background: transparent;
                    color: #0094FF;
                }
            }
        }
    }

}
.el-picker__popper.col_authority_date.el-popper {
    border-color: #303030;
    background: #000;
    .el-picker-panel{
        background: transparent;
        color: #fff;
    }
    .el-picker-panel__sidebar {
        background: transparent;
        border-color: #2A2B31;

        .el-picker-panel__shortcut {
            background: #0094FF;
            color: #fff;
            font-size: 12px;
        }
    }
    .el-picker-panel__body{
        .el-date-picker__header--bordered {
            border-color: #303030;
        }
        .el-date-picker__header{
            i{
                color: #fff;
            }
            .el-date-picker__header-label{
                color: #e4e4e4;
            }
        }
        .el-picker-panel__content{
            .el-date-table{
                th{
                    border-color: #303030;
                    color:  #e4e4e4;
                }
            }
            .el-date-table td.next-month, .el-date-table td.prev-month{
                color:  rgba(255,255,255,0.6);
            }
        }
    }
}
</style>