import { resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, withCtx as _withCtx, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode } from "vue";
const _hoisted_1 = {
  class: "kf-newContent-authority-container w-100 h-100"
};
const _hoisted_2 = {
  key: 0
};
const _hoisted_3 = {
  key: 1
};
const _hoisted_4 = {
  key: 0,
  class: "operation_btn_box"
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_el_table_column = _resolveComponent("el-table-column");
  const _component_el_button = _resolveComponent("el-button");
  const _component_el_table = _resolveComponent("el-table");
  const _component_user_edit_dialog = _resolveComponent("user-edit-dialog");
  return _openBlock(), _createElementBlock("div", _hoisted_1, [_createVNode(_component_el_table, {
    data: $setup.getDataList(),
    style: {
      "width": "100%"
    },
    "empty-text": _ctx.$t('DataNotAvailable'),
    height: "100%"
  }, {
    default: _withCtx(() => [_createVNode(_component_el_table_column, {
      label: _ctx.$t('UserName'),
      sortable: "",
      prop: "userName",
      "min-width": "18%"
    }, null, 8, ["label"]), _createVNode(_component_el_table_column, {
      prop: "role",
      label: _ctx.$t('Role'),
      sortable: ""
    }, {
      default: _withCtx(scope => [_createTextVNode(_toDisplayString($setup.roleChange(scope.row.role)), 1)]),
      _: 1
    }, 8, ["label"]), _createVNode(_component_el_table_column, {
      prop: "timeout",
      label: _ctx.$t('PeriodOfValidity'),
      sortable: ""
    }, {
      default: _withCtx(scope => [scope.row.timeout == 0 ? (_openBlock(), _createElementBlock("span", _hoisted_2, _toDisplayString(_ctx.$t('ForeverValid')), 1)) : (_openBlock(), _createElementBlock("span", _hoisted_3, _toDisplayString(scope.row.timeout), 1))]),
      _: 1
    }, 8, ["label"]), _createVNode(_component_el_table_column, {
      label: _ctx.$t('Operation'),
      "min-width": "20%",
      class: "kf-data-table-cell"
    }, {
      default: _withCtx(scope => [scope.row.role !== 'creator' ? (_openBlock(), _createElementBlock("div", _hoisted_4, [_createVNode(_component_el_button, {
        type: "text",
        size: "small",
        onClick: $event => $setup.handleDelete(scope.row)
      }, {
        default: _withCtx(() => [_createTextVNode(_toDisplayString(_ctx.$t('Delete')), 1)]),
        _: 2
      }, 1032, ["onClick"]), _createVNode(_component_el_button, {
        type: "text",
        size: "small",
        onClick: $event => $setup.handleEdit(scope.row)
      }, {
        default: _withCtx(() => [_createTextVNode(_toDisplayString(_ctx.$t('Edit')), 1)]),
        _: 2
      }, 1032, ["onClick"])])) : _createCommentVNode("", true)]),
      _: 1
    }, 8, ["label"])]),
    _: 1
  }, 8, ["data", "empty-text"]), _createVNode(_component_user_edit_dialog, {
    show: $setup.dialogEditRoleVisible,
    isCreate: false,
    onPropChange: $setup.updateVisible,
    currUser: $setup.editRoleList
  }, null, 8, ["show", "onPropChange", "currUser"])]);
}