import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, withModifiers as _withModifiers, createCommentVNode as _createCommentVNode } from "vue";
const _hoisted_1 = {
  class: "kf-space-preview-container pr"
};
const _hoisted_2 = {
  key: 0,
  class: "kf-space-filter-box"
};
const _hoisted_3 = ["onClick"];
const _hoisted_4 = {
  key: 0,
  class: "flex-row-start-center kf-space-list"
};
const _hoisted_5 = ["onClick"];
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_el_checkbox = _resolveComponent("el-checkbox");
  const _component_widgetPreview = _resolveComponent("widgetPreview");
  return _openBlock(), _createElementBlock("div", _hoisted_1, [$props.showPanel ? (_openBlock(), _createElementBlock("div", _hoisted_2, [(_openBlock(true), _createElementBlock(_Fragment, null, _renderList($setup.dataList, (group, index) => {
    var _group$list;
    return _openBlock(), _createElementBlock("div", {
      class: "kf-space-title",
      key: 'group' + index
    }, [_createElementVNode("div", {
      class: "flex-row-start-center gap-8 w-100",
      onClick: _withModifiers($event => $setup.updateDataItem(group), ["stop", "prevent"])
    }, [_createElementVNode("div", null, _toDisplayString(group.label ? group.label[$setup.local] : group.name), 1), _createVNode(_component_el_checkbox, {
      modelValue: group.active,
      "onUpdate:modelValue": $event => group.active = $event
    }, null, 8, ["modelValue", "onUpdate:modelValue"])], 8, _hoisted_3), (group === null || group === void 0 || (_group$list = group.list) === null || _group$list === void 0 ? void 0 : _group$list.length) > 0 ? (_openBlock(), _createElementBlock("div", _hoisted_4, [(_openBlock(true), _createElementBlock(_Fragment, null, _renderList(group.list, (item, index2) => {
      return _openBlock(), _createElementBlock("div", {
        class: "flex-row-start-center gap-8",
        key: item.name + index2,
        onClick: _withModifiers($event => $setup.updateDataItem(item, group), ["stop", "prevent"])
      }, [_createVNode(_component_el_checkbox, {
        modelValue: item.active,
        "onUpdate:modelValue": $event => item.active = $event,
        disabled: !group.active
      }, null, 8, ["modelValue", "onUpdate:modelValue", "disabled"]), _createElementVNode("div", null, _toDisplayString(item.label ? item.label[$setup.local] : item.name), 1)], 8, _hoisted_5);
    }), 128))])) : _createCommentVNode("", true)]);
  }), 128))])) : _createCommentVNode("", true), _createVNode(_component_widgetPreview, {
    props: $props.props,
    args: {
      floor: $setup.currFloor,
      type: $setup.type,
      subType: $setup.subType
    },
    type: "spaceScene"
  }, null, 8, ["props", "args"])]);
}