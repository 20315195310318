import { openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode } from "vue";
const _hoisted_1 = {
  class: ""
};
const _hoisted_2 = {
  class: "kf-3dc-dialog-body"
};
const _hoisted_3 = {
  class: "kf-3dc-dialog-content",
  style: {
    "border-radius": "inherit",
    "overflow": "hidden"
  }
};
const _hoisted_4 = ["src"];
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_el_dialog = _resolveComponent("el-dialog");
  return _openBlock(), _createElementBlock("div", _hoisted_1, [_createVNode(_component_el_dialog, {
    "custom-class": "kf-3dc-dialog5 kf-video-dialog",
    title: _ctx.$t('TutorialVideo'),
    modelValue: $setup.showDialog,
    "onUpdate:modelValue": _cache[1] || (_cache[1] = $event => $setup.showDialog = $event),
    width: "80%",
    "close-on-click-modal": true,
    "show-close": true,
    draggable: ""
  }, {
    default: _withCtx(() => [_createElementVNode("div", _hoisted_2, [_createElementVNode("div", _hoisted_3, [$setup.inited ? (_openBlock(), _createElementBlock("video", {
      key: 0,
      onPlay: _cache[0] || (_cache[0] = (...args) => $setup.onPlay && $setup.onPlay(...args)),
      autoplay: "",
      src: $setup.getVideoUrl(),
      ref: "videoDom",
      style: {
        "width": "100%",
        "height": "100%"
      },
      controls: ""
    }, null, 40, _hoisted_4)) : _createCommentVNode("", true)])])]),
    _: 1
  }, 8, ["title", "modelValue"])]);
}