import { onMounted, ref, watch } from 'vue';
import contentModel from '@/model/newContent';
import projectModel from "@/model/project";
import contentHeader from "../newContent/mainContentRight/contentHeader.vue";
import contentLeft from "./mainContentRight/contentLeft.vue";
import contentRight from "./mainContentRight/contentRight.vue";
export default {
  name: "mainContentRight",
  components: {
    contentHeader,
    contentLeft,
    contentRight
  },
  setup() {
    let importModelVisible = ref(false);
    let importModelTab = ref([{
      name: Kf.t('ImportDirectly'),
      code: '0'
    }, {
      name: Kf.t('BestPerformance'),
      code: '1'
    }, {
      name: Kf.t('Balance'),
      code: '2'
    }, {
      name: Kf.t('OptimalPerformance'),
      code: '3'
    }]);
    let currImportModelTab = ref({});
    let search = ref('');
    onMounted(() => {});
    watch(() => contentModel.currMenu.value.code, (new_v, old_v) => {
      search.value = '';
    });
    const importModel = function () {
      importModelVisible.value = true;
      currImportModelTab.value = importModelTab.value[3];
    };
    const handleImportLevel = function (item) {
      currImportModelTab.value = item;
    };
    const doSearch = function (val) {
      search.value = val;
    };
    return {
      currMenu: contentModel.currMenu,
      currGroup: contentModel.currGroup,
      projectList: projectModel.projectGroupList,
      sceneList: projectModel.sceneList,
      importModelVisible,
      importModel,
      currImportModelTab,
      importModelTab,
      handleImportLevel,
      doSearch,
      search
    };
  }
};