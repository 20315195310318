import { ref, watch, computed } from 'vue';
import projectService from '@/service/project';
import spaceScenePreview from './spaceScenePreview.vue';
export default {
  name: "previewDialog",
  props: {
    show: {
      type: Boolean,
      default: false
    },
    data: {
      type: Object,
      default: () => {
        return {};
      }
    },
    type: {
      type: String,
      default: 'scene'
    }
  },
  components: {
    spaceScenePreview
  },
  setup(props, {
    emit
  }) {
    let showDialog = ref(false);
    let previewBox = ref(null);
    watch(props, (newValue, oldValue) => {
      showDialog.value = newValue.show;
    });
    console.log("--444-dialog", props);
    watch(showDialog, (newValue, oldValue) => {
      emit("propChange", showDialog.value);
    });
    let token = ref('');
    token.value = localStorage.getItem("KC_TOKEN");
    let showPanel = ref(false);
    let currType = ref('');
    const showPanelBox = function (type) {
      if (currType.value === type) {
        showPanel.value = !showPanel.value;
      } else {
        showPanel.value = true;
        currType.value = type;
      }
    };
    const close = function () {
      showPanel.value = false;
      currType.value = '';
    };
    return {
      close,
      showDialog,
      token,
      previewBox,
      showPanelBox,
      showPanel,
      currType
    };
  }
};