import "core-js/modules/es.array.at.js";
import "core-js/modules/es.array.push.js";
import "core-js/modules/es.string.at-alternative.js";
import { computed, onMounted, ref, nextTick, watch } from 'vue';
import contentModel from '@/model/newContent';
import projectModel from "@/model/project";
import projectService from '@/service/project';
import { ElMessage, ElMessageBox } from 'element-plus';
import recycleBin from '../../../model/recycleBin';
import { getUser } from "@/utils/auth";
import tools from "@/utils/tools";
import userModel from "@/model/user";
export default {
  name: "contentLeft",
  setup() {
    let currProject = ref({});
    let search = ref('');
    onMounted(() => {
      /*nextTick(() => {
          updateLeftContent();
      })*/
    });
    const updateLeftContent = function () {
      if (contentModel.currMenu.value.code !== 'file') {
        if (contentModel.currLeftContent.value.category === undefined) {} else {
          currProject.value = JSON.parse(JSON.stringify(contentModel.currLeftContent.value));
          projectModel.switchProject(contentModel.myProject.value, currProject.value);
        }
      } else {
        currProject.value = JSON.parse(JSON.stringify(projectModel.currProject.value));
        selectedProject(projectModel.projectList.value[0]);
      }
    };
    let count = 0;
    watch(contentModel.currLeftContent, (newValue, oldValue) => {
      if (Object.keys(newValue).length === 0 && projectModel.currProject.value) {
        updateLeftContent();
      } else {
        if (count === 0) {
          selectedProject(newValue);
        } else {
          count = 0;
          if (projectModel.currProject.value.uuid === undefined) {
            updateLeftContent();
          }
        }
        if (!currProject.value.category && contentModel.currMenu.value.code === 'library') {
          selectedProject(newValue);
        }
      }
    });
    watch(contentModel.currHeaderTab, (newValue, oldValue) => {
      if (contentModel.currMenu.value.code === 'library') {
        selectedProject(contentModel.currLeftContent.value);
      }
    });
    const selectedProject = function (item) {
      count = 1;
      if (item) {
        currProject.value = item;
        contentModel.currLeftContent.value = JSON.parse(JSON.stringify(item));
        if (contentModel.currMenu.value.code === 'file') {
          //项目
          projectModel.switchProject(item);
          contentModel.currLeftContentName.value = item.name;
        } else {
          //我得文件
          contentModel.currLeftContentName.value = item.category;
          projectModel.switchProject(contentModel.myProject.value, item);
        }
      }
    };
    let editProjectData = ref({});
    let editProjectVisible = ref(false);
    let createProjectVisible = ref(false);
    let createProjectData = ref({
      code: '',
      name: '',
      groups: "",
      description: ''
    });
    let createGroupName = ref('');
    let createGroupName1 = ref(false);
    let createGroupVisible = ref(false);
    let renameVisible = ref(false);
    let groupName = ref('');
    let moveGroup = ref({});
    let moveGroupVisible = ref(false);
    const addProject = function () {
      if (contentModel.currMenu.value.code === 'file') {
        //项目
        createProjectVisible.value = true;
        createProjectData.value = {
          code: '1',
          name: '',
          groups: 'default',
          description: ''
        };
      } else {
        //我得文件
        createGroupName.value = '';
        createGroupName1.value = false;
        createGroupVisible.value = true;
      }
    };
    const doCreateProject = function () {
      let user = JSON.parse(getUser());
      if (createProjectData.value.name === '') {
        ElMessage({
          type: 'error',
          message: Kf.t('PleaseFillInTheProjectName'),
          duration: 1000,
          grouping: true
        });
        return;
      }
      createProjectData.value.author = user.userName;
      projectService.createProject(createProjectData.value).then(result => {
        if (result === -1 || result === '') {
          ElMessage({
            type: 'warning',
            message: Kf.t('NameRepeat'),
            grouping: true
          });
          return;
        } else if ((result === null || result === void 0 ? void 0 : result.status) == '406') {
          ElMessage({
            type: "error",
            message: Kf.t('UserSRemainingSpaceIsNotEnough'),
            grouping: true
          });
        } else {
          ElMessage({
            type: 'success',
            grouping: true,
            customClass: 'kf-3dc-create-success',
            message: Kf.t('CreateSuccessful')
          });
          projectModel.loadProject();
          createProjectVisible.value = false;
        }
      }).catch(error => {
        if (error.code == 2301) {
          ElMessage({
            type: 'warning',
            message: Kf.t('LimitedNumberOfProjects') + ',' + Kf.t('PleaseContactTheAdministrator'),
            grouping: true
          });
        }
      });
    };
    const getNum = function (item) {
      return '99+';
    };
    let editGroupName = ref('');
    let editGroup = ref(false);
    let rightClickMenu = ref([]);
    let showEdit = ref(false);
    let editStyle = ref({});
    let editItem = ref({});
    const doRightClick = function (item, event) {
      if (contentModel.currMenu.value.code === 'tutorial') {
        return false;
      }
      showEdit.value = false;
      editItem.value = item;
      if (contentModel.currMenu.value.code === 'file') {
        rightClickMenu.value = [{
          name: Kf.t('Edit'),
          type: 'edit'
        }, {
          name: Kf.t('Delete'),
          type: 'delete'
        }];
      } else {
        var _contentModel$currLib, _contentModel$currLib2;
        if (((_contentModel$currLib = contentModel.currLibTab.value) === null || _contentModel$currLib === void 0 ? void 0 : _contentModel$currLib.code) === 'official') {
          rightClickMenu.value = [{
            name: Kf.t('Rename'),
            type: 'renameThemeGroup'
          }, {
            name: Kf.t('Delete'),
            type: 'deleteThemeGroup'
          }];
        } else {
          rightClickMenu.value = [{
            name: Kf.t('Rename'),
            type: 'renameThemeGroup'
          } /*,{name:Kf.t('DismissGroupToDefault'),type:'dissolution'}*/, {
            name: Kf.t('Delete'),
            type: 'deleteThemeGroup'
          }];
        }
        if (((_contentModel$currLib2 = contentModel.currLibTab.value) === null || _contentModel$currLib2 === void 0 ? void 0 : _contentModel$currLib2.code) !== 'official' && (item === null || item === void 0 ? void 0 : item.category) === Kf.t('Default') || (item === null || item === void 0 ? void 0 : item.category) === Kf.t('UploadedByMe') || (item === null || item === void 0 ? void 0 : item.category) === Kf.t('IAdded')) {
          return false;
        }
        if (!contentModel.currUser.value.manager && isOfficial.value) {
          return false;
        }
      }
      let _height = document.documentElement.clientHeight;
      let _width = document.documentElement.clientWidth;
      if (event.y > _height - 100) {
        editStyle.value.top = _height - 100 + 'px';
      } else {
        editStyle.value.top = event.y + 'px';
      }
      if (event.x > _width - 120) {
        editStyle.value.left = _width - 120 + 'px';
      } else {
        editStyle.value.left = event.x + 'px';
      }
      showEdit.value = true;
    };
    const doDeleteProject = function (item) {
      ElMessageBox.confirm(Kf.t('AreYouSureItsDeleted') + editItem.value.name + '?' + Kf.t('DeleteItAndFindItInTheWastebin') + Kf.t('WellKeepYouFor30Days1'), Kf.t('Hint'), {
        confirmButtonText: Kf.t('Confirm'),
        cancelButtonText: Kf.t('Cancel'),
        type: 'warning',
        draggable: true,
        customClass: 'kf-content-msg-confirm'
      }).then(() => {
        projectService.deleteProject(editItem.value.uuid).then(result => {
          if ((result === null || result === void 0 ? void 0 : result.code) == 200) {
            projectModel.projectList.value.some((item, index1) => {
              if (item.uuid == editItem.value.uuid) {
                projectModel.projectList.value.splice(index1, 1);
                projectModel.projectList.value.length && selectedProject(projectModel.projectList.value.at(0)); // 删除后选择第一个项目
                return true;
              } else {
                return false;
              }
            });
            ElMessage({
              type: "success",
              customClass: 'kf-3dc-create-success',
              message: Kf.t('Deleted'),
              grouping: true
            });
          } else {
            ElMessage({
              type: "warning",
              message: Kf.t('DeletionFailed') + ',' + Kf.t('PleaseContactTheAdministrator'),
              grouping: true
            });
          }
        });
      }).catch(() => {});
    };
    const handleEdit = function (item) {
      if (contentModel.currMenu.value.code == 'file') {
        switch (item.type) {
          case 'edit':
            editProjectData.value = JSON.parse(JSON.stringify(editItem.value));
            editProjectVisible.value = true;
            break;
          case 'move':
            moveGroup.value = JSON.parse(JSON.stringify(editItem.value));
            moveGroupVisible.value = true;
            break;
          case 'delete':
            doDeleteProject(item);
            break;
        }
      } else {
        switch (item.type) {
          case 'rename':
            groupName.value = editItem.value.category;
            createGroupName1.value = false;
            if (contentModel.currUser.value.manager && editItem.value.category != '' && editItem.value.category.indexOf('（' + Kf.t('Official') + '）') != -1) {
              createGroupName1.value = true;
              groupName.value = editItem.value.category.split('（' + Kf.t('Official') + '）')[0];
            }
            renameVisible.value = true;
            break;
          case 'dissolution':
            updateCategory(editItem.value.category, Kf.t('Default'), editItem.value);
            break;
          case 'delete':
            deleteCategory(editItem.value.category);
            break;
          case 'deleteThemeGroup':
            contentModel.deleteThemeGroup(editItem.value, res => {
              if (res === 'success') {
                let index = null;
                contentModel.myProjectList.value.some((item, index1) => {
                  if (item.category === editItem.value.category) {
                    index = index1;
                    return true;
                  } else {
                    return false;
                  }
                });
                if (index != null) {
                  contentModel.myProjectList.value.splice(index, 1);
                  contentModel.currLeftContentName.value = contentModel.myProjectList.value[0].category;
                  contentModel.currLeftContent.value = JSON.parse(JSON.stringify(contentModel.myProjectList.value[0]));
                  currProject.value = contentModel.myProjectList.value[0];
                  projectModel.switchProject(contentModel.myProject.value, contentModel.currLeftContent.value);
                  contentModel.loadThemeGroups(contentModel.myProject.value);
                }
              } else {
                ElMessage({
                  type: "error",
                  message: res,
                  grouping: true
                });
              }
            });
            break;
          case 'renameThemeGroup':
            let _item = Object.assign({}, editItem.value);
            contentModel.renameThemeGroup(_item, (res, newValue) => {
              if (res === 'success') {
                editItem.value.category = newValue;
              } else {
                ElMessage({
                  type: "error",
                  message: res,
                  grouping: true
                });
              }
              contentModel.loadThemeGroups(contentModel.myProject.value);
            });
            break;
        }
      }
    };
    const deleteCategory = function (category) {
      ElMessageBox.confirm(Kf.t('AreYouSureItsDeleted') + category + '?', Kf.t('Hint'), {
        confirmButtonText: Kf.t('Confirm'),
        cancelButtonText: Kf.t('Cancel'),
        type: 'warning',
        draggable: true,
        customClass: 'kf-content-msg-confirm'
      }).then(() => {
        projectService.deteleCategory(contentModel.myProject.value.uuid, category, contentModel.currHeaderTab.value.code).then(result => {
          if ((result === null || result === void 0 ? void 0 : result.code) == 200) {
            let index = null;
            contentModel.myProjectList.value.some((item, index1) => {
              if (item.category == category) {
                index = index1;
                return true;
              } else {
                return false;
              }
            });
            if (index != null) {
              contentModel.myProjectList.value.splice(index, 1);
              contentModel.currLeftContentName.value = contentModel.myProjectList.value[0].category;
              contentModel.currLeftContent.value = JSON.parse(JSON.stringify(contentModel.myProjectList.value[0]));
              currProject.value = contentModel.myProjectList.value[0];
              projectModel.switchProject(contentModel.myProject.value, contentModel.currLeftContent.value);
            }
            ElMessage({
              type: "success",
              customClass: 'kf-3dc-create-success',
              message: Kf.t('Deleted'),
              grouping: true
            });
          } else {
            ElMessage({
              type: "warning",
              message: Kf.t('DeletionFailed') + ',' + Kf.t('PleaseContactTheAdministrator'),
              grouping: true
            });
          }
        });
      }).catch(() => {});
    };
    const doRename = function () {
      if (groupName.value != '') {
        var _contentModel$myProje;
        let _category = groupName.value;
        if (contentModel.currUser.value.manager && contentModel.currMenu.value.code == 'library' && createGroupName1.value) {
          _category = groupName.value + '（' + Kf.t('Official') + '）';
        }
        let flag = false;
        if (((_contentModel$myProje = contentModel.myProjectList.value) === null || _contentModel$myProje === void 0 ? void 0 : _contentModel$myProje.length) > 0) {
          contentModel.myProjectList.value.some(i => {
            if (i.category == _category) {
              flag = true;
              return true;
            } else {
              return false;
            }
          });
        }
        if (flag) {
          ElMessage({
            type: "warning",
            message: Kf.t('TheGroupAlreadyExists'),
            grouping: true
          });
          return false;
        }
        updateCategory(editItem.value.category, _category);
      } else {
        ElMessage({
          type: "warning",
          message: Kf.t('TheGroupAlreadyExists') + "," + Kf.t('PleaseReenter'),
          grouping: true
        });
      }
    };
    const updateCategory = function (sourceCategory, targetCategory, item) {
      projectService.updateCategory(contentModel.myProject.value.uuid, sourceCategory, targetCategory, contentModel.currHeaderTab.value.code).then(res => {
        renameVisible.value = false;
        if ((res === null || res === void 0 ? void 0 : res.code) == 200) {
          if (item) {
            item.category = targetCategory;
            contentModel.currLeftContent.value = item;
            contentModel.loadPart(contentModel.myProject.value, null, 1);
          } else {
            editItem.value.category = targetCategory;
          }
          contentModel.loadThemeGroups(contentModel.myProject.value);
        } else {
          if ((res === null || res === void 0 ? void 0 : res.status) == '406') {
            ElMessage({
              type: "error",
              message: Kf.t('UserSRemainingSpaceIsNotEnough'),
              grouping: true
            });
          } else {
            ElMessage({
              type: "warning",
              message: Kf.t('UpdateFailPleaseContactAdministrator'),
              grouping: true
            });
          }
        }
      });
    };
    let isOfficiel = false;
    const editGroups = function (item, event) {
      isOfficiel = false;
      if (!contentModel.isProject.value && (item === null || item === void 0 ? void 0 : item.category) === Kf.t('Default') || (item === null || item === void 0 ? void 0 : item.category) === Kf.t('UploadedByMe') || (item === null || item === void 0 ? void 0 : item.category) === Kf.t('IAdded')) {
        return false;
      }
      if (!contentModel.currUser.value.manager && isOfficial.value) {
        return false;
      }
      editGroup.value = true;
      let _text = event.target.innerText;
      if (contentModel.currUser.value.manager && contentModel.currMenu.value.code === 'library' && _text !== '' && _text.indexOf('（' + Kf.t('Official') + '）') !== -1) {
        _text = event.target.innerText.split('（' + Kf.t('Official') + '）')[0];
        isOfficiel = true;
      }
      event.target.innerText = _text;
      event.target.contentEditable = true;
      event.target.focus();
      document.execCommand('selectAll');
    };
    const changeGroup = function (item, event) {
      let _text = event.target.innerText.trim();
      if (_text === '') {
        ElMessage({
          type: "warning",
          message: Kf.t('CannotBeEmpty'),
          grouping: true
        });
      } else if (!(_text !== "" && contentModel.checkNameLength(_text))) {
        event.target.innerHTML = item.name;
        ElMessage({
          type: "warning",
          customClass: 'kf-3dc-create-success',
          message: Kf.t('NameMayNotBeLongerThan50Bytes'),
          grouping: true
        });
      } else {
        /*禁止以_开头*/
        if (_text.startsWith('_') || _text.startsWith('-')) {
          ElMessage({
            type: "warning",
            message: Kf.t('Cannot') + ' _  - ' + Kf.t('Beginning'),
            grouping: true
          });
          _text = _text.substring(1, _text.length);
        }
        if (contentModel.currUser.value.manager && contentModel.currMenu.value.code == 'library' && isOfficiel) {
          _text = event.target.innerText.trim() + '（' + Kf.t('Official') + '）';
        }
        if (!contentModel.isProject.value && contentModel.currMenu.value.code !== 'file') {
          var _contentModel$myProje2;
          let flag = false;
          if (((_contentModel$myProje2 = contentModel.myProjectList.value) === null || _contentModel$myProje2 === void 0 ? void 0 : _contentModel$myProje2.length) > 0) {
            contentModel.myProjectList.value.some(i => {
              if (i.category === _text) {
                flag = true;
                return true;
              } else {
                return false;
              }
            });
          }
          if (flag && !contentModel.isProject.value) {
            event.target.innerText = item.category;
            ElMessage({
              type: "warning",
              message: Kf.t('TheGroupAlreadyExists'),
              grouping: true
            });
            return false;
          }
        }
        event.target.innerText = _text;
        editGroup.value = false;
        if (contentModel.currMenu.value.code === 'file') {
          //项目     2023.12.8--我的文件里包含所有项目
          let p = JSON.parse(JSON.stringify(item));
          p.name = _text;
          projectService.updateProject(p).then(result => {
            if (result) {
              item.name = _text;
              event.target.innerHTML = item.name;
            }
          });
        } else if (contentModel.currMenu.value.code === 'library' && contentModel.currUser.value.manager) {
          contentModel.doRenameThemeGroup(item.category, _text, res => {
            if (res === 'success') {
              item.category = _text;
            } else {
              ElMessage({
                type: "error",
                message: res,
                grouping: true
              });
            }
            contentModel.loadThemeGroups(contentModel.myProject.value);
          });
        } else {
          projectService.updateCategory(contentModel.myProject.value.uuid, item.category, event.target.innerText, contentModel.currHeaderTab.value.code).then(res => {
            if ((res === null || res === void 0 ? void 0 : res.code) == 200) {
              item.category = _text;
              projectModel.switchProject(contentModel.myProject.value, item);
            } else {
              if ((res === null || res === void 0 ? void 0 : res.status) == '406') {
                ElMessage({
                  type: "error",
                  message: Kf.t('UserSRemainingSpaceIsNotEnough'),
                  grouping: true
                });
              } else {
                ElMessage({
                  type: "warning",
                  message: Kf.t('UpdateFailPleaseContactAdministrator'),
                  grouping: true
                });
              }
            }
          });
        }
      }
    };
    const doCreateGroup = function () {
      if (createGroupName.value !== '') {
        var _contentModel$myProje3;
        let _category = createGroupName.value;
        if (contentModel.currUser.value.manager && contentModel.currMenu.value.code === 'library' && createGroupName1.value) {
          _category = createGroupName.value + '（' + Kf.t('Official') + '）';
        }
        let flag = false;
        if (((_contentModel$myProje3 = contentModel.myProjectList.value) === null || _contentModel$myProje3 === void 0 ? void 0 : _contentModel$myProje3.length) > 0) {
          contentModel.myProjectList.value.some(i => {
            if (i.category === _category) {
              flag = true;
              return true;
            } else {
              return false;
            }
          });
        }
        if (flag) {
          ElMessage({
            type: "warning",
            message: Kf.t('TheGroupAlreadyExists'),
            grouping: true
          });
          return false;
        }
        let _data = {
          category: _category,
          showFlag: 0
        };
        contentModel.myProjectList.value.push(_data);
        let _flag = contentModel.currLibTab.value.code === 'userContent' ? 2 : 1;
        contentModel.themeGroup.value[_flag].push(_category);
        contentModel.newGroup.value = {
          category: _category,
          systemFlag: createGroupName1.value ? 1 : 2
        };
        createGroupVisible.value = false;
        search.value = '';
      }
    };
    let editDom = ref(null);
    window.addEventListener('click', function (e) {
      if (!editDom.value || editDom.value && editDom.value.contains && !editDom.value.contains(e.target)) {
        showEdit.value = false;
      }
    });
    // 获取具体日期分类下的回收站资源
    const getRecycleResourceList = item => {
      currProject.value = item;
      recycleBin.currentDate.value = item.category;
      if (recycleBin.menuCode.value === 'scene') {
        recycleBin.getRecycleListByScene({
          date: item.category
        });
      } else if (recycleBin.menuCode.value === 'component') {
        recycleBin.getRecycleList({
          date: item.category
        });
      } else if (recycleBin.menuCode.value === 'space') {
        recycleBin.getRecycleListBySpace({
          date: item.category
        });
      } else if (recycleBin.menuCode.value === 'project') {
        recycleBin.getRecycleListByProject({
          date: item.category
        });
      } else if (recycleBin.menuCode.value === 'page') {
        recycleBin.getRecycleListByPage({
          date: item.category
        });
      }
    };
    const myProjectList1 = computed(() => {
      var _contentModel$myProje4, _contentModel$currLib3;
      let arr = [];
      if (((_contentModel$myProje4 = contentModel.myProjectList.value) === null || _contentModel$myProje4 === void 0 ? void 0 : _contentModel$myProje4.length) > 0) {
        arr = [...arr, ...contentModel.myProjectList.value];
        search.value = '';
      }
      if (contentModel.currMenu.value.code === 'library' && ((_contentModel$currLib3 = contentModel.currLibTab.value) === null || _contentModel$currLib3 === void 0 ? void 0 : _contentModel$currLib3.code) !== 'official') {
        arr.unshift({
          'category': Kf.t('UploadedByMe'),
          showFlag: 0,
          flag: 1
        });
      }
      return arr;
    });
    const doMoveGroup = function () {
      projectService.updateProject(moveGroup.value).then(result => {
        if (result) {
          moveGroupVisible.value = false;
          ElMessage({
            type: "success",
            customClass: 'kf-3dc-create-success',
            message: Kf.t('MoveSuccessfully') + "，" + Kf.t('CanBeMovedTo') + moveGroup.value.groups + Kf.t('CheckDown'),
            grouping: true
          });
          projectModel.loadProject(contentModel.currGroup.value);
          if (moveGroup.value.uuid === projectModel.currProject.value.uuid) {
            currProject.value = projectModel.projectGroupList.value[contentModel.currGroup.value][0];
            selectedProject(currProject.value);
          }
        }
      });
    };
    const doUpdateProject = function () {
      projectService.updateProject(editProjectData.value).then(result => {
        if (result) {
          editProjectVisible.value = false;
          editItem.value = JSON.parse(JSON.stringify(editProjectData.value));
          projectModel.loadProject();
        }
      });
    };
    const getCurrList = function () {
      let result = [];
      if (contentModel.currMenu.value.code === 'file') {
        result = projectModel.projectList.value.filter(comp => {
          return !search.value || tools.pinyinMatch(comp.name, search.value);
        });
      } else {
        return myProjectList1.value.filter(comp => {
          return !search.value || tools.pinyinMatch(comp.category, search.value);
        });
      }
      return result;
    };
    const doDrop = function (item, event, key) {
      if (item && item[key] && contentModel.currUser.value.manager) {
        contentModel.doMoveToProject(contentModel.currDragItem.value.uuid, item[key]);
        event.target.toggleAttribute('over', false);
      }
    };
    const doDrop1 = function (item, event, key) {
      if (item && item[key] && contentModel.currDragItem.value[key] != item[key] && contentModel.currUser.value.manager) {
        contentModel.currDragItem.value[key] = item[key];
        event.target.toggleAttribute('over', false);
        contentModel.moveToCategory(contentModel.currDragItem.value);
      }
    };
    const doDragOver = function (item, event, key) {
      if (item && item[key] && !contentModel.currUser.value.manager) {} else {
        event.dropEffect = 'none';
      }
    };
    const doDragEnter = function (item, event) {
      if (contentModel.currUser.value.manager) {
        event.target.toggleAttribute('over', true);
      } else {
        event.dropEffect = 'none';
      }
    };
    const doDragOver1 = function (item, event) {
      if (!contentModel.currUser.value.manager) {
        event.dropEffect = 'none';
      }
    };
    const doDragLeave = function (item, event) {
      event.target.toggleAttribute('over', false);
    };
    const changeValue = function (val) {
      /*禁止以_开头*/
      if (val.startsWith('_') || val.startsWith('-')) {
        ElMessage({
          type: "warning",
          message: Kf.t('Cannot') + ' _  - ' + Kf.t('Beginning'),
          grouping: true
        });
        createGroupName.value = val.substring(1, val.length);
      }
    };
    const changeValue1 = function (val) {
      /*禁止以_开头*/
      if (val.startsWith('_') || val.startsWith('-')) {
        ElMessage({
          type: "warning",
          message: Kf.t('Cannot') + ' _  - ' + Kf.t('Beginning'),
          grouping: true
        });
        groupName.value = val.substring(1, val.length);
      }
    };
    let loginUserInfo = ref({}); // 登录用户信息
    if (userModel.user === undefined || userModel.user.value.userName === undefined) {
      let user = JSON.parse(localStorage.getItem('KC_USER'));
      if (user === null) {
        router.replace({
          path: '/'
        });
      } else {
        loginUserInfo.value = user;
        userModel.updateUser(user);
      }
    } else {
      loginUserInfo.value = userModel.user.value;
    }
    // 是否是官方内容
    const isOfficial = computed(() => {
      return contentModel.currLibTab.value.code !== 'userContent';
    });
    const changeNameLengthLimit = function (val) {
      if (val.length > 50) {
        ElMessage({
          type: "warning",
          message: Kf.t('TheNameMustNotExceed50Words')
        });
        createProjectData.value.name = val.substring(0, 50);
      }
    };
    return {
      currMenu: contentModel.currMenu,
      currGroup: contentModel.currGroup,
      projectList: projectModel.projectGroupList,
      isProject: contentModel.isProject,
      currProject,
      recycleBin,
      myProject: contentModel.myProject,
      myProjectList: contentModel.myProjectList,
      myProjectList1,
      selectedProject,
      addProject,
      createProjectVisible,
      createProjectData,
      doCreateProject,
      getNum,
      editGroups,
      changeGroup,
      editGroup,
      editGroupName,
      doRightClick,
      createGroupName,
      createGroupVisible,
      doCreateGroup,
      rightClickMenu,
      showEdit,
      editStyle,
      handleEdit,
      renameVisible,
      groupName,
      doRename,
      getRecycleResourceList,
      moveGroup,
      moveGroupVisible,
      doMoveGroup,
      editProjectData,
      editProjectVisible,
      doUpdateProject,
      search,
      getCurrList,
      doDrop,
      doDragOver,
      doDrop1,
      doDragEnter,
      doDragLeave,
      doDragOver1,
      createGroupName1,
      currUser: contentModel.currUser,
      changeValue,
      changeValue1,
      splitIndex: projectModel.splitIndex,
      isOfficial,
      changeNameLengthLimit
    };
  }
};