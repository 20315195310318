import "core-js/modules/es.error.cause.js";
import "core-js/modules/es.array.push.js";
import { reactive, ref } from "vue";
import projectService from "../service/project";
import { ElMessage, ElLoading } from "element-plus";
import projectModel from "@/model/project";

// 回收站的项目数据列表
let recycleBinList = reactive([]);
let searchList = reactive([]);
// 回收站按照日期分类的接口
let recycleDateList = reactive([]);
let menuCode = ref('project');
let currentDate = ref();
// 判断是否是回收站
let isMenuLabel = ref('');
// elloading flag
const options = {
  fullscreen: false
};
// 升序降序 true => up false => down
let orderType = ref(true);
const state = reactive({
  loading: false
});

// 组件 回收站根据日期查询当前日期下的数据列表
const getRecycleList = async params => {
  // debugger
  recycleBinList.length = 0;
  searchList.length = 0;
  let res = null;
  try {
    res = await projectService.getRecycleList(params);
  } catch (error) {
    throw new Error(Kf.t('WastebinResourceListNetworkRequestError'));
  }
  recycleBinList.push(...res);
  searchList.push(...res);
  // console.log(recycleBinList, '回收站的列表', searchList);
};
// 组件 获取回收站的按照日期和数量分布的数据列表
const getBaseDateCount = async () => {
  var _res;
  // debugger
  state.loading = true;
  recycleDateList.length = 0;
  let res = null;
  try {
    res = await projectService.getRecycleDateCountList('kf-part');
    state.loading = false;
  } catch (error) {
    throw new Error(Kf.t('WastebinResourceListNetworkRequestError'));
  }
  res = sortByDate(res);
  if (((_res = res) === null || _res === void 0 ? void 0 : _res.length) > 0) {
    currentDate.value = res[0].category;
  }
  recycleDateList.push(...res);
  if (currentDate.value) {
    getRecycleList({
      date: currentDate.value
    });
  }

  // console.log(recycleDateList, '按照日期进行分类的回收站的接口');
};

// 场景 回收站根据日期查询当前日期下的数据列表
const getRecycleListByScene = async params => {
  state.loading = true;
  recycleBinList.length = 0;
  searchList.length = 0;
  let res = null;
  try {
    res = await projectService.getRecycleListByScene(params);
    state.loading = false;
  } catch (error) {
    throw new Error(Kf.t('WastebinResourceListNetworkRequestError'));
  }
  recycleBinList.push(...res);
  searchList.push(...res);
  // console.log(recycleBinList, '场景 --回收站的列表');
};
const getRecycleListBySpace = async params => {
  state.loading = true;
  recycleBinList.length = 0;
  searchList.length = 0;
  let res = null;
  try {
    res = await projectService.getRecycleListBySpace(params);
    state.loading = false;
  } catch (error) {
    throw new Error(Kf.t('WastebinResourceListNetworkRequestError'));
  }
  recycleBinList.push(...res);
  searchList.push(...res);
  // console.log(recycleBinList, '空间 --回收站的列表');
};
const getRecycleListByPage = async params => {
  state.loading = true;
  recycleBinList.length = 0;
  searchList.length = 0;
  let res = null;
  try {
    res = await projectService.getRecycleListByPage(params);
    state.loading = false;
  } catch (error) {
    throw new Error(Kf.t('WastebinResourceListNetworkRequestError'));
  }
  recycleBinList.push(...res);
  searchList.push(...res);
  // console.log(recycleBinList, '回收站的列表');
};
// 场景 获取回收站的按照日期和数量分布的数据列表
const getBaseDateCountByScene = async () => {
  var _res2;
  recycleDateList.length = 0;
  recycleBinList.length = 0;
  let res = null;
  try {
    res = await projectService.getRecycleDateCountList('kf-scene-3d');
  } catch (error) {
    throw new Error(Kf.t('WastebinResourceListNetworkRequestError'));
  }
  res = sortByDate(res);
  if (((_res2 = res) === null || _res2 === void 0 ? void 0 : _res2.length) > 0) {
    currentDate.value = res[0].category;
    recycleDateList.push(...res);
    if (currentDate.value) {
      getRecycleListByScene({
        date: currentDate.value
      });
    }
  }

  // console.log(recycleDateList, '场景 --按照日期进行分类的回收站的接口');
};
const isGMT = function (time) {
  var date = new Date(time);
  return date.getUTCHours() === 0;
};
const timeChange = function (time) {
  if (!isGMT(time)) {
    return time;
  }
  // 创建一个 Date 对象，传入 UTC 时间字符串
  const date = new Date(time);
  // 获取 UTC 时间的时间戳，并加上 8 小时的毫秒数
  const beijingTime = new Date(date.getTime() + 8 * 3600 * 1000);

  // 格式化为 YYYY-MM-DD HH:MM:SS
  return beijingTime.getFullYear() + "-" + String(beijingTime.getMonth() + 1).padStart(2, '0') + "-" + String(beijingTime.getDate()).padStart(2, '0') + " " + String(beijingTime.getHours()).padStart(2, '0') + ":" + String(beijingTime.getMinutes()).padStart(2, '0') + ":" + String(beijingTime.getSeconds()).padStart(2, '0');
};
const getBaseDateCountBySpace = async () => {
  var _res3;
  recycleDateList.length = 0;
  recycleBinList.length = 0;
  let res = null;
  try {
    res = await projectService.getRecycleDateCountList('kf-space');
  } catch (error) {
    throw new Error(Kf.t('WastebinResourceListNetworkRequestError'));
  }
  let list = [];
  let categoryData = {};
  if (((_res3 = res) === null || _res3 === void 0 ? void 0 : _res3.length) > 0) {
    var _list;
    res.forEach(item => {
      let _category = item.lastModifiedDate ? timeChange(item.lastModifiedDate).substring(0, 10) : item.category;
      let _item = item;
      _item.category = _category;
      if (_category !== null) {
        if (categoryData.hasOwnProperty(_category)) {
          categoryData[_category].push(_item);
        } else {
          categoryData[_category] = [];
          categoryData[_category].push(_item);
        }
      }
    });
    for (let key in categoryData) {
      let obj = {};
      obj.category = key;
      obj.showFlag = categoryData[key].length;
      list.push(obj);
    }
    list = sortByDate(list);
    if (((_list = list) === null || _list === void 0 ? void 0 : _list.length) > 0) {
      currentDate.value = list[0].category;
    }
    recycleDateList.push(...list);
    if (currentDate.value) {
      getRecycleListBySpace({
        date: currentDate.value
      });
    }
  }

  // console.log(recycleDateList, '场景 --按照日期进行分类的回收站的接口');
};

// 项目 回收站根据日期查询当前日期下的数据列表
const getRecycleListByProject = async params => {
  state.loading = true;
  recycleBinList.length = 0;
  searchList.length = 0;
  let res = null;
  try {
    res = await projectService.getRecycleListByProject(params);
    state.loading = false;
  } catch (error) {
    throw new Error(Kf.t('WastebinResourceListNetworkRequestError'));
  }
  recycleBinList.push(...res);
  searchList.push(...res);
  // console.log(recycleBinList, '回收站的列表');
};
const getBaseDateCountByPage = async () => {
  var _res4;
  recycleDateList.length = 0;
  let res = null;
  try {
    res = await projectService.getRecycleDateCountList('kf-page3d');
  } catch (error) {
    throw new Error('回收站资源列表网络请求错误');
  }
  res = sortByDate(res);
  if (((_res4 = res) === null || _res4 === void 0 ? void 0 : _res4.length) > 0) {
    currentDate.value = res[0].category;
  }
  recycleDateList.push(...res);
  if (currentDate.value) {
    getRecycleListByPage({
      date: currentDate.value
    });
  }

  // console.log(recycleDateList, '按照日期进行分类的回收站的接口');
};

// 项目 获取回收站的按照日期和数量分布的数据列表
const getBaseDateCountByProject = async () => {
  var _res5;
  recycleDateList.length = 0;
  let res = null;
  try {
    res = await projectService.getRecycleDateCountList('kf-project');
  } catch (error) {
    throw new Error(Kf.t('WastebinResourceListNetworkRequestError'));
  }
  res = sortByDate(res);
  if (((_res5 = res) === null || _res5 === void 0 ? void 0 : _res5.length) > 0) {
    currentDate.value = res[0].category;
  }
  recycleDateList.push(...res);
  if (currentDate.value) {
    getRecycleListByProject({
      date: currentDate.value
    });
  }

  // console.log(recycleDateList, '按照日期进行分类的回收站的接口');
};
// 项目 永久删除
const recycleDelByProject = async projectId => {
  let res = await projectService.recycleDelByProject(projectId);
  if (res.code != 200) return;
  ElMessage({
    message: Kf.t('FileDeleteSuccessful'),
    type: 'success',
    grouping: true
  });
  getBaseDateCountByProject();
};
// 组件-恢复/永久删除
const recycleOpeByComponent = async param => {
  let res = await projectService.recycleRestoreByComponent(param);
  if (res.code != 200) return;
  if (param.flag == 0) {
    ElMessage({
      message: Kf.t('FileRestorationSuccess'),
      type: 'success',
      grouping: true
    });
  } else {
    ElMessage({
      message: Kf.t('FileDeleteSuccessful'),
      type: 'success',
      grouping: true
    });
  }
  getBaseDateCount();
  // console.log(res, '回收站 - 组件 恢复和删除操作');
};
// 项目 恢复
const restoreProByProject = async projectUuid => {
  let res = null;
  try {
    res = await projectService.restoreProjectByPro(projectUuid);
  } catch {
    throw new Error(Kf.t('WastebinResourceListNetworkRequestError'));
  }
  if (res == 'success') {
    projectModel.loadProject();
    getBaseDateCountByProject();
    ElMessage({
      message: Kf.t('FileRestorationSuccess'),
      type: 'success',
      grouping: true
    });
  }
};
// 场景-恢复/永久删除
const recycleOpeByScene = async param => {
  let res = await projectService.recycleRestoreByScene(param);
  if (res.code != 200) return;
  if (param.flag == 0) {
    ElMessage({
      message: Kf.t('ModifiedSuccessfully'),
      type: 'success',
      grouping: true
    });
  } else {
    ElMessage({
      message: Kf.t('PageDeleteSuccessful'),
      type: 'success',
      grouping: true
    });
  }
  getBaseDateCountByScene();
  // console.log(res, '回收站 - 组件 恢复和删除操作');
};
const recycleOpeBySpace = async param => {
  let res = await projectService.recycleRestoreBySpace(param);
  if (res !== 'success') return;
  if (param.flag == 0) {
    ElMessage({
      message: Kf.t('ModifiedSuccessfully'),
      type: 'success',
      grouping: true
    });
  } else {
    ElMessage({
      message: Kf.t('PageDeleteSuccessful'),
      type: 'success',
      grouping: true
    });
  }
  getBaseDateCountBySpace();
  // console.log(res, '回收站 - 组件 恢复和删除操作');
};
const recycleOpeByPage = async param => {
  let res = await projectService.recycleRestoreByPage(param);
  if (res.code != 200) return;
  if (param.flag == 0) {
    ElMessage({
      message: Kf.t('FileRestorationSuccess'),
      type: 'success',
      grouping: true
    });
  } else {
    ElMessage({
      message: Kf.t('FileDeleteSuccessful'),
      type: 'success',
      grouping: true
    });
  }
  getBaseDateCountByPage();
  // console.log(res, '回收站 - 组件 恢复和删除操作');
};
// 回收站的清空
const recycleClear = async (type, callback) => {
  let res = await projectService.recycleClear(type);
  console.log(res);
  if (type !== 'space' && res.code == "200" || type === 'space' && res === "success") {
    if (callback) {
      callback(res);
    }
    ElMessage({
      message: Kf.t('WastebinEmptiedSuccessfully'),
      type: 'success',
      grouping: true
    });
  } else {
    ElMessage({
      message: Kf.t('EmptyWastebinAttemptFailed'),
      type: 'warnning',
      grouping: true
    });
  }
};
// 日期排序
const sortByDate = list => {
  let timestr = null,
    timestamp = null;
  let resourceList = list.filter(item => item.category != null);
  resourceList.forEach(it => {
    var _it$category;
    timestr = (_it$category = it.category) === null || _it$category === void 0 ? void 0 : _it$category.replace(/-/g, '/');
    timestamp = new Date(timestr).getTime();
    it.getTime = timestamp;
  });
  resourceList.sort((a, b) => b.getTime - a.getTime);
  return resourceList;
};
// 获取当天日期时间戳
const getNowTime = () => {
  let date = new Date();
  let str = date.getFullYear() + '-' + (date.getMonth() + 1) + '-' + date.getDate();
  return new Date(str).getTime();
};
// 获取当天时间 格式 xxxx-xx-xx
const getNowDate = () => {
  let date = new Date();
  return date.getFullYear() + '-' + (date.getMonth() + 1) + '-' + date.getDate();
};
// loading 
const loadingInstance = option => {
  let options = Object.assign(option, options);
  return ElLoading.service(options);
};
// 某天 删除文件资源列表的排序
const sortByList = (list, type, orderType) => {
  //console.log(list, type, '根据什么进行排序', orderType);
  if (type === 'createDate') {
    type = list[0][type] ? type : 'createdDate';
  } else {
    type = list[0][type] ? type : 'lastModifiedDate';
  }
  dateStrToTimeStamp(list, type);
  list.sort((a, b) => {
    if (orderType) {
      return a.sort_date - b.sort_date;
    } else {
      return b.sort_date - a.sort_date;
    }
  });
};
// date转成时间戳
const dateStrToTimeStamp = (list, type) => {
  list.forEach(item => {
    item.sort_date = new Date(item[type]).getTime();
  });
};
const loadData = function (tabData) {
  menuCode.value = tabData === null || tabData === void 0 ? void 0 : tabData.code;
  if (menuCode.value === 'project') {
    getBaseDateCountByProject();
  } else if (menuCode.value === 'space') {
    getBaseDateCountBySpace();
  }
};
export default {
  recycleBinList,
  getRecycleList,
  isMenuLabel,
  getBaseDateCount,
  recycleDateList,
  searchList,
  getNowTime,
  getRecycleListByScene,
  getBaseDateCountByScene,
  getBaseDateCountBySpace,
  getNowDate,
  menuCode,
  recycleOpeByComponent,
  currentDate,
  recycleOpeByScene,
  recycleOpeBySpace,
  recycleOpeByPage,
  getBaseDateCountByProject,
  getBaseDateCountByPage,
  getRecycleListByProject,
  restoreProByProject,
  loadingInstance,
  orderType,
  sortByList,
  recycleDelByProject,
  recycleClear,
  state,
  loadData,
  getRecycleListByPage,
  getRecycleListBySpace
};