import "core-js/modules/es.array.push.js";
import { useRoute } from 'vue-router';
import { getToken, setToken } from "@/utils/auth";
import { onUnmounted, ref, onMounted, watch, computed } from 'vue';
import tools, { each } from '@/utils/tools';
import Const from '@/utils/const';
import spaceService from '@/service/space';
import widgetPreview from './widgetPreview.vue';
export default {
  name: "spaceScenePreview",
  components: {
    widgetPreview
  },
  props: {
    props: {
      type: Object,
      default: () => {
        return {};
      }
    },
    showPanel: {
      type: Boolean,
      default: false
    },
    currType: {
      type: String,
      default: 'floor'
    }
  },
  setup(props) {
    var _props$props, _props$props2;
    let token = ref('');
    token.value = localStorage.getItem("KC_TOKEN");
    let local = window.Kf.locale.value.replace('-', '');
    let spaceSceneDom = ref(null);
    let projectId = props === null || props === void 0 || (_props$props = props.props) === null || _props$props === void 0 ? void 0 : _props$props.projectId;
    let buildingId = props === null || props === void 0 || (_props$props2 = props.props) === null || _props$props2 === void 0 ? void 0 : _props$props2.buildingId;
    onMounted(() => {
      if (projectId && buildingId) {
        loadFloor(projectId, buildingId);
      }
    });
    let floorList = ref([]);
    const typeList = ref([{
      active: true,
      type: 'wall',
      name: '墙',
      show: true,
      label: {
        zhCN: '墙',
        en: 'walls'
      }
    }, {
      active: true,
      type: 'area',
      name: '区域',
      show: true,
      label: {
        zhCN: '区域',
        en: 'areas'
      }
    }, {
      active: true,
      type: 'model.door',
      name: '门',
      show: true,
      label: {
        zhCN: '门',
        en: 'doors'
      }
    }, {
      active: true,
      type: 'model.window',
      name: '窗',
      show: true,
      label: {
        zhCN: '窗',
        en: 'windows'
      }
    }, {
      active: true,
      type: 'model.building',
      name: '建筑',
      show: true,
      label: {
        zhCN: '建筑',
        en: 'buildings'
      }
    }, {
      active: true,
      type: 'model.light',
      name: '灯',
      show: true,
      label: {
        zhCN: '灯',
        en: 'lights'
      }
    }, {
      active: true,
      type: 'model.camera',
      name: '监控',
      show: true,
      label: {
        zhCN: '监控',
        en: 'cameras'
      }
    }, {
      active: true,
      type: 'model.sensor',
      name: '传感器',
      show: true,
      label: {
        zhCN: '传感器',
        en: 'sensors'
      }
    },
    // {active: true, type: 'model.desk', name: '桌', show: true},
    // {active: true, type: 'model.chair', name: '椅', show: true},
    {
      active: true,
      type: 'model.furniture',
      name: '家具',
      show: true,
      label: {
        zhCN: '家具',
        en: 'furniture'
      }
    }, {
      active: true,
      type: 'model.equipment',
      name: '设备',
      show: true,
      label: {
        zhCN: '设备',
        en: 'equipment'
      }
    }, {
      active: true,
      type: 'model.parking',
      name: '停车',
      show: true,
      label: {
        zhCN: '停车',
        en: 'parking'
      }
    }]);
    let typeGroups = ref([{
      name: '建筑构件',
      type: 'building',
      types: ['wall', 'area', 'model.door', 'model.window', 'model.building'],
      active: true,
      show: true,
      list: [],
      label: {
        zhCN: '建筑构件',
        en: 'Architectural construction '
      }
    }, {
      name: '设备列表',
      type: 'equipment',
      types: ['model.light', 'model.camera', 'model.sensor', 'model.equipment'],
      active: true,
      show: true,
      list: [],
      label: {
        zhCN: '设备列表',
        en: 'Equipment list '
      }
    }, {
      name: '家具列表',
      type: 'furniture',
      types: ['model.furniture'],
      active: true,
      show: true,
      list: [],
      label: {
        zhCN: '家具列表',
        en: 'Furniture list '
      }
    }, {
      name: '其他',
      type: 'other',
      types: ['model.parking'],
      active: true,
      show: true,
      list: [],
      label: {
        zhCN: '其他',
        en: 'Other list '
      }
    }]);
    let dataList = ref([]);
    const loadFloor = function (projectId, buildingId) {
      spaceService.getFloors(projectId, buildingId).then(res => {
        floorList.value = res;
        if (res[0]) {
          res[0].active = true;
        }
      });
    };
    const currFloor = computed(() => {
      let res = (floorList.value.find(item => item.active) || {}).nanoId;
      console.log('currFloor = ' + res);
      return res;
    });
    const subType = ref('');
    const type = computed(() => {
      console.log('---');
      const noSelGroups = typeGroups.value.filter(x => !x.active);
      let noSelGroupTypes = [];
      for (let i = 0; i < noSelGroups.length; i++) {
        const g = noSelGroups[i];
        for (let j = 0; j < g.types.length; j++) {
          const t = g.types[j];
          noSelGroupTypes.push(t);
        }
      }
      const tempTypes = typeList.value.filter(x => x.active).map(x => x.type);
      let types = tempTypes.filter(item => !noSelGroupTypes.includes(item));
      let tys = [];
      let subTypes = [];
      for (let t of types) {
        let t1 = t.split('.');
        if (t1.length === 1) {
          tys.push(t1[0]);
        } else {
          subTypes.push(t1[1]);
        }
      }
      subType.value = subTypes.join(',');
      return tys.join(',');
    });
    watch(props, (newValue, oldValue) => {
      doShowPanel(newValue.showPanel, newValue.currType);
    });
    const getTypeGroups = function (types) {
      local = 'en'; // 支持马来银行 英文
      // let groups = structuredClone(typeGroups.value);
      let groups = typeGroups.value;
      for (let i = 0; i < groups.length; i++) {
        let group = groups[i];
        group.name = group.label[local];
        group.list = group.types.map(type => {
          return types.find(item => item.type === type);
        });
        group.list.forEach(it => it.name = it.label[local]);
      }
      return groups;
    };
    let currDropDownType = ref('');
    const doShowPanel = function (isShow, type) {
      currDropDownType.value = type;
      if (type === 'floor') {
        dataList.value = floorList.value;
      }
      if (type === 'modelType') {
        dataList.value = getTypeGroups(typeList.value);
      }
    };
    let updateDataItem = function (item, group) {
      if (currDropDownType.value === 'floor') {
        each(dataList.value, _item => {
          _item.active = item === _item;
        });
      }
      if (currDropDownType.value === 'modelType') {
        if (group && group.active === false) {
          return;
        }
        item.active = !item.active;
      }
    };
    return {
      spaceSceneDom,
      floorList,
      currFloor,
      type,
      subType,
      updateDataItem,
      dataList,
      local,
      token
    };
  }
};