import { withModifiers as _withModifiers, createElementVNode as _createElementVNode, unref as _unref, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, createBlock as _createBlock, withCtx as _withCtx, createVNode as _createVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";
const _withScopeId = n => (_pushScopeId("data-v-529d9f74"), n = n(), _popScopeId(), n);
const _hoisted_1 = {
  class: "kingfisher_file_sort_box",
  style: {
    "height": "30px"
  }
};
import { toRefs, computed } from 'vue';
import contengModel from '@/model/newContent';
import contentModel from "@/model/newContent";
export default {
  __name: 'popFilter',
  props: ['filterList', 'orderType', 'currOrder', 'handleFun'],
  emits: ['handleOrder'],
  setup(__props, {
    emit: __emit
  }) {
    const props = __props;
    const emits = __emit;
    let {
      filterList,
      currOrder
    } = toRefs(props);
    let orderType = computed(() => {
      return props.orderType;
    });
    const orderHandle = val => {
      contentModel.currOrder.value.currOrderType = !orderType.value;
      emits('handleOrder', !orderType.value);
      props.handleFun();
    };
    const changeItem = function (item) {
      contentModel.currOrder.value.currOrderParam = item;
      props.handleFun(item);
    };
    return (_ctx, _cache) => {
      const _component_el_option = _resolveComponent("el-option");
      const _component_el_select = _resolveComponent("el-select");
      return _openBlock(), _createElementBlock("div", _hoisted_1, [_createElementVNode("i", {
        class: "iconfont icon-Group mr3 sort_i",
        onClick: _withModifiers(orderHandle, ["stop"])
      }), _createVNode(_component_el_select, {
        modelValue: props.currOrder,
        "onUpdate:modelValue": _cache[0] || (_cache[0] = $event => props.currOrder = $event),
        placeholder: _ctx.$t('PleaseChoose'),
        onChange: changeItem,
        "popper-class": "sort_select"
      }, {
        default: _withCtx(() => [(_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_unref(filterList), filter => {
          return _openBlock(), _createBlock(_component_el_option, {
            key: filter.sortKey,
            label: filter.label,
            value: filter.sortKey
          }, null, 8, ["label", "value"]);
        }), 128))]),
        _: 1
      }, 8, ["modelValue", "placeholder"])]);
    };
  }
};