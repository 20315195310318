import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, resolveComponent as _resolveComponent, createBlock as _createBlock, withCtx as _withCtx, createVNode as _createVNode } from "vue";
const _hoisted_1 = {
  class: ""
};
const _hoisted_2 = {
  class: "flex-row-start-center h-100 pr"
};
const _hoisted_3 = {
  class: "flex-row-start-center h-100",
  style: {
    "width": "50%"
  }
};
const _hoisted_4 = {
  class: "el-dialog-content"
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_space_scene_preview = _resolveComponent("space-scene-preview");
  const _component_el_dialog = _resolveComponent("el-dialog");
  return _openBlock(), _createElementBlock("div", _hoisted_1, [_createVNode(_component_el_dialog, {
    "custom-class": "kf-3dc-dialog5 kf-video-dialog kf-preview-dialog kf-preview-dialog-resize",
    title: _ctx.$t('Preview'),
    modelValue: $setup.showDialog,
    "onUpdate:modelValue": _cache[2] || (_cache[2] = $event => $setup.showDialog = $event),
    width: "90%",
    "close-on-click-modal": true,
    "show-close": true,
    draggable: "",
    onClose: $setup.close
  }, {
    title: _withCtx(() => [_createElementVNode("div", _hoisted_2, [_createElementVNode("div", _hoisted_3, _toDisplayString(_ctx.$t('Preview')), 1), $props.type === 'space' ? (_openBlock(), _createElementBlock("i", {
      key: 0,
      class: "iconfont icon-liebiao1 kf-space-i1",
      onClick: _cache[0] || (_cache[0] = $event => $setup.showPanelBox('modelType'))
    })) : _createCommentVNode("", true), $props.type === 'space' ? (_openBlock(), _createElementBlock("i", {
      key: 1,
      class: "iconfont icon-caidanhezi kf-space-i2",
      onClick: _cache[1] || (_cache[1] = $event => $setup.showPanelBox('floor'))
    })) : _createCommentVNode("", true)])]),
    default: _withCtx(() => [_createElementVNode("div", _hoisted_4, [$props.type === 'space' && $setup.showDialog ? (_openBlock(), _createBlock(_component_space_scene_preview, {
      key: 0,
      props: $props.data,
      showPanel: $setup.showPanel,
      currType: $setup.currType
    }, null, 8, ["props", "showPanel", "currType"])) : _createCommentVNode("", true)])]),
    _: 1
  }, 8, ["title", "modelValue", "onClose"])]);
}