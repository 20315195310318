import "core-js/modules/es.array.push.js";
import { ref } from 'vue';
import projectModel from "@/model/project";
import contentModel from '@/model/newContent';
import tools from "@/utils/tools";
import authorityService from '@/service/authority';
import { ElMessageBox } from 'element-plus';
import userEditDialog from "../userEditDialog.vue";
export default {
  name: "authorityList",
  components: {
    userEditDialog
  },
  props: {
    search: {
      type: String,
      default: () => {
        return '';
      }
    }
  },
  setup(props) {
    let locale = ref(window.Kf.locale.value.replace("-", ""));
    let roleOptions = ref([{
      name: Kf.t('ProjectAdministrator'),
      value: 'management'
    }, {
      name: Kf.t('Editor'),
      value: 'editorial'
    }, {
      name: Kf.t('Viewers'),
      value: 'observer'
    }]);
    const getDataList = () => {
      let list = projectModel.authorityList.value;
      let filterName = 'userName';
      let type = contentModel.currOrder.value.currOrderParam;
      list.sort((a, b) => {
        if (contentModel.currOrder.value.currOrderType) {
          return new Date(a[type]).getTime() - new Date(b[type]).getTime();
        } else {
          return new Date(b[type]).getTime() - new Date(a[type]).getTime();
        }
      });
      return list.filter(comp => {
        let filterValue = comp[filterName];
        return !props.search || tools.pinyinMatch(filterValue, props.search);
      });
    };
    const roleChange = function (role) {
      let roleName = '';
      switch (role) {
        case 'creator':
          roleName = Kf.t('Creator');
          break;
        case 'editorial':
          roleName = Kf.t('Editor');
          break;
        case 'observer':
          roleName = Kf.t('Viewers');
          break;
        case 'management':
          roleName = Kf.t('ProjectAdministrator');
          break;
      }
      return roleName;
    };
    const handleDelete = function (row) {
      ElMessageBox.confirm(Kf.t('AreYouSureToDeleteTheUserPermission'), Kf.t('Hint'), {
        confirmButtonText: Kf.t('Sure'),
        cancelButtonText: Kf.t('Cancel'),
        type: 'warning'
      }).then(() => {
        authorityService.deleteUser(row).then(result => {
          projectModel.loadAuthorityList(projectModel.currProject.value);
        });
      }).catch(() => {});
    };
    let dialogEditRoleVisible = ref(false);
    let currRowData = ref({});
    let editRoleList = ref({});
    const handleEdit = function (row) {
      dialogEditRoleVisible.value = true;
      currRowData.value = row;
      editRoleList.value = _.cloneDeep(row);
      editRoleList.value.users = [];
      editRoleList.value.users.push({
        userId: row.userId,
        userName: row.userName
      });
    };
    const updateVisible = function (val) {
      dialogEditRoleVisible.value = val;
    };
    return {
      getDataList,
      roleChange,
      handleDelete,
      handleEdit,
      dialogEditRoleVisible,
      currRowData,
      editRoleList,
      roleOptions,
      locale,
      updateVisible
    };
  }
};