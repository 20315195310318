import { ref, computed, onMounted } from 'vue';
import Const from '@/utils/const';
import contentModel from '@/model/newContent';
import tools from "@/utils/tools";
import { ElMessage } from "element-plus";
import artistPreview from "../artistPreview.vue";
export default {
  name: "libraryWidgetList",
  props: {
    search: {
      type: String,
      default: () => {
        return '';
      }
    }
  },
  components: {
    artistPreview
  },
  setup(props, {
    emit
  }) {
    let loading = ref(contentModel.widgetLoading);
    let locale = window.Kf.locale.value.replace("-", "");
    let showOpe = ref();
    let currBatch = ref(null);
    let currPart = ref({});
    const categoryList = computed(() => {
      let list = [];
      let filterName = 'name';
      console.log(456);
      list = contentModel.librarySpaceList.value;
      let type = contentModel.currOrder.value.currOrderParam;
      list.sort((a, b) => {
        if (contentModel.currOrder.value.currOrderType) {
          return new Date(a[type]).getTime() - new Date(b[type]).getTime();
        } else {
          return new Date(b[type]).getTime() - new Date(a[type]).getTime();
        }
      });
      let _list = list.filter(comp => {
        let filterValue = comp[filterName];
        if (contentModel.currMenu.value.code === 'library') {
          let _label = comp.name;
          if (comp.labelInterpret && typeof comp.labelInterpret === 'string') {
            _label = JSON.parse(comp.labelInterpret)[locale];
          } else if (comp.labelInterpret && typeof comp.labelInterpret === 'object') {
            _label = comp.labelInterpret[locale];
          }
          filterValue = _label;
        }
        return !props.search || tools.pinyinMatch(filterValue, props.search);
      });
      return contentModel.getCategoryByWidgetList(_list);
    });
    const getComName = function (part) {
      return contentModel.getComName(part);
    };
    const getEditTime = function (part) {
      return contentModel.getEditTime(part);
    };
    let host = ref('');
    onMounted(() => {
      let url = window.location.href;
      host.value = url.substring(0, url.indexOf("/#/"));
      if ('development' === process.env.NODE_ENV) {
        host.value = 'http://dev.kingfisher.live:8000';
      }
    });
    const getImageUrl = function (item) {
      let url = require('../../../styles/img/defaultSceneImg.png');
      if (!!(item !== null && item !== void 0 && item.screenshotPath)) {
        url = host.value + Const.servicePath.value + '/api/repo/kf-part/find-resource?path=' + item.screenshotPath + '&projectId=' + contentModel.myProject.value.uuid;
      }
      if (item && Reflect.has(item, 'thumbnail') && !item.screenshotPath) {
        url = Const.servicePath.value + `/api/repo/kf-space/find-file?path=${contentModel.myProject.value.uuid}/parts${item.thumbnail}`;
      } else if (item && Reflect.has(item, 'thumbnail') && item.screenshotPath) {
        url = Const.servicePath.value + `/api/repo/kf-space/find-file?path=${contentModel.myProject.value.uuid}/parts/${item.screenshotPath}`;
      }
      return url;
    };
    const imgShow = function (content) {
      let show = false;
      if (content && !!content.screenshotPath) {
        show = true;
      }
      if (content && !!content.thumbnail) {
        show = true;
      }
      return show;
    };
    const partCheckCount = list => {
      return list.filter(part => part.isChecked).length;
    };
    const batchBtns = computed(() => {
      return [{
        name: Kf.t('Delete'),
        type: "delBybatch"
      }, {
        name: Kf.t('SelectAll'),
        type: "selectAllBybatch"
      }, {
        name: Kf.t('Cancel') + Kf.t('SelectAll'),
        type: 'noSelectAllBybatch'
      }];
    });
    const showBtnOpe = item => {
      return showOpe.value === item.uuid;
    };
    const getBtnOpes = part => {
      return [{
        name: Kf.t('Preview'),
        type: "partPreview"
      }];
    };
    let previewDialog = ref(false);
    const setPartByBtn = (type, catgory, list) => {
      currPart.value = catgory;
      let uuidArr = [];
      switch (type) {
        case "partPreview":
          previewDialog.value = true;
          break;
        case 'selectAllBybatch':
          if ((list === null || list === void 0 ? void 0 : list.length) > 0) {
            list.forEach(part => {
              part.isChecked = true;
            });
          }
          break;
        case 'noSelectAllBybatch':
          if ((list === null || list === void 0 ? void 0 : list.length) > 0) {
            list.forEach(part => {
              part.isChecked = false;
            });
          }
          break;
        case 'delBybatch':
          if ((list === null || list === void 0 ? void 0 : list.length) > 0) {
            uuidArr = list.filter(part => part.isChecked).map(part => part.uuid);
            contentModel.deletePart(contentModel.myProject.value.uuid, uuidArr, 1, null, null, res => {
              if (res.code == 200) {
                contentModel.loadCategory(contentModel.myProject.value);
                const msg = Kf.t('DeleteSuccess');
                ElMessage.success(msg);
              } else {
                ElMessage.error(res);
              }
            });
          }
          break;
      }
    };
    const doBatch = (item, list) => {
      if (currBatch.value !== item) {
        currBatch.value = item;
      } else {
        currBatch.value = null;
      }
      list.forEach(part => part.isChecked = false);
      console.log("-doBatch-", item, list, currBatch.value);
    };
    const closeDialog = function (val) {
      previewDialog.value = val;
    };
    return {
      closeDialog,
      doBatch,
      loading,
      categoryList,
      getEditTime,
      getComName,
      getImageUrl,
      imgShow,
      showOpe,
      currBatch,
      partCheckCount,
      batchBtns,
      showBtnOpe,
      getBtnOpes,
      setPartByBtn,
      previewDialog,
      currPart
    };
  }
};