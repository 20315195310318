import "core-js/modules/es.array.push.js";
import { onMounted, onUnmounted, ref, watch } from 'vue';
import { Web3dManager } from '../../lib/web3d';
import Axios from 'axios';
import Const from '@/utils/const';
import SpaceService from '@/service/space';
import projectModel from "@/model/project";
export default {
  name: 'Preview',
  props: {
    props: {
      type: Object,
      default: () => {
        return {};
      }
    },
    theme: {
      type: Object,
      default: () => {
        return {};
      }
    },
    type: {
      type: String,
      default: 'space'
    },
    args: {
      type: Object,
      default: () => {
        return {
          floor: '',
          type: '',
          subType: ''
        };
      }
    }
  },
  setup(props, {
    emit
  }) {
    let web3d;
    const part = ref(Object.assign({}, props.props));
    const receiveEvent = ref();
    const cameras = ref([]);
    const events = ref([]);
    const type = ref();
    const uuid = ref();
    let projectId = 8402;
    let sceneId = '70658';
    const onTap = event => {
      console.log('场景点击', event);
    };
    let geoParamTemp = '';
    const loadSpaceGeoData = async () => {
      let scene = web3d && web3d.getScene();
      if (!scene || !props.args || !props.args.floor) {
        return;
      }
      if (!scene.puzzleLogic._pGlob.params) {
        scene.puzzleLogic._pGlob.params = {};
      }
      scene.puzzleLogic._pGlob.showRedBoxWhenLostMatch = true;
      let geoParam = {
        projectId: props.props.projectId,
        buildingId: props.props.buildingId,
        floorId: props.args.floor,
        type: props.args.type,
        subType: props.args.subType
      };
      let temp = JSON.stringify(geoParam);
      if (geoParamTemp === temp) {
        return;
      }
      geoParamTemp = temp;
      const registerContext = function (scene) {
        let register = scene && scene.puzzleLogic ? scene.puzzleLogic.registerContext : window.registerContext;
        if (register) {
          register('contextPath', '');
          register('context', {
            registerContext: registerContext,
            puzzleParams: {
              SpaceService
            },
            project: projectModel.currProject.value
          });
          register('canvas', web3d.domCanvas);
          register('axios', Axios);
          register('kingfisher', window.Kingfisher);
          register('scene', scene);
        }
      };
      registerContext(scene);
      if (!props.args.type && !props.args.subType) {
        scene.puzzleLogic._pGlob.params.spaceData = [];
        scene.puzzleLogic._pGlob.callbacks.updateGeo([]);
      } else {
        let geos = await SpaceService.findSpaceGeo(geoParam);
        if (geos) {
          if (geoParamTemp !== temp) {
            return;
          }
          scene.puzzleLogic._pGlob.params.spaceData = geos;
          scene.puzzleLogic._pGlob.callbacks.updateGeo(geos);
          let onReadyInterval = setInterval(() => {
            if (!scene.puzzleLogic._pGlob.geoBuilding && !scene._pendingData.length) {
              clearInterval(onReadyInterval);
              scene.activeCamera.focusOnObject(scene.getNodeByName('空间治理'), 1, {
                ratio: 1.2,
                minDistance: 35
              });
            } else if (scene.isDisposed) {
              clearInterval(onReadyInterval);
            }
          }, 50);
        }
      }
    };
    const onLoad = async scene => {
      console.log('场景下载完成', scene);
      console.log('场景镜头', web3d.getCameras());
      console.log('场景脚本', web3d.puzzleLogic);
      if (web3d.puzzleLogic) {
        console.log('事件列表', web3d.puzzleLogic.loadEvents());
        events.value = web3d.puzzleLogic.loadEvents() || [];
      }
      console.log('API', web3d.puzzle);
      cameras.value = web3d.getCameras();
      let loader;
      if (type.value === 'configuration') {
        let part = new Kingfisher.KPart(scene, {
          id: uuid.value
        });
        let partData = {
          url: 'k3dc://parts/' + uuid.value + '.k3dc'
        };
        part.updateProperties(partData);
        loader = new Kingfisher.KPartLoader('', scene, undefined, {
          resourceId: uuid.value
        });
      } else if (type.value === 'space') {
        loader = new Kingfisher.TransformNode('loader', scene);
        let objs = JSON.parse(props.props.k3dc);
        let nodes = [...objs.nodes];
        if (objs.others) {
          nodes.push(...objs.others);
        }
        for (let i = 0; i < nodes.length; i++) {
          let node = nodes[i];
          node.parentId = loader.id;
          node.enableState = i === 0;
        }
        objs.nodes = nodes;
        Kingfisher.SceneLoader.GetDefaultPlugin().plugin.loadAssetContainer(scene, objs, 'space://');
        for (let i = 0; i < loader.childrenNode.length; i++) {
          let node = loader.childrenNode[i];
          node.parent = loader;
          node.enableState = i === 0;
        }
        if (props.props.properties) {
          let properties = JSON.parse(props.props.properties);
          if ((properties === null || properties === void 0 ? void 0 : properties.positionY) != undefined) {
            loader.position.y = properties === null || properties === void 0 ? void 0 : properties.positionY;
          }
        }
      } else if (type.value === 'spaceScene') {
        await loadSpaceGeoData();
      }
      if (loader) {
        scene.activeCamera.setProjectOffsetY(0, true);
        scene.activeCamera.setPitch(-0.5, true);
        let onReadyInterval = setInterval(() => {
          if (scene.isReady() && (!loader.resource || loader.resource.isReady())) {
            clearInterval(onReadyInterval);
            scene.activeCamera.setYaw(type.value === 'configuration' ? -2.3 : 0.5);
            scene.activeCamera.focusOnObject(loader);
          } else if (scene.isDisposed) {
            clearInterval(onReadyInterval);
          }
        }, 50);
      }
    };
    const onLoadingHide = scene => {
      console.log('隐藏加载动画', scene);
    };
    const onRender = () => {
      //console.log('每帧渲染回调');
    };
    const onEvent = event => {
      console.log('收到消息', event);
      receiveEvent.value = event;
    };
    const issueEvent = event => {
      web3d.issueEvent(event);
    };
    const changeCamera = camera => {
      web3d.changeCamera(camera);
    };
    onMounted(async () => {
      type.value = props.type;
      console.log("-onMounted-", props);
      if (props.props.tronTheme && props.props.tronTheme.sceneId) {
        projectId = props.props.tronTheme.projectId;
        sceneId = props.props.tronTheme.sceneId;
      } else {
        projectId = 8402;
        sceneId = '70658';
      }
      if (type.value === 'space') {
        uuid.value = props.props.k3dcId;
      } else if (type.value === 'spaceScene') {
        let buildings = await SpaceService.findSpaceGeo({
          projectId: props.props.projectId,
          type: 'building',
          buildingId: props.props.buildingId
        });
        let partObj = buildings[0];
        let themeId = -2;
        if (partObj && partObj.properties.themeId) {
          themeId = partObj.properties.themeId;
        }
        let theme = (await SpaceService.querySpaceThemeList({
          projectId: 8402
        })).filter(x => x.uuid === themeId)[0];
        if (theme) {
          projectId = theme.projectId;
          sceneId = theme.sceneId;
        } else {
          projectId = 8402;
          sceneId = '70658';
        }
        watch(props, (newValue, oldValue) => {
          loadSpaceGeoData();
        }, {
          deep: true
        });
      } else {
        console.error('UnknownType: ', type.value);
        return;
      }
      let lib;
      const root = '/cloud/';
      if (Const.develop) {
        lib = '/static/';
      } else {
        lib = root + 'market/static/';
      }
      let schemes;
      schemes = {
        'root://': root + 'api/repo/kf-scene/find-resources?path=' + projectId,
        'k3dc://': root + 'api/repo/kf-scene-3d/find-resources?path=' + projectId,
        'space://': root + '/api/repo/kf-space/find-file?path=' + projectId,
        'resources://': root + 'resources/',
        'lib://': lib
      };
      web3d = new Web3dManager('idCanvas', {
        projectId: projectId,
        sceneId: [sceneId],
        schemes: schemes,
        isReady: true
      });
      web3d.registerWeb3dListener({
        onTap: onTap,
        onLoad: onLoad,
        onLoadingHide: onLoadingHide,
        onRender: onRender,
        onEvent: onEvent
      });
    });
    onUnmounted(() => {
      web3d.destroy();
    });
    return {
      cameras,
      events,
      issueEvent,
      changeCamera
    };
  }
};