<template>
    <div class="kf-newContent-right-header">
        <div class="kf-newContent-right-header-title" v-if="currMenu.code!=='frecycleBin'&& currMenu.code!=='library'"
             :title="currMenu.name+'/'+currLeftContentName">
            <span style="color: #fff">{{ currMenu.name }} /</span> {{ currLeftContentName }}
        </div>
        <div class="kf-newContent-right-header-title" v-if="currMenu.code==='library'"
             :title="currMenu.name">
            <span style="color: #fff">{{ currMenu.name }} </span>
        </div>
        <div class="kf-newContent-right-header-title" v-if="currMenu.code==='frecycleBin'"
             :title="currMenu.name+'/'+recyBinName">
            <span style="color: #fff">{{ currMenu.name }} /</span> {{ recyBinName }}
        </div>

        <!-- 回收站切换 -->
        <recycleHeader v-if="currMenu?.code === 'frecycleBin'" :menuTab="menuTab"
                       v-model:currMenuTab="currMenuTab"/>
        <div class="kf-newContent-right-header1" v-else>
            <div class="kf-newContent-right-header-left">
<!--                <div class="kf-newContent-right-header-tab "
                     v-if="!currMenu || (currMenu && currMenu.code === 'library')">
                    <div class="kf-newContent-right-header-tab-item" v-for="item in menuLibraryTab" :key="item.code"
                         @click="handleMenuTab(item,'library')"
                         :class="[currLibMenuTab.code === item.code ? 'kf-newContent-right-header-tab-item-active' : '']">
                        {{
                        $t(item.key)
                        }}
                    </div>
                </div>-->
                <div class="kf-newContent-right-header-tab "
                     v-if="!currMenu || (currMenu && currMenu.code !== 'tutorial' &&  currMenu.code !== 'library')">
                    <div class="kf-newContent-right-header-tab-item" v-for="item in menuTab" :key="item.code"
                         @click="handleMenuTab(item)"
                         :class="[currMenuTab.code == item.code ? 'kf-newContent-right-header-tab-item-active' : '']">{{
                        $t(item.key)
                        }}
                    </div>
                </div>

                <div class="kf-newContent-right-header-search kf-search1 dd">
                    <el-input :placeholder="$t('Search')" prefix-icon="Search" clearable v-model="search"
                              @input="doSearch"></el-input>
                </div>

                <!-- 排序组件 -->
                <popFilter
                        :filterList="orderState.filterList"
                        v-model:currOrder="currOrder"
                        :order-type="orderType"
                        @handleOrder="orderHandle"
                        :handleFun="orderState.orderHandle"/>
            </div>
            <div class="kf-newContent-right-header-right">
        <div class="kf-newContent-right-header-import" @click="createScene"
             v-if="(currMenuTab.code === 'scene' || currMenuTab.code === 'space') && ((currMenu.code !== 'library' && currMenu.code !== 'tutorial') || isProject)">
            <i class="iconfont icon-add-bold  mr8 ml8"></i>{{ $t('CreateScene') }}
        </div>

        <div class="kf-newContent-right-header-import" @click="addUser"
             v-if="currMenuTab.code === 'permissions' && currMenu.code === 'file'">
            <i class="iconfont icon-add-bold  mr8 ml8"></i>{{ $t('AddUser') }}
        </div>

                <div class="kf-newContent-right-header-import" style="padding:0;background: transparent" v-if="currMenu.code === 'library' && currUser.manager">
                    <el-upload ref="uploadThemeBox" multiple accept=".zip" class="upload-demo"
                               :http-request="uploadWidgetResource"
                               :on-success="doUploadThemeSuccess" action="#">
                        <div class="kf-3dc-dialog-select-con kingfisher_input-new1" style="height: 35px">
                        </div>
                        <div class="kf-newContent-right-header-import" style="gap: 0"><i
                                class="iconfont icon-add-bold mr10"></i>
                            {{ $t('ImportComponent') }}
                        </div>
                    </el-upload>

                </div>

        </div>
        </div>
        <!--        &lt;!&ndash; 发布 &ndash;&gt;-->
        <!--        <Publish v-model:publishVisible="publishVisible"/>-->

        <user-edit-dialog :show="dialogAddUserVisible" :isCreate="true" @propChange="updateVisible"></user-edit-dialog>

    </div>
</template>

<script>
import {computed, onMounted, ref, nextTick, watch, reactive} from 'vue'
import tools, {each} from "@/utils/tools";
import contentModel, {
    isTronThemeDetails, tronThemeCategoryList, currTronTheme, createTronThemeAssets,
    tronThemeList, queryPartListByTheme, tronComList
} from '@/model/newContent'
import projectModel from "@/model/project";
// import dataMapModel from "@/model/dataMapping";
 import recycleHeader from "./recycleHeader.vue";
import projectService from "@/service/project";
import ProjectData from "@/model/project";
import {ElMessage, ElMessageBox} from "element-plus";
import recycleBin from "@/model/recycleBin";
import spaceService from "@/service/space";
import popFilter from "../miscCp/popFilter.vue";
import userEditDialog from "../userEditDialog.vue";


export default {
    name: "contentHeader",
    components: {
      popFilter,recycleHeader,userEditDialog
    },
    props: {},
    setup(props, {emit}) {
        let fileUploadBox = ref(null)

        let loading = ref(false);
        let publishVisible = ref(false);
        let uploadThemeBox = ref(null)
        let menuLibraryTab = ref([
            {name: Kf.t('OfficialContent'), code: 'official', key: 'OfficialContent'},
            {name: Kf.t('UserContents'), code: 'userContent', key: 'UserContents'},]);
        // 排序的对象
        let orderState = reactive({
            filterList: [
                {
                    label: Kf.t('RecentlyEdited'),
                    code: 0,
                    sortKey: 'updateDate'
                }, {
                    label: Kf.t('CreateOrder'),
                    code: 2,
                    sortKey: 'createDate'
                }
            ],
            orderType: true,
            orderHandle: (key) => {
                if (key) {
                    currOrder.value = key
                }
                if (contentModel.currHeaderTab.value.code === 'space') {
                    // recycleBin.sortByList(projectModel.space.value, currOrder.value, orderType.value);
                }
            },
        });
        const orderHandle = function (val) {
            orderType.value = val
            orderState.orderHandle(orderState.currOrder);
        }
        let orderType = ref(true);
        let currOrder = ref('updateDate');
        let currMenuTab = ref({});
        let currLibMenuTab = ref(menuLibraryTab.value[0]);
        let search = ref('');
        let importModelVisible = ref(false);
        let ruleFormRef = ref();

        // START library 主题 相关
        let isImportCom = ref(false); // 导入 主题 组件 dialog
        let newCategory = ref("");  // 创建新分类名称
        let selThemeCatgory = ref("");
        let comFileData = ref();
        let comFileName = ref("")

        const createSceneRules = reactive({
            name: [
                {required: true, message: Kf.t('PleaseFillInTheSceneName'), trigger: 'blur'}
            ],
        })
        onMounted(() => {
            if (!contentModel.currHeaderTab.value) {
                let currInfo = localStorage.getItem('currTabInfo') !== '' ? JSON.parse(localStorage.getItem('currTabInfo')) : {};
                let _tab = menuTab.value[0];
                if (currInfo?.code) {
                    _tab = Object.assign({}, currInfo)
                }
                localStorage.setItem('currTabInfo', '{}');
                currMenuTab.value = _tab;
                contentModel.currHeaderTab.value = _tab
            } else {
                currMenuTab.value = contentModel.currHeaderTab.value;
            }
            handleMenuTab(currMenuTab.value)
            handleMenuTab(currLibMenuTab.value, 'library')
        })
        const handleMenuTab = function (menu, type) {

            if (type) {
                currLibMenuTab.value = menu;
                contentModel.currLibTab.value = menu;
                contentModel.currLeftContent.value = {};
            } else {
                currMenuTab.value = menu;
                contentModel.currHeaderTab.value = menu;
            }
            if (contentModel.currMenu.value.code === 'file') {
                projectModel.sceneList.value = [];
                projectModel.switchProject(projectModel.currProject.value);
            } else {
                tronThemeList.value = [];
                projectModel.sceneList.value = [];
                contentModel.loadPart(contentModel.myProject.value)
                contentModel.loadThemeGroups(contentModel.myProject.value)
            }

        }

        const importModel = function () {
            if (projectModel.currProject.value.uuid === undefined) {
                ElMessage({type: "warning", message: Kf.t('PleaseSelectTheItemFirst'), grouping: true})
                return false
            } else {
                importModelVisible.value = true;
            }

            //currImportModelTab.value=importModelTab.value[3]
        }
        const changeVisble = value => {
            importModelVisible.value = value;
        };

        let createSceneVisible = ref(false);
        let createSceneData = ref({
            name: '',
            description: '',
            sceneMode: 'scene2D'
        })
        let createSceneType = ref([{name: '3D' + Kf.t('EmptyScene'), code: 'scene3D'}, {
            name: '2D' + Kf.t('EmptyScene'),
            code: 'scene2D'
        }]);
        const createScene = function () {
            if (projectModel.currProject.value.uuid === undefined) {
                ElMessage({type: "warning", message: Kf.t('PleaseSelectTheItemFirst'), grouping: true})
                return false
            } else {
                createSceneVisible.value = true;
                if (contentModel.currLeftContentName.value) {
                    createSceneData.value = {
                        name: Kf.t('Scene') + projectModel.getNameMaxNumber(projectModel.sceneList.value),
                        sceneMode: 'scene3D',
                        projectUuid: projectModel.currProject.value.uuid
                    }
                    if (!contentModel.isProject.value) {
                        createSceneData.value.category = contentModel.currLeftContent.value.category
                    }

                    if (currMenuTab.value?.code === 'space') {
                        doCreateSpace();
                    }

                } else {
                    ElMessage({type: 'warning', message: Kf.t('SelectTheGroupOnTheLeft'), grouping: true});
                }
            }

        }
        let uploadImgBox = ref(null)
        const doUploadSuccess = function (response, file, fileList) {
            uploadImgBox.value.clearFiles();
            if (response) {
                createSceneData.value.screenshotPath = response
            } else {
                ElMessage({type: 'warning', message: response.message, grouping: true});
            }
        }


        const importArtistSceneByBtn = function () {
            isArtDialog.value = true;
        };


        let uploadFiles = ref([])
        // 主题 组件上传处理
        const themePartChange = function (evt) {
            uploadFiles.value = [];
            let formData = new FormData();
            comFileName.value = evt.target.files[0].name.substring(0, evt.target.files[0].name.lastIndexOf('.'));
            formData.append('name', evt.target.files[0].name);
            formData.append('file', evt.target.files[0]);
            //formData.append("tronThemeUuid", currTronTheme.value.uuid);
            comFileData.value = formData;
            uploadFiles.value = evt.target.files
            // evt.target.value = null;
        }
        // 主题 组件上传处理 关闭dialog
        const closeThemePartDialog = function () {
            if (!comFileData.value?.has('file')) return;
            if (loading.value) return;
            //const systemFlag = contentModel.currLibTab.value.code === 'userContent' ? 2 : 1;
            if (uploadFiles.value?.length > 0) {
                let params = []
                uploadFiles.value.forEach(file => {
                    let _form = new FormData();
                    let _name = file.name.substring(0, file.name.lastIndexOf('.'));
                    _form.append('file', file);
                    _form.append('name', _name);
                   _form.append("tronThemeUuid", '-2');
                    if (selThemeCatgory.value && selThemeCatgory.value !== '') {
                        _form.append('category', selThemeCatgory.value);
                    }
                    //_form.append('systemFlag', systemFlag);
                    isImportCom.value = false;
                    params.push(_form)
                })
                Promise.all(params.map((param) => {
                    return spaceService.importWidget(param, projectModel.currProject.value.uuid)
                })).then(res => {
                    let count=0;
                    let errorName=''
                    if (res.length > 0) {
                        res.forEach((item,index) => {
                            if (item === 'success') {
                                count++;
                            }else{
                                errorName+=(errorName!==''?',':'')+uploadFiles.value[index].get('name')
                            }
                        })
                    }
                    if(count===0){
                        ElMessage.error(Kf.t('ImportError'))
                    }else if(count===res.length){
                        ElMessage.success(Kf.t('ImportSuccess'));
                    }else{
                        ElMessage.warning(errorName+Kf.t('ImportSuccess'));
                    }
                    queryPartListByTheme(projectModel.currProject.value.uuid, currTronTheme.value.uuid);
                    loading.value = false;
                    fileUploadBox.value.value = null;
                }).finally((result) => {
                    comFileName.value = "";
                    selThemeCatgory.value = ""
                    comFileData.value = null;
                    initNewCategory.value = "";
                })
            }

        }

        const doCreateSpace = function () {
            if (ProjectData.currProject.value?.uuid) {
                let nanoId = Kingfisher.getNanoID();
                let project = {
                    buildingId: nanoId,
                    name: Kf.t('NewFloor') + projectModel.getNameMaxNumber(projectModel.sceneList.value),
                    nanoId: nanoId,
                    properties: {visible: true},
                    type: "building",
                    uuid: 0,
                    projectId: ProjectData.currProject.value?.uuid
                }
                spaceService.saveSpace(project).then(() => {
                    projectModel.switchProject(ProjectData.currProject.value)
                })
            }
        }
        const doCreateScene = function () {
            if (createSceneData.value.name == '') return;
            // if (ProjectData.currProject.value?.uuid) {
            //     let sceneData = getSceneData(createSceneData.value.type);
            //     let options = Object.assign({k3ds: sceneData}, createSceneData.value);
            //     options.sceneMode = createSceneData.value.sceneMode;
            //     let libUuid = contentModel.libraryData.value.uuid;
            //     let projectUuid = ProjectData.currProject.value.uuid;
            //     let copyList = [
            //         //`3dc://${libUuid}/textures/xUpQxtRw8vVUxFnMbjzGr.png>3dc://${projectUuid}/textures/xUpQxtRw8vVUxFnMbjzGr.png`
            //         `3dc://${libUuid}/textures/lz8f_4K7Dikl11el1Cnov.png>3dc://${projectUuid}/textures/lz8f_4K7Dikl11el1Cnov.png`,
            //         `3dc://${libUuid}/textures/0bdftx4XmrOWnJe0pOHkD.jpeg>3dc://${projectUuid}/textures/0bdftx4XmrOWnJe0pOHkD.jpeg`,
            //         `3dc://${libUuid}/textures/R5J1HyZ_21rj8cO1Hc-Go.png>3dc://${projectUuid}/textures/R5J1HyZ_21rj8cO1Hc-Go.png`,
            //         `3dc://${libUuid}/textures/M66zclKwU-KwWYLGBIFAe.png>3dc://${projectUuid}/textures/M66zclKwU-KwWYLGBIFAe.png`,
            //         `3dc://${libUuid}/textures/ogogf41ABjJjJG1ZqCJ-q.png>3dc://${projectUuid}/textures/ogogf41ABjJjJG1ZqCJ-q.png`
            //     ];
            //     projectService.copyResources(copyList).then(() => {
            //         Kingfisher.FileTools.ClearImportFileList();
            //     });
            //     projectService.createScene(options).then(result => {
            //         if (result?.status == '406') {
            //             ElMessage({type: "error", message: Kf.t('UserSRemainingSpaceIsNotEnough'), grouping: true})
            //         } else {
            //             createSceneVisible.value = false;
            //             //projectModel.sceneList.value.push(result);
            //             pageModel.currScene.value = result;
            //             console.log(result);
            //             console.log("create new scene: ", result);
            //             if (contentModel.currMenu.value.code == 'file') {//项目
            //                 projectModel.selectedProject(projectModel.currProject.value)
            //             }
            //             ElMessage({
            //                 type: 'success',
            //                 grouping: true,
            //                 customClass: 'kf-3dc-create-success',
            //                 message: Kf.t('SceneCreatedSuccessfully')
            //             });
            //         }
            //     });
            // } else {
            //     ElMessage({type: 'warning', message: Kf.t('PleaseSelectTheItemFirst'), grouping: true});
            // }

        }
        let locale = window.Kf.locale.value.replace("-", "");
        const doSearch = function (val) {
            //pageModel.filterAllProject(val)
            emit("search", search.value);
            //筛选主题里面的组件列表
            if (isTronThemeDetails) {
                if (val) {
                    let list = tronComList.value.filter(item => {
                        let label;
                        if (typeof item.labelInterpret == 'object' && item?.labelInterpret) {
                            label = item.labelInterpret[locale] ? item.labelInterpret[locale] : item.name;
                        } else if (JSON.parse(item.labelInterpret)) {
                            label = JSON.parse(item.labelInterpret).category ? JSON.parse(item.languageInterpret).category[locale] : JSON.parse(item.labelInterpret)[locale] ? JSON.parse(item.labelInterpret)[locale] : item.name;
                        } else {
                            label = item.name
                        }
                        return label.indexOf(val) > -1 || tools.pinyinMatch(label, val)
                    })
                    contentModel.classifyPartList(list);
                } else {
                    contentModel.classifyPartList(tronComList.value);
                }
            }
        }

        const menuTab=computed(() =>{
            let res=[]
            let _code=contentModel.currMenu.value.code;
            if(_code === "frecycleBin"){
                    res= [
                        {name: Kf.t('Space'), code: "space", key: 'Space'},
                    ];
            } else if (_code === "library"){
                res= [
                    {name: Kf.t('Component'), code: "widget", key: 'Component'},
                    {name: Kf.t('Scene'), code: "scene", key: 'Scene'},
                ];
            } else {
                res = [
                    {name: Kf.t('Space'), code: "space", key: 'Space'},
                    {name: Kf.t('Permissions'), code: "permissions", key: 'Permissions'},
                ];
            }
            return res;
        })
        const changeMenuTab = (code, v) => {
            const setTabValue = function () {
                if (menuTab?.value?.at(0)) {
                    currMenuTab.value = menuTab.value.at(0);
                }
                contentModel.currHeaderTab.value = currMenuTab.value
            }
            if (!v) {
                if (contentModel.currHeaderTab.value?.code === undefined) {
                    setTabValue()
                } else {
                    const _tab = menuTab.value.find(i => contentModel.currHeaderTab.value?.code === i.code);
                    if (!_tab) {
                        setTabValue()
                    }
                }
            }

        };
        // 监听menu变化
        watch(
            () => contentModel.currMenu.value.code,
            (new_v, old_v) => {
                let _v = old_v === new_v
                changeMenuTab(new_v, _v);
                if (new_v === "frecycleBin") {
                     recycleBin.loadData(contentModel.currHeaderTab.value)
                }
                contentModel.currLeftContent.value = {};
                recycleBin.isMenuLabel.value = new_v;
            }
        );

        const doAllShowFlag = function (flag, msg) {
            contentModel.updateShowFlag(flag, msg)
        }
        const doAllPubFlag = function (flag, msg) {
            contentModel.updatePubFlag(flag, msg)
        }
        const recyBinName = computed(() => {
            let date = new Date();
            let str = (date.getFullYear()) + '-' + ((date.getMonth() + 1) < 10 ? ('0' + (date.getMonth() + 1)) : (date.getMonth() + 1)) + '-' + (date.getDate() < 10 ? ('0' + date.getDate()) : date.getDate())
             return recycleBin.currentDate.value === str ? Kf.t('Today') : recycleBin.currentDate.value
        })


        let uploadImgBox1 = ref(null);
        let importState = ref(false)
        let importWidgetsState = ref(false)

        const beforeUpload1 = function () {
            if (projectModel.currProject.value.uuid === undefined) {
                ElMessage({type: "warning", message: Kf.t('PleaseSelectTheItemFirst'), grouping: true})
                return false
            } else {
                return true
            }
        }
        const uploadResource1 = function (item) {
            let param = new FormData();
            param.append('file', item.file);
            importState.value = true
            // partService.importWidget(param, projectModel.currProject.value.uuid).then(result => {
            //     importState.value = false;
            //     if (result === 'success') {
            //         doUploadSuccess1(result)
            //     } else {
            //         ElMessage({type: 'warning', message: result, grouping: true});
            //     }
            // })
        }

        const doUploadSuccess1 = function (response, file, fileList) {
            uploadImgBox1.value.clearFiles();
            if (response === 'success') {
                projectModel.switchProject(projectModel.currProject.value, createSceneData.value)
            } else {
                ElMessage({type: 'warning', message: response.message, grouping: true});
            }
        }
        const changeTitle = function (data) {
            dataMapModel.addDataParams.dialogTitle = data;
        }

        let importWidgetBox = ref(null)
        const importWidgets = function (item) {
            let param = new FormData();
            param.append('file', item.file);
            importWidgetsState.value = true
            // partService.importWidgets(param).then(result => {
            //     importWidgetsState.value = false;
            //     if (result === 'success') {
            //         doUploadSuccess1(result)
            //     } else {
            //         ElMessage({type: 'warning', message: result, grouping: true});
            //     }
            // })
        }
        const doImportSuccess = function (response, file, fileList) {
            importWidgetBox.value.clearFiles();
            if (response === 'success') {
                contentModel.loadPart(contentModel.myProject.value)
            } else {
                ElMessage({type: 'warning', message: response.message, grouping: true});
            }
        }

        const createAsset = function (type) {
            // console.log(projectModel.currProject.value);
            let key = type.charAt(0).toUpperCase() + type.slice(1);
            let addParams = {
                name: Kf.t(key) + tronThemeList.value.length,
                type,
                projectId: projectModel.currProject.value.uuid,
                groups: contentModel.currLeftContentName.value
            };
            const systemFlag = contentModel.currLibTab.value.code === 'userContent' ? 2 : 1;
            createTronThemeAssets(addParams, systemFlag, () => {
                projectModel.switchProject(projectModel.currProject.value, {category: contentModel.currLeftContentName.value});
            });
        }

        // tron theme 创建新的category
        const initNewCategory = (val) => {
            tronThemeCategoryList.value.push({label: val, value: val});
            selThemeCatgory.value = val;
            newCategory.value = "";
        }

        const closeTronThemeDialog = function () {
            console.log('closeTronThemeDialog', projectModel.currProject.value.uuid,);
        }


        const beforeUploadTheme = function () {
            if (currTronTheme.value?.uuid === undefined) {
                ElMessage({type: "warning", message: Kf.t('CouldNotUpload'), grouping: true})
                return false
            } else {
                return true
            }
        }
        const uploadThemeResource = function (item) {
            let param = new FormData();
            /* if(item?.file?.name){
                 param.append('name', item.file.name.substring(0, item.file.name.lastIndexOf('.')));
             }*/
            param.append('file', item.file);
            param.append('tronThemeUuid', currTronTheme.value.uuid);
            importState.value = true
            projectService.importTheme(param, projectModel.currProject.value.uuid).then(result => {
                importState.value = false;
                if (result?.uuid) {
                    currTronTheme.value = result
                    doUploadThemeSuccess(result)
                } else {
                    ElMessage({type: 'warning', message: result, grouping: true});
                }
            })
        }

        const doUploadThemeSuccess = function (response, file, fileList) {
            uploadThemeBox.value.clearFiles();
            queryPartListByTheme(projectModel.currProject.value.uuid, currTronTheme.value.uuid);
        }



        const uploadWidgetResource = function (item) {
            let param = new FormData();
             if(item?.file?.name){
                 param.append('name', item.file.name.substring(0, item.file.name.lastIndexOf('.')));
             }
            param.append('file', item.file);
            param.append('tronThemeUuid', '-2');
            importState.value = true
            spaceService.importWidget(param, contentModel.myProject.value.uuid).then(result => {
                importState.value = false;
                console.log("---importWidget",result);
                if(result==='success'){
                    doUploadWidgetSuccess(result)
                }else {
                    ElMessage({type: 'warning', message: result, grouping: true});
                }
            })
        }

        const doUploadWidgetSuccess = function (response, file, fileList) {
            uploadThemeBox.value.clearFiles();
            contentModel.loadCategory(contentModel.myProject.value)
        }

        const addAllHandler = function () {
            let ids = contentModel.getUuids(tronComList.value)
            projectService.updatePartTronTheme(ids, currTronTheme.value.uuid).then(result => {
                if (result === 'success') {
                    ElMessage({type: 'warning', message: Kf.t('AddSuccess'), grouping: true});
                    queryPartListByTheme(projectModel.currProject.value.uuid, currTronTheme.value.uuid);

                } else {
                    ElMessage({type: 'error', message: result, grouping: true});
                }
            })

        }
        let uploadSpaceBox = ref(false);
        let importSpaceState = ref(false)
        const uploadSpace = function (item) {
            let param = new FormData();
            param.append('file', item.file);
            param.append('toProId', projectModel.currProject.value.uuid);
            importSpaceState.value = true
            // spaceService.importSpace(param, projectModel.currProject.value.uuid).then(result => {
            //     importSpaceState.value = false;
            //     if (result === 'success') {
            //         doUploadSpaceSuccess(result)
            //     } else {
            //         ElMessage({type: 'warning', message: result, grouping: true});
            //     }
            // })
        }

        const doUploadSpaceSuccess = function () {
            if (contentModel.currMenu.value.code === 'file') {//项目
                projectModel.switchProject(projectModel.currProject.value)
            }
        }

        const createCategory = function () {

        }

        let dialogAddUserVisible = ref(false);

        const addUser = function () {
            dialogAddUserVisible.value = true;
        }
        const updateVisible = function (val) {
            dialogAddUserVisible.value = val;
        }

        return {
            currMenu: contentModel.currMenu,
            currGroup: contentModel.currGroup,
            projectList: projectModel.projectGroupList,
            isProject: contentModel.isProject,
            currLeftContentName: contentModel.currLeftContentName,
            currUser: contentModel.currUser,
            currentDate: '',
            menuTab,
            currMenuTab,
            search,
            handleMenuTab,
            importModel,
            menuLibraryTab,
            currLibMenuTab,
            importModelVisible,
            changeVisble,
            createScene,
            createSceneType,
            createSceneVisible,
            createSceneData,
            doUploadSuccess,
            doCreateScene,
            doSearch,
            doAllShowFlag,
            doAllPubFlag,
            orderState,
            orderType,
            orderHandle,
            currOrder,
            uploadImgBox,
            createSceneRules,
            recyBinName,
            publishVisible,
            uploadResource1,
            doUploadSuccess1,
            uploadImgBox1,
            importState,
            importWidgetsState,
            changeTitle,
            beforeUpload1,
            importWidgets,
            doImportSuccess,
            importWidgetBox,
            importArtistSceneByBtn,
            loading,
            createAsset,
            isTronThemeDetails,
            isImportCom,
            newCategory,
            tronThemeCategoryList,
            initNewCategory,
            selThemeCatgory,
            closeTronThemeDialog,
            beforeUploadTheme,
            doUploadThemeSuccess,
            uploadThemeResource,
            uploadThemeBox,
            addAllHandler,
            themePartChange,
            comFileData,
            comFileName,
            closeThemePartDialog,
            currLibTab: contentModel.currLibTab,
            uploadSpace,
            uploadSpaceBox,
            importSpaceState,
            createCategory,
            doUploadSpaceSuccess,
            fileUploadBox,
            addUser,
            dialogAddUserVisible,
            locale, updateVisible,
            uploadWidgetResource,
            doUploadWidgetSuccess
        }

    }
}
</script>

<style lang="scss">

.kf-importArt {
  border: 1px solid #222;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  border-radius: 5px;
  background: #202020;
  width: 30%;
  overflow-y: overlay;

  .el-dialog__header {
    height: 35px;
    line-height: 35px;
    padding: 0 1rem;
    background-color: #1d1d1d;
    // border-buttom: 1px solid #333;

    .my-header {
      i {
        color: #aaa;
      }
    }

    .el-dialog__headerbtn {
      top: 2px;
      height: 35px;
    }

    .el-dialog__title {
      font-size: 13px;
      color: #bbb;
    }
  }

  .el-dialog__body {
    border-top: 0;
    background-color: #121212;
    padding: 0px;

    .el-form-item__label {
      justify-content: flex-start;
      font-size: 13px;
      color: #ddd;
    }
  }

  .el-dialog__footer {
    background-color: #121212;
  }
}

.kf-importArt-dialog {
  padding: 15px;
  display: flex;
  flex-direction: column;
  gap: 20px;

  i {
    position: absolute;
    right: 8px;
  }
}

.kf-importArt-dialog-file {
  width: 100%;
  height: 100%;
  position: absolute;
  left: 0;
  top: 0;
  opacity: 0;
  cursor: pointer;
}

.kf-importArt-dialog-footer {
  display: flex;
  justify-content: flex-end;
  gap: 10px;
  font-size: 12px;

  button {
    width: 80px;
    height: 28px;
    border: none;
    outline: none;
    padding: 3px 10px;
    border-radius: 3px;
    color: #ddd;
    font-size: 12px;
    background: rgb(107, 107, 107);
  }

  .kf-importArt-dialog-footer-ok {
    background-color: #2978EC;
  }

  .kf-importArt-dialog-footer-ok[isLoading=true] {
    background-color: rgba(41, 120, 236, 0.5);
    cursor: not-allowed;
    color: #666;
  }

  button:hover {
    color: #fff;
    cursor: pointer;
  }
}

.upload-art {
  display: inline-block;
  width: 15px;
  height: 15px;
  border: 1px dashed #29ecb5;
}

.kf-lib-btns {
  display: flex;
  gap: 10px;
  padding-right: 5px;
}

.kf-import-category-box {
  display: flex;
  align-items: center;
  height: 30px;
  color: #aaa;
  font-weight: 500;
  letter-spacing: 1.5px;
  font-size: 13px;

  .kf-select-label {
    width: 100px;
    height: 100%;
    display: flex;
    align-items: center;
  }

  .el-select {
    width: calc(100% - 190px);
    height: 100%;

    .select-trigger {
      height: 100%;

      .el-input {
        height: 100%;

        .el-input__wrapper {
          height: 100%;
          box-shadow: none;
          border-radius: 3px;
          border: 1px solid #2c2c2c;
          outline: none;
          background-color: #000;
          padding: 0;
          padding-right: 5px;

          .el-input__inner {
            height: 100%;
            box-shadow: none;
            background: transparent;
            border: none;
            color: #999;
            font-family: Alibaba PuHuiTi;
          }

        }
      }
    }
  }

  .el-button.el-button--primary {
    width: 80px;
    background: #2978ec;
    height: 28px;
    font-family: Alibaba PuHuiTi;
    color: #ddd;
    font-size: 12px;
    border: none;
  }
}

.el-dialog__body {
  .kf-importArt-dialog {
    .kf-pub-copy {
      .kf-pub-copy-label {
        width: 100px;
      }

      .kf-pub-copy-main {
        width: calc(100% - 100px) !important;
      }
    }
  }
}
</style>
