import "core-js/modules/web.url-search-params.delete.js";
import "core-js/modules/web.url-search-params.has.js";
import "core-js/modules/web.url-search-params.size.js";
import { onMounted, getCurrentInstance } from "vue";
import mainContentLeft from './mainContentLeft.vue';
import mainContentRight from './mainContentRight.vue';
import contentModel from "../../model/newContent";
import { useRouter } from 'vue-router';
import Const from '@/utils/const';
import env from '@/model/env';
import projectModel from "@/model/project";
import 'url-search-params-polyfill';
// import {svsLogin} from '@/utils/action';
import oemManager from "@/utils/oem";
env.init();
export default {
  name: "newContent",
  components: {
    mainContentLeft,
    mainContentRight
  },
  setup() {
    const router = useRouter();
    const url = window.location.href;
    const params = new URLSearchParams(url.substring(url.indexOf('?') + 1));
    let ticket = params.get('ticket');
    if (ticket) {
      if (ticket.indexOf('#/') !== -1) {
        ticket = ticket.substring(0, ticket.indexOf('#/'));
      }
      ticket = decodeURI(ticket);
      // svsLogin('', '', ticket, (success, msg) => {
      //   if (success) {
      //     const _path = window.location.origin + window.location.pathname;
      //     window.location.href = _path + '#/content';
      //   } else {
      //     router.replace({path: '/'});
      //   }
      // });
    }
    // Const.updateServicePath()
    onMounted(() => {
      var _window;
      contentModel.loadCurrUser();
      if (((_window = window) === null || _window === void 0 || (_window = _window.performance) === null || _window === void 0 || (_window = _window.navigation) === null || _window === void 0 ? void 0 : _window.type) === 0) {
        projectModel.currProject.value = {};
      }
    });
  }
};