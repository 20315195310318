import { ref, watch, onMounted, nextTick } from 'vue';
import Const from "@/utils/const";
export default {
  name: "videoDialog",
  props: {
    show: {
      type: Boolean,
      default: false
    },
    data: {
      type: Object,
      default: () => {
        return {};
      }
    }
  },
  setup(props, {
    emit
  }) {
    let inited = ref(false);
    let videoDom = ref(null);
    let showDialog = ref(false);
    const options = ref({
      muted: false,
      //静音
      webFullScreen: true,
      speedRate: ["0.75", "1.0", "1.25", "1.5", "2.0"],
      //播放倍速
      autoPlay: true,
      //自动播放
      loop: true,
      //循环播放
      mirror: false,
      //镜像画面
      lightOff: false,
      //关灯模式
      control: false,
      //是否显示控制器
      title: '',
      //视频名称
      src: props.data.video,
      //视频源
      poster: 'img/kfFrame.png' //封面
    });
    let host = ref('');
    onMounted(() => {
      let url = window.location.href;
      host.value = url.substring(0, url.indexOf("/#/"));
      if ('development' === process.env.NODE_ENV) {
        host.value = 'http://dev.kingfisher.live:8000';
      }
      /*if (props.data.video != "") {
          init();
      }*/
    });
    const init = function () {
      inited.value = false;
      options.value.src = props.data.video;
      nextTick(() => {
        inited.value = true;
      });
    };
    const getVideoUrl = function () {
      return host.value + Const.servicePath.value + '/api/repo/kf-part/find-resource?path=' + props.data.video;
      //return 'https://dev.kingfisher.live/cloud/api/repo/kf-part/find-resource?path=' + props.data.video;
    };
    watch(props, (newValue, oldValue) => {
      showDialog.value = newValue.show;
    });
    watch(showDialog, (newValue, oldValue) => {
      if (!showDialog.value) {
        inited.value = false;
        if (videoDom.value) {
          videoDom.value.pause();
        }
      } else {
        init();
      }
      emit("propChange", showDialog.value);
    });
    const onPlay = function () {
      if (!showDialog.value && videoDom.value) {
        inited.value = false;
        videoDom.value.pause();
      }
    };
    return {
      showDialog,
      options,
      getVideoUrl,
      inited,
      videoDom,
      onPlay
    };
  }
};