import "core-js/modules/es.array.push.js";
import { unref as _unref, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode } from "vue";
const _hoisted_1 = {
  class: "kf-newContent-right-header1"
};
const _hoisted_2 = {
  class: "kf-newContent-right-header-left"
};
const _hoisted_3 = {
  class: "kf-newContent-right-header-tab"
};
const _hoisted_4 = ["onClick"];
const _hoisted_5 = {
  class: "kf-newContent-right-header-right"
};
import { toRefs, reactive, onMounted, ref } from "vue";
import recycleBinModel from '@/model/recycleBin';
import tools from "../../../utils/tools";
import { ElMessageBox } from "element-plus";
export default {
  __name: 'recycleHeader',
  props: ["menuTab", "currMenuTab"],
  emits: ["update:currMenuTab"],
  setup(__props, {
    emit: __emit
  }) {
    const props = __props;
    const {
      menuTab,
      currMenuTab
    } = toRefs(props);
    const emits = __emit;

    // 组件状态数据
    const state = reactive({
      search: '',
      lately: false,
      filterList: [{
        label: Kf.t('RecentlyDeleted'),
        code: 0,
        sortKey: 'lastModifiedDate'
      }],
      //orderType: false,
      currOrder: 'lastModifiedDate'
    });
    let orderType = ref(false);
    let currOrder = ref('lastModifiedDate');
    const handleMenuTab = menu => {
      console.log(menu, '====');
      recycleBinModel.menuCode.value = menu.code;
      recycleBinModel.currentDate.value = null;
      if (menu.code === 'space') {
        recycleBinModel.getBaseDateCountBySpace();
      } else if (menu.code === 'project') {
        recycleBinModel.getBaseDateCountByProject();
      }
      emits("update:currMenuTab", menu);
    };
    // 清空回收站的个人的数据
    const clearCycleState = () => {
      // console.log("清空回收站的所有数据", recycleBinModel.menuCode.value);
      let menu = recycleBinModel.menuCode.value;
      let msg = Kf.t('DeterminesThatAllContentsAreEmptied') + Kf.t(`Question?`) + Kf.t('AfterConfirmation') + ',' + Kf.t('TheContentsWillBePermanentlyRemovedFromTheWastebin') + '!';
      ElMessageBox.confirm(msg, Kf.t('Clear2'), {
        confirmButtonText: Kf.t('Confirm'),
        cancelButtonText: Kf.t('Cancel'),
        cancelButtonClass: 'cancel-button',
        type: 'warning',
        draggable: true,
        customClass: 'kf-content-msg-confirm1'
      }).then(() => {
        if (menu === 'space') {
          recycleBinModel.recycleClear('space', res => {
            recycleBinModel.getBaseDateCountBySpace();
          });
        } else if (menu === 'project') {
          recycleBinModel.recycleClear('project', res => {
            recycleBinModel.getBaseDateCountByProject();
          });
        }
      }).catch(() => {});
    };
    // input数据查询搜索
    const doSearch = value => {
      // console.log('数据查询', value);
      state.search = value;
      if (value) {
        recycleBinModel.recycleBinList.length = 0;
        recycleBinModel.recycleBinList.push(...recycleBinModel.searchList.filter(it => {
          return tools.pinyinMatch(it.name, value);
        }));
      } else {
        recycleBinModel.recycleBinList.length = 0;
        recycleBinModel.recycleBinList.push(...recycleBinModel.searchList);
      }
    };
    // 排序功能 => 传入popfilter组件
    const orderHandleByRecycle = key => {
      if (key) {
        currOrder.value = key;
      }
      // console.log(currMenuTab.value.code);
      if (currMenuTab.value.code === 'project') {
        recycleBinModel.sortByList(recycleBinModel.recycleBinList, 'delDate', orderType.value);
      } else if (currMenuTab.value.code === 'space') {
        recycleBinModel.sortByList(recycleBinModel.recycleBinList, 'updateDate', orderType.value);
      }
    };
    const orderHandle = function (val) {
      orderType.value = val;
      orderHandleByRecycle();
    };
    return (_ctx, _cache) => {
      return _openBlock(), _createElementBlock("section", _hoisted_1, [_createElementVNode("div", _hoisted_2, [_createElementVNode("section", _hoisted_3, [(_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_unref(menuTab), item => {
        var _unref2;
        return _openBlock(), _createElementBlock("div", {
          class: _normalizeClass(["kf-newContent-right-header-tab-item", [((_unref2 = _unref(currMenuTab)) === null || _unref2 === void 0 ? void 0 : _unref2.code) === item.code ? 'kf-newContent-right-header-tab-item-active' : '']]),
          key: item.code,
          onClick: $event => handleMenuTab(item)
        }, _toDisplayString(item.name), 11, _hoisted_4);
      }), 128))])]), _createElementVNode("div", _hoisted_5, [_createElementVNode("div", {
        class: "kf-newContent-right-header-clear",
        onClick: clearCycleState
      }, _toDisplayString(_ctx.$t('EmptyWastebin')), 1)])]);
    };
  }
};