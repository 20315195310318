import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, createElementBlock as _createElementBlock } from "vue";
const _hoisted_1 = {
  class: "kf-newContent-right"
};
const _hoisted_2 = {
  class: "kf-newContent-content"
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_content_header = _resolveComponent("content-header");
  const _component_content_left = _resolveComponent("content-left");
  const _component_content_right = _resolveComponent("content-right");
  return _openBlock(), _createElementBlock("div", _hoisted_1, [_createVNode(_component_content_header, {
    onSearch: $setup.doSearch
  }, null, 8, ["onSearch"]), _createElementVNode("div", _hoisted_2, [!($setup.currMenu && $setup.currMenu.code === 'library') ? (_openBlock(), _createBlock(_component_content_left, {
    key: 0
  })) : _createCommentVNode("", true), _createVNode(_component_content_right, {
    search: $setup.search
  }, null, 8, ["search"])])]);
}