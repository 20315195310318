<template>
    <div class="kf-newContent-contents kingfisher_scroll1" v-loading="loading" :element-loading-text="$t('Loading…')"
         element-loading-background="rgba(0, 0, 0, 0.5)">
        <recycle-content v-if="currMenu.code === 'frecycleBin'"/>
        <authority-list v-if="currMenu.code !== 'frecycleBin' && currHeaderTab.code=== 'permissions'"
                        :search="search"></authority-list>
        <library-widget-list v-if="currMenu.code === 'library'" :search="search"></library-widget-list>
        <div class="kf-newContent-item"
             v-if="currMenu.code !== 'frecycleBin'&& currMenu.code !== 'library' && !isTronThemeDetails && currHeaderTab.code!== 'permissions' && getSceneList().length>0"
             v-for="(item, index) in getSceneList()" :key="item.uuid" @click.stop="handleClick(item)" draggable="true"
             @dragstart="handleDragStart(item, $event)">
            <div class="kf-newContent-item-img pr">
                <div class="kf-newContent-item-type2" v-if="currMenu.code!=='tutorial'  ">
                    <div v-if="currHeaderTab.code==='scene'  && (item.sceneMode==null ||  item.sceneMode==='scene3D')"
                         class="kf-newContent-item-type1">3D
                    </div>
                    <div v-else-if="currHeaderTab.code==='scene'" class="kf-newContent-item-type1">2D</div>
                </div>
<!--                <div class="kf-newContent-item-sceneMode"
                     v-if="(currHeaderTab.code==='space' || currHeaderTab.code === 'scene')&& currMenu.code === 'file'  && item.checksum!==undefined && item.userChecksum!==undefined ">
                    <div class="kf-newContent-item-type1">
                        {{ item.checksum === null ? 0 : item.checksum }}/{{ item.userChecksum === null || item.userChecksum === -1 ? 0 : item.userChecksum }}
                    </div>
                </div>-->
                <img :src="getImageUrl1(item)" :key="item.uuid+index" class="kf-table-td-img ml5" :alt="index"
                     @error="imageError($event)"
                     v-if="getShowImg(item)">
                <div class="kf-newContent-item-img-none1" v-else>
                    <i class="iconfont icon-kongchangjing"></i>
                </div>
                <div class="kf-newContent-item-modal" v-if="currMenu.code!=='tutorial'">
                    <el-button type="primary" v-for="(menu, index1) in sceneOperations(item)" :key="index1"
                               v-show="getShow(menu,item)"
                               @click.stop="editOperation(item, menu)">{{ menu.name }}
                    </el-button>
                </div>
            </div>

            <div class="kf-newContent-item-title mt8" v-if="item.name!=null && currMenu.code!=='tutorial'">
                <div class="kf-newContent-item-title2" :contenteditable="editGroup"
                     @keyup.enter="changeGroup(item,$event)" @blur="changeGroup(item,$event)"
                     @dblclick.stop="editGroups(item,$event)">{{ getItemName(item) }}
                </div>
                <i class="iconfont icon-gengduo" :class="[showEdit && currEditWidget.uuid===item.uuid?'i-active':'']"
                   @click.stop="handleEditPart(item,$event)"
                   ref="editIconDom"
                   v-show="currMenu.code!=='library' || (currMenu.code==='library' && currUser.manager)"></i>
            </div>
            <div class="kf-newContent-item-title mt8" v-else>
                <div class="kf-newContent-item-title2">{{ item.tittle }}</div>
            </div>
            <div class="kf-newContent-item-title1">{{ getEditTime(item) }}
            </div>
        </div>


        <el-empty :image-size="200"
                  v-if="!loading && !getSceneList().length && currMenu.code !== 'frecycleBin'&& currHeaderTab.code !== 'permissions' && !isTronThemeDetails"
                  class="kf-empty"
                  style="justify-content: center;align-items: center;width: 100%;height: 40%"/>
        <el-dialog custom-class="kf-3dc-dialog3" :title="moveGroupData.moveGroupTitle"
                   v-model="moveGroupData.moveGroupVisible"
                   width="600px"
                   :close-on-click-modal="true" :show-close="true" draggable>
            <div class="kf-3dc-dialog-body">
                <div class="kf-3dc-dialog-contents" v-if="!moveGroupData.moveGroupToProject"
                     style="flex-direction: column;justify-content: flex-start;">
                    <div class="kf-3dc-dialog-contents">
                        <div class="kf-3dc-dialog-select-label">{{ $t('GroupName') }}</div>
                        <div class="kf-3dc-dialog-select-con kingfisher_select-new1" style="height: 32px"
                             v-if="moveGroupData.moveToProject">
                            <el-select v-model="moveGroupData.moveGroupName" :placeholder="$t('PleaseChoose')">
                                <el-option v-for="(item1, index1) in moveGroupData.moveGroupList"
                                           :key="item1.uuid+item1.name+index1" :label="item1.name"
                                           :value="item1.uuid"></el-option>
                            </el-select>
                        </div>
                        <div class="kf-3dc-dialog-select-con kingfisher_select-new1" style="height: 32px" v-else>
                            <el-select v-model="moveGroupData.moveGroupName" :placeholder="$t('PleaseChoose')">
                                <el-option v-for="(item, index) in moveGroupData.moveGroupList"
                                           :key="item.category+index" :label="item.category"
                                           :value="item.category"></el-option>
                            </el-select>
                        </div>
                    </div>
                </div>

                <div class="kf-3dc-dialog-contents" v-else style="flex-direction: column;justify-content: flex-start;">
                    <div class="kf-3dc-dialog-contents mt20">
                        <div class="kf-3dc-dialog-select-label">{{ $t('ProjectName') }}</div>
                        <div class="kf-3dc-dialog-select-con kingfisher_select-new1" style="height: 32px">
                            <el-select v-model="moveGroupData.projectUuid" filterable :placeholder="$t('PleaseChoose')">
                                <el-option v-for="(item, index) in projectList" :key="item.uuid+item.name"
                                           :label="item.name" :value="item.uuid"></el-option>
                            </el-select>
                        </div>
                    </div>
                </div>

                <div class="kf-3dc-dialog-footer mt20 mb10">
                    <div class="kf-dialog-left-btn-cancel mr15" @click="moveGroupData.moveGroupVisible = false">
                        {{ $t('Cancel') }}
                    </div>
                    <div class="kf-dialog-left-btn-sure " @click="doMoveGroup">{{ $t('Confirm') }}
                    </div>
                </div>

            </div>
        </el-dialog>

        <el-dialog custom-class="kf-3dc-dialog3" :title="$t('MoveToMyFile')" v-model="moveToFile.moveToFileVisible"
                   width="600px"  :close-on-click-modal="true"
                   :show-close="true" draggable>
            <div class="kf-3dc-dialog-body">
                <div class="kf-3dc-dialog-contents" style="flex-direction: column;justify-content: flex-start;">
                    <div class="kf-3dc-dialog-contents">
                        <div class="kf-3dc-dialog-select-label">{{ $t('GroupName') }}</div>
                        <div class="kf-3dc-dialog-select-con kingfisher_select-new1" style="height: 32px">
                            <el-select v-model="moveToFile.moveCategory" :placeholder="$t('PleaseChoose')">
                                <el-option v-for="(item1, index1) in moveToFile.list" :key="item1.category+index1"
                                           :label="item1.category"
                                           :value="item1.category"></el-option>
                            </el-select>
                        </div>
                    </div>
                </div>

                <div class="kf-3dc-dialog-footer mt20 mb10">
                    <div class="kf-dialog-left-btn-cancel mr15" @click="moveToFile.moveToFileVisible = false">
                        {{ $t('Cancel') }}
                    </div>
                    <div class="kf-dialog-left-btn-sure " @click="doMoveToFile">{{ $t('Confirm') }}
                    </div>
                </div>

            </div>
        </el-dialog>

        <el-dialog custom-class="kf-3dc-dialog3" title="" v-model="publishData.publishVisible" width="700px"
                   :close-on-click-modal="true"
                   :show-close="true" draggable>
            <div class="kf-3dc-dialog-header-tab" v-if="currUser.manager">
                <div class="kf-3dc-import-model1-items" style="width: 220px">
                    <div class="kf-3dc-import-model1-items-tab"
                         :class="[publishData.default?'kf-3dc-import-model1-items-tab-active':'']" style="width:50%"
                         @click="doPublishTab(true)">{{ $t('Default') }}
                    </div>
                    <div class="kf-3dc-import-model1-items-tab" @click="doPublishTab(false)"
                         :class="[publishData.default?'':'kf-3dc-import-model1-items-tab-active']" style="width:50%">
                        {{ $t('History') }}
                    </div>
                </div>
            </div>
            <div class="kf-3dc-dialog-body" v-if="publishData.default"
                 style="height: 330px;display: flex;flex-direction: column;justify-content: space-between">
                <div style="width: 100%;">
                    <div class="kf-3dc-import-model1-item">{{ $t('PageFillMode') }}
                        <div class="kf-3dc-import-model1-items" style="width: calc(100% - 120px)">
                            <div class="kf-3dc-import-model1-items-tab"
                                 :class="[publishData.publishMode === item.value ? 'kf-3dc-import-model1-items-tab-active' : '']"
                                 v-for="item in publishModeList"
                                 :key="item.value" @click="publishData.publishMode = item.value">
                                {{
                                item.name
                                }}
                            </div>
                        </div>
                    </div>
                </div>

                <div class="kf-3dc-dialog-footer  mb10">
                    <div class="kf-dialog-left-btn-cancel mr15" @click="publishData.publishVisible = false">
                        {{ $t('Close') }}
                    </div>
                    <div class="kf-dialog-left-btn-sure " @click="doPublish">{{ $t('GenerateLinkOpen') }}
                    </div>

                </div>

            </div>
            <div class="kf-3dc-dialog-body" v-else style="height: 330px">
                <div class="kf-pub-his">
                    <div class="kf-pub-his-header">
                        <span style="width: 200px;">{{ currHeaderTab.name }}</span>
                        <!--                        <span style="width: 90px;">用户</span>-->
                        <span style="width: 90px;">{{ $t('FillMode') }}</span>
                        <span style="width: 190px;">{{ $t('Operation') }}</span>
                    </div>
                    <div class="kf-pub-his-table">
                        <div class="kf-pub-his-row mt2" v-for="his in publishHisList" v-bind:key="his.uuid"
                             :title="his.description">
                            <div class="kf-pub-his-row1" style="width: 200px;float: left">{{ his.description }}</div>
                            <!--                            <div class="kf-pub-his-row1" style="width: 90px; overflow:hidden; text-overflow:ellipsis; white-space: nowrap;"
                                                              :title="his.user">{{ his.user }}</div>-->
                            <div class="kf-pub-his-row1" style="width: 90px;">{{ his.overflowPolicyName }}</div>
                            <div class="kf-pub-his-row3" style="width: 190px;">
                                <div class="kf-pub-his-row2" @click="copyHisLine(his, false)">{{ $t('CopyLink') }}</div>
                                <div class="kf-pub-his-row2 ml10" @click="openHisLine(his, true)">{{ $t('OpenLink') }}
                                </div>
                                <div class="kf-pub-his-row2 ml10 " @click="deleteLink(true)">{{ $t('Clear') }}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </el-dialog>

        <el-dialog custom-class="kf-3dc-dialog3" :title="$t('AllPublishefdHistoricalData')" v-model="hisDialogVisible"
                   width="700px" :close-on-click-modal="true"
                   :show-close="true" draggable>
            <div class="kf-3dc-dialog-body">
                <div class="kf-pub-his">
                    <div class="kf-pub-his-header">
                        <span style="width: 80px;">{{ $t('Type') }}</span>
                        <span style="width: 250px;">{{ $t('GroupName') }}</span>
                        <!--                        <span style="width: 150px;">用户</span>-->
                        <span style="width: 100px;">{{ $t('FillMode') }}</span>
                    </div>
                    <div class="kf-pub-his-table">
                        <div class="kf-pub-his-row mt2" v-for="his in publishHisLists" v-bind:key="his.uuid"
                             :title="his.description">
                            <div class="kf-pub-his-row1" style="width: 80px;float: left">{{ his.type }}</div>
                            <div class="kf-pub-his-row1" style="width: 250px;float: left">{{ his.description }}</div>
                            <!--                            <div class="kf-pub-his-row1" style="width: 150px; overflow:hidden; text-overflow:ellipsis; white-space: nowrap;"
                                                             :title="his.lastModifiedBy">{{ his.lastModifiedBy }}</div>-->
                            <div class="kf-pub-his-row1" style="width: 100px;">{{ his.overflowPolicyName }}</div>
                        </div>
                    </div>
                </div>
            </div>
        </el-dialog>

        <el-dialog custom-class="kf-3dc-dialog3" :title="$t('Release')" v-model="editSceneVisible" width="800px"
                   :close-on-click-modal="true" :show-close="true"
                   draggable>
            <div class="kf-3dc-dialog-img">
                <img :src="getImageUrl1(currEditScene)" v-if="getShowImg(currEditScene)">
                <div class="kf-newContent-item-img-none1" v-else>
                    <i class="iconfont icon-kongchangjing"></i>
                </div>
            </div>
            <div class="kf-3dc-dialog-body mt16">
                <div class="kf-3dc-dialog-contents">
                    <div class="kf-3dc-dialog-select-label">{{ $t('Name') }}</div>
                    <div class="kf-3dc-dialog-select-con kingfisher_input-new1" style="height: 35px">
                        <el-input v-model="currEditScene.name"></el-input>
                    </div>
                </div>

                <div class="kf-3dc-dialog-contents mt16">
                    <div class="kf-3dc-dialog-select-label">{{ $t('Description') }}</div>
                    <div class="kf-3dc-dialog-select-con kingfisher_input-new1" style="height: 35px">
                        <el-input v-model="currEditScene.description"></el-input>
                    </div>
                </div>

                <div class="kf-3dc-dialog-contents mt16">
                    <div class="kf-3dc-dialog-select-label">{{ $t('CoverChart') }}</div>
                    <div class="kf-3dc-dialog-select-con1">
                        <div class="kf-3dc-path-name">{{ currEditSceneImg }}</div>
                        <el-upload ref="uploadImgBox" multiple accept=".jpg,jpeg,.png,.jfif,.webp" class="upload-demo"
                                   :on-success="doUploadSuccess"
                                   :on-error="doUploadError" :http-request="uploadResource1" action="#">
                            <div class="kf-3dc-dialog-select-con2 " style="height: 35px">
                                <i class="iconfont icon-wenjian2 mr8"></i>{{ $t('SelectFile') }}
                            </div>
                        </el-upload>
                    </div>
                </div>

                <div class="kf-3dc-dialog-contents mt16">
                    <div class="kf-3dc-dialog-select-label">{{ $t('LibraryClassificationSelection') }}</div>
                    <div class="kf-3dc-dialog-select-con kingfisher_select-new1" style="height: 32px">
                        <el-select v-model="currEditScene.category" :placeholder="$t('PleaseChoose')"
                                   style="width: calc(100% - 100px)">
                            <el-option v-for="(item,index) in libraryCategoryList" :key="item+index" :label="item"
                                       :value="item"></el-option>
                        </el-select>
                        <el-button type="primary" class="ml10" @click="createCategory()">
                            {{ $t('CreateClassification') }}
                        </el-button>
                    </div>
                </div>

            </div>
            <div class="kf-3dc-dialog-footer mt20 mb10">
                <div class="kf-dialog-left-btn-cancel mr15" @click="editSceneVisible = false">{{ $t('Cancel') }}
                </div>
                <div class="kf-dialog-left-btn-sure " @click="doEditScene">{{ $t('Confirm') }}
                </div>
            </div>
        </el-dialog>

        <div class="kf-right-menu" :style="editStyle" v-show="showEdit" ref="editDom">
            <div class="kf-right-menu-con hvr-sweep-to-right" v-for="(menu,index) in sceneOperations1"
                 v-show="getShowBtn(menu)"
                 @click="handleEdit(menu)"
                 :key="menu + index">{{ menu.name }}
            </div>
        </div>

        <el-dialog custom-class="kf-3dc-dialog3" :title="$t('Rename')" v-model="renameVisible" width="700px"
                   :close-on-click-modal="true" :show-close="true"
                   draggable>
            <div class="kf-3dc-dialog-body mt16">
                <div class="kf-3dc-dialog-contents mt16">
                    <div class="kf-3dc-dialog-select-label">{{ $t('Name') }}</div>
                    <div class="kf-3dc-dialog-select-con kingfisher_input-new1" style="height: 35px">
                        <el-input v-model="partName"  maxlength="45"></el-input>
                    </div>
                </div>
            </div>
            <div class="kf-3dc-dialog-footer mt20 mb10">
                <div class="kf-dialog-left-btn-cancel mr15" @click="renameVisible=false">{{ $t('Cancel') }}
                </div>
                <div class="kf-dialog-left-btn-sure " @click="doRename">{{ $t('Confirm') }}
                </div>
            </div>
        </el-dialog>

        <el-dialog custom-class="kf-3dc-dialog3" :title="$t('Settings')" v-model="editWidgetVisible" width="800px"
                   :close-on-click-modal="true"
                   :show-close="true" draggable>
            <div class="kf-3dc-dialog-img">
                <img :src="getImageUrl1(editWidgetOrScene)" v-if="getShowImg(editWidgetOrScene)">
                <div class="kf-newContent-item-img-none1" v-else>
                    <i class="iconfont icon-kongchangjing"></i>
                </div>
            </div>
            <div class="kf-3dc-dialog-body mt16">
                <div class="kf-3dc-dialog-contents">
                    <div class="kf-3dc-dialog-select-label">{{ $t('Name') }}</div>
                    <div class="kf-3dc-dialog-select-con kingfisher_input-new1" style="height: 35px">
                        <el-input v-model="currEditWidget.name" maxlength="45"></el-input>
                    </div>
                </div>

                <div class="kf-3dc-dialog-contents mt16">
                    <div class="kf-3dc-dialog-select-label">{{ $t('Description') }}</div>
                    <div class="kf-3dc-dialog-select-con kingfisher_input-new1" style="height: 35px">
                        <el-input v-model="currEditWidget.description"></el-input>
                    </div>
                </div>

                <div class="kf-3dc-dialog-contents mt16" v-if="currHeaderTab.code!=='space'">
                    <div class="kf-3dc-dialog-select-label">{{ $t('CoverChart') }}</div>
                    <div class="kf-3dc-dialog-select-con1">
                        <div class="kf-3dc-path-name">{{ currEditWidget.screenshotPath }}</div>
                        <el-upload ref="uploadImgBox1" multiple accept=".jpg,jpeg,.png,.jfif,.webp" class="upload-demo"
                                   :on-success="doUploadSuccess1"
                                   :http-request="uploadResource" action="#">
                            <div class="kf-3dc-dialog-select-con2 " style="height: 35px">
                                <i class="iconfont icon-wenjian2 mr8"></i>{{ $t('SelectFile') }}
                            </div>
                        </el-upload>
                    </div>
                </div>

            </div>
            <div class="kf-3dc-dialog-footer mt20 mb10">
                <div class="kf-dialog-left-btn-cancel mr15" @click="editWidgetVisible = false">{{ $t('Cancel') }}
                </div>
                <div class="kf-dialog-left-btn-sure " @click="doEditWidget">{{ $t('Confirm') }}
                </div>
            </div>
        </el-dialog>

        <el-dialog custom-class="kf-3dc-dialog3" :title="$t('CreateClassification') " v-model="createCategoryVisible"
                   width="700px" :close-on-click-modal="true"
                   :show-close="true" draggable>
            <div class="kf-3dc-dialog-body mt16">
                <div class="kf-3dc-dialog-contents mt16">
                    <div class="kf-3dc-dialog-select-label">{{ $t('CategoryName') }}</div>
                    <div class="kf-3dc-dialog-select-con kingfisher_input-new1" style="height: 35px">
                        <el-input v-model="categoryName"></el-input>
                    </div>
                </div>
                <div class="kf-3dc-dialog-contents mt16" v-if="currUser.manager">
                    <div class="kf-3dc-dialog-select-label">{{ $t('Official') }}</div>
                    <div class="kf-3dc-dialog-select-con kingfisher_input-new1" style="height: 35px">
                        <el-checkbox v-model="categoryNameOfficial" size="large"/>
                    </div>
                </div>
            </div>
            <div class="kf-3dc-dialog-footer mt20 mb10">
                <div class="kf-dialog-left-btn-cancel mr15" @click="createCategoryVisible=false">{{ $t('Cancel') }}
                </div>
                <div class="kf-dialog-left-btn-sure " @click="doCreateCategory">{{ $t('Confirm') }}
                </div>
            </div>
        </el-dialog>

                <video-dialog :show.sync="videoDialog" v-show="videoDialog" :data="currVideoData"
                              @propChange="changeVideoDialog"></video-dialog>

        <preview-dialog :show.sync="previewDialog" v-show="previewDialog" :data="currEditScene"
                        :type="currHeaderTab.code"
                        @propChange="changePreviewDialog"></preview-dialog>
        <artistPreview :data="currEditScene" v-show="widgetPreviewDialog" :isPreview="widgetPreviewDialog"
                       @close="closeDialog"
                       type="component"/>

        <!--        &lt;!&ndash; 库主题资产管理详情页 &ndash;&gt;-->
        <!--        <libraryAssets @setTheme="setTheme" ></libraryAssets>-->
        <!-- tron theme 主题设置 -->
        <el-dialog custom-class="kf-3dc-dialog3" :title="$t('Settings')" v-model="isSetTronTheme" width="800px"
                   :close-on-click-modal="true" :show-close="true"
                   draggable>
            <div class="kf-3dc-dialog-img">
                <img :src="getThemeImage(editTheme)" v-if="!!editTheme.screenshotPath">
                <div class="kf-newContent-item-img-none1" v-else>
                    <i class="iconfont icon-kongchangjing"></i>
                </div>
            </div>
            <div class="kf-3dc-dialog-body mt16">
                <div class="kf-3dc-dialog-contents">
                    <div class="kf-3dc-dialog-select-label">{{ $t('Name') }}</div>
                    <div class="kf-3dc-dialog-select-con kingfisher_input-new1" style="height: 35px">
                        <el-input v-model="editTheme.name"></el-input>
                    </div>
                </div>
                <div class="kf-3dc-dialog-contents mt16" v-if="!isTronThemeDetails">
                    <div class="kf-3dc-dialog-select-label">{{ $t('Classification') }}</div>
                    <div class="kf-3dc-dialog-select-con kingfisher_select-new1" style="height: 32px">
                        <el-select v-model="editTheme.groups" :placeholder="$t('PleaseChoose')"
                                   style="width: calc(100% - 100px)">
                            <el-option v-for="(item,index) in myProjectList1" :key="item.category+index"
                                       :label="item.category" :value="item.category"></el-option>
                        </el-select>
                        <el-button type="primary" class="ml10" @click="createThemeCategory()">
                            {{ $t('CreateClassification') }}
                        </el-button>
                    </div>
                </div>

                <div class="kf-3dc-dialog-contents mt16">
                    <div class="kf-3dc-dialog-select-label">{{ $t('CoverChart') }}</div>
                    <div class="kf-3dc-dialog-select-con1">
                        <div class="kf-3dc-path-name">{{ editTheme.screenshotPath }}</div>
                        <el-upload ref="uploadThemeImgBox" multiple accept=".jpg,jpeg,.png,.jfif,.webp"
                                   class="upload-demo" :on-success="doUploadThemeSuccess"
                                   :http-request="changeTronThemeImg" action="#">
                            <div class="kf-3dc-dialog-select-con2 " style="height: 35px">
                                <i class="iconfont icon-wenjian2 mr8"></i>{{ $t('SelectFile') }}
                            </div>
                        </el-upload>
                    </div>
                </div>


            </div>
            <div class="kf-3dc-dialog-footer mt20 mb10">
                <div class="kf-dialog-left-btn-cancel mr15" @click="isSetTronTheme = false">{{ $t('Cancel') }}
                </div>
                <div class="kf-dialog-left-btn-sure " @click="setTronThemeBydialog">{{ $t('Confirm') }}
                </div>
            </div>
        </el-dialog>


        <el-dialog custom-class="kf-3dc-dialog3" :title="$t('Copy2')" v-model="copyThemeVisible" width="800px"
                   :close-on-click-modal="true" :show-close="true"
                   draggable>

            <div class="kf-3dc-dialog-body mt16">

                <div class="kf-3dc-dialog-contents mt16" v-if="currUser.manager">
                    <div class="kf-3dc-dialog-select-label">{{ $t('Group') }}</div>
                    <div class="kf-3dc-dialog-select-con kingfisher_select-new1" style="height: 32px">
                        <el-select v-model="copyThemeData.systemFlag"
                                   :placeholder="$t('PleaseChoose')">
                            <el-option v-for="(item,index) in copyThemeData.systemFlagList"
                                       :key="item.name+index+'systemFlag'"
                                       :label="item.name" :value="item.value"></el-option>
                        </el-select>

                    </div>
                </div>

                <div class="kf-3dc-dialog-contents mt16" v-if="currUser.manager">
                    <div class="kf-3dc-dialog-select-label">{{ $t('Classification') }}</div>
                    <div class="kf-3dc-dialog-select-con kingfisher_select-new1" style="height: 32px">
                        <el-select v-model="copyThemeData.groups" :placeholder="$t('PleaseChoose')">
                            <el-option v-for="(item,index) in themeGroup[copyThemeData.systemFlag]"
                                       :key="item+index+'group1'"
                                       :label="item" :value="item"></el-option>
                        </el-select>
                    </div>
                </div>

                <div class="kf-3dc-dialog-contents mt16" v-if="!currUser.manager">
                    <div class="kf-3dc-dialog-select-label">{{ $t('Classification') }}</div>
                    <div class="kf-3dc-dialog-select-con kingfisher_select-new1" style="height: 32px">
                        <el-select v-model="editTheme.groups" :placeholder="$t('PleaseChoose')"
                                   style="width: calc(100% - 100px)">
                            <el-option v-for="(item,index) in themeGroup[copyThemeData.systemFlag]"
                                       :key="item+index+'group2'" :label="item" :value="item"></el-option>
                        </el-select>
                    </div>
                </div>

            </div>
            <div class="kf-3dc-dialog-footer mt20 mb10">
                <div class="kf-dialog-left-btn-cancel mr15" @click="copyThemeVisible = false">{{ $t('Cancel') }}
                </div>
                <div class="kf-dialog-left-btn-sure " @click="doCopyTheme()">{{ $t('Confirm') }}
                </div>
            </div>
        </el-dialog>
    </div>
</template>

<script>
import {computed, onMounted, ref, watch} from 'vue'
import {useRouter} from 'vue-router'
import contentModel, {
    tronThemeList, deleteTronThemeAssets,
    setTronThemeAssets, moveTronThemeAssets, isTronThemeDetails, currTronTheme, tronThemeCategoryList,
    queryPartListByTheme
} from '@/model/newContent'
import projectModel, {getNameByCopy} from "@/model/project";
import projectService from "@/service/project";
import {ElMessage, ElMessageBox} from 'element-plus';
import tools, {each} from "@/utils/tools";
import recycleContent from './recycleContent.vue'
import authorityList from './authorityList.vue'
import videoDialog from '../videoDialog.vue'
import previewDialog from '../previewDialog.vue'
import artistPreview from '../artistPreview.vue'
import contactUs from "@/components/header/contactUs.vue";
import pageModel from "@/model/page";
import menuModel from "@/model/menu";
import recycleBinModel from "@/model/recycleBin";
import Const from '@/utils/const';
import spaceService from '@/service/space'
import libraryAssets from './libraryAssets.vue';
import libraryWidgetList from "./libraryWidgetList.vue";
import {emptySceneImg} from "@/model/img.base64";


export default {
    name: "contentRight",
    computed: {
        recycleBinModel() {
            return recycleBinModel
        }
    },
    components: {
        contactUs, recycleContent, previewDialog,videoDialog
        , libraryAssets, authorityList, artistPreview,libraryWidgetList
    },
    props: {
        search: {
            type: String,
            default: () => {
                return ''
            }
        }
    },
    setup(props, {emit}) {
        let newCategory = ref('');
        let updateLoading = ref(false)
        let artSceneData = ref(null)
        const router = useRouter();
        let editGroupName = ref('');
        let editGroup = ref(false);
        let createCategoryVisible = ref(false);
        let categoryName = ref('');
        let categoryNameOfficial = ref(false);
        let host = ref('');
        let videoDialog = ref(false);
        let previewDialog = ref(false)
        let currVideoData = ref({})
        let editWidgetVisible = ref(false);
        let widgetPreviewDialog = ref(false)

        let artistPreview = ref(false);
        let uploadThemeImgBox = ref(false)

        let isSetTronTheme = ref(false);  // 肢体资产设置弹窗
        let TronThemeName = ref("");  // 主题资产名称
        let tronThemeImg = ref(""); // 主题资产缩略图

        let copyThemeVisible = ref(false);
        let copyThemeData = ref({
            systemFlagList: [],
            systemFlag: 1,
            groups: '',
            groupsList: []
        })

        let editTheme = ref({})

        onMounted(() => {
            let url = window.location.href;
            host.value = url.substring(0, url.indexOf("/#/"));
            if ('development' == process.env.NODE_ENV) {
                host.value = 'http://dev.kingfisher.live:8000'
            }
        })

        const doCopyTheme = function () {
            contentModel.copyTronTheme(editTheme.value, copyThemeData.value.groups, copyThemeData.value.systemFlag, (res) => {
                if (res === 'success') {
                    if ((contentModel.currLibTab.value.code === 'userContent' && copyThemeData.value.systemFlag === 2) || (contentModel.currLibTab.value.code !== 'userContent' && copyThemeData.value.systemFlag === 1)) {
                        projectModel.switchProject(contentModel.myProject.value, contentModel.currLeftContent.value);
                    }
                }
                copyThemeVisible.value = false
            })
        }
        let locale = window.Kf.locale.value.replace("-", "");
        const getSceneList = function () {
            let list = [];
            let filterName = 'name'
            if (contentModel.currMenu.value.code === 'tutorial') {
                list = projectModel.videoList.value;
                filterName = 'tittle'
            } else if (contentModel.currMenu.value.code === 'library') {
                list = contentModel.librarySpaceList.value;
                filterName = 'name'
            } else {
                if (contentModel.currHeaderTab.value?.code === 'space') {
                    list = projectModel.spaceList.value
                }
            }
            let type = contentModel.currOrder.value.currOrderParam;
            list.sort((a, b) => {
                if (contentModel.currOrder.value.currOrderType) {
                    return (new Date(a[type]).getTime() - new Date(b[type]).getTime());
                } else {
                    return (new Date(b[type]).getTime() - new Date(a[type]).getTime());
                }
            });
            return list.filter(comp => {
                let filterValue = comp[filterName]
                if (contentModel.currMenu.value.code === 'library') {
                  let _label=comp.name;
                  if (comp.labelInterpret && typeof comp.labelInterpret === 'string') {
                    _label = JSON.parse(comp.labelInterpret)[locale]
                  }else if (comp.labelInterpret && typeof comp.labelInterpret === 'object') {
                    _label =comp.labelInterpret[locale]
                  }
                  filterValue = _label;
                }
                return !props.search || tools.pinyinMatch(filterValue, props.search);
            })

        }

        const sceneOperations = computed(() => (item) => {
            if (contentModel.currHeaderTab.value?.code === 'scene') {
                if (contentModel.currMenu.value.code === 'library' && !contentModel.isProject.value) {
                    return [{name: Kf.t('Create'), type: 'create'}, {name: Kf.t('Preview'), type: 'preview'}]
                } else {
                    let ope = [];
                    if (item?.sceneMode === 'scene3D') {
                        ope = [{name: Kf.t('Edit'), type: 'edit'}, {
                            name: Kf.t('Preview'),
                            type: 'preview'
                        }, {name: Kf.t('Export'), type: 'exportScene'}];
                    } else {
                        ope = [{name: Kf.t('Preview'), type: 'preview'}];
                    }
                    return ope;
                }
            }
            if (contentModel.currHeaderTab.value?.code === 'widget') {
                if (contentModel.currMenu.value.code === 'library') {
                    return [{name: Kf.t('Preview'), type: 'preview'}];
                } else {
                    return [{name: Kf.t('Edit'), type: 'edit'}, {
                        name: Kf.t('Preview'),
                        type: 'preview'
                    }, {name: Kf.t('Release'), type: 'publishWidget'}]
                }

            }
            if (contentModel.currHeaderTab.value?.code === 'space') {
                if (contentModel.currMenu.value.code === 'library') {
                    return [{name: Kf.t('Preview'), type: 'preview'}];
                } else {
                    return [{name: Kf.t('Edit'), type: 'edit'}, {name: Kf.t('Preview'), type: 'preview'}]
                }
            }
        })
        const getShow = function (menu, item) {
            let res = true;
            if (contentModel.currMenu.value.code == 'library' && !contentModel.isProject.value) {
                if (item.pubFlag == 1 && menu?.type == 'add') {
                    res = false;
                }
                if (item.pubFlag == 0 && menu?.type == 'cancel') {
                    res = false;
                }
                if (contentModel.currLeftContent.value.flag && item.showFlag == 0 && menu?.type == 'create') {
                    res = false;
                }
                if (contentModel.currLeftContent.value.flag && item.showFlag == 0 && (menu?.type == 'create' || menu?.type == 'add')) {
                    res = false;
                }
            }
            if (contentModel.currHeaderTab.value.code == 'page' && !item.isEntrance && menu?.type == 'publish') {
                res = false;
            }
            return res;
        }
        let libraryCategoryList = ref([])
        let sceneOperations1 = ref([])
        let currEditScene = ref({})
        let currEditWidget = ref({});
        let editWidgetOrScene = ref({})
        let moveGroupData = ref({
            moveGroupVisible: false,
            moveGroupTitle: Kf.t('MoveGroup'),
            moveGroupName: '',
            moveGroupList: [],
            moveToProject: false,
            moveGroupToProject: false,
        })
        let moveToFile = ref({
            moveToFileVisible: false,
            moveCategory: ''
        })
        let moveGroupDataParam = ref({})
        let createProjectVisible = ref(false)
        let createProjectData = ref({
            code: '',
            name: '',
            groups: "",
            description: ''
        })

        let editSceneVisible = ref(false);
        let publishData = ref({
            publishVisible: false,
            publishUrl: '',
            publishMode: 'auto',
            user: '',
            pwd: '',
            default: true,
            showUser: false,
            deleLink: false,
        })
        let publishModeList = ref([{name: Kf.t('Auto'), value: 'auto'}, {
            name: Kf.t('WidthFill'),
            value: 'fullWidth'
        }, {name: Kf.t('HeightFill'), value: 'fullHeight'}, {name: Kf.t('Scale2'), value: 'scale'}]);
        let publishUserList = ref([{name: Kf.t('Yes'), value: true}, {name: Kf.t('No'), value: false}])
        let showEdit = ref(false);
        let editStyle = ref({});
        let editItem = ref({})
        let editDom = ref(null);
        let editIconDom = ref(null);
        let renameVisible = ref(false);
        let partName = ref('');
        let currEditSceneImg = ref('')
        const editOperation = function (item, operation) {
            currEditScene.value = JSON.parse(JSON.stringify(item))
            currEditSceneImg.value = currEditScene.value.screenshotPath
            switch (operation.type) {
                case 'edit':
                    if (contentModel.currHeaderTab.value?.code === 'space') {
                        let token = localStorage.getItem("KC_TOKEN");
                        console.log('space')
                        let _url = '/main/' + projectModel.currProject.value.uuid + '/' + currEditScene.value.uuid + '/' + token;
                        router.replace({
                            path: _url,
                        });
                    } else {
                        if (item.source !== 'tron-edit') {
                            let name = item.label[locale] ? item.label[locale] : item.name;
                            ElMessage({
                                type: "warning",
                                message: Kf.t('Component') + `“${name}”` + Kf.t('EditNotSupported'),
                                grouping: true
                            })
                        } else {
                            let currInfo = {
                                isProject: contentModel.isProject.value,
                                currLeftContent: contentModel.currLeftContent.value
                            };
                            localStorage.setItem('currInfo', JSON.stringify(currInfo));
                            router.replace({
                                path: '/model-edit/' + projectModel.currProject.value.uuid + '/' + currEditScene.value.uuid,
                            });
                        }

                    }
                    break;
                case 'data':
                    pageModel.currScene.value = currEditScene.value;
                    menuModel.showData.value = true;
                    let currInfo = {
                        isProject: contentModel.isProject.value,
                        currLeftContent: contentModel.currLeftContent.value
                    };
                    localStorage.setItem('currInfo', JSON.stringify(currInfo));
                    router.replace({
                        path: '/main/' + projectModel.currProject.value.uuid + '/' + currEditScene.value.uuid + '/' + currEditScene.value.version + '/' + true
                    });
                    break;
                case 'preview':
                    // console.log(item); 释放所有组件的预览功能 20240514 需求
                    // if (contentModel.currHeaderTab.value?.code == 'widget' && item.source == null) {
                    //   let name = "";
                    //   if(!item.label){
                    //     name = item.name;
                    //   }else if(typeof item.label == 'object'){
                    //     name = item.label[locale] ? item.label[locale] : item.name;
                    //   }
                    //   ElMessage({ type: "warning", message: name + ' 组件不支持预览', grouping: true })
                    // } else {
                    //   previewDialog.value = true;
                    // }
                    if (contentModel.currMenu.value.code === 'library') {
                        widgetPreviewDialog.value = true
                    } else {
                        previewDialog.value = true;
                    }

                    break;
                case 'add':
                    updatePartPub(1, item.uuid, Kf.t('Add1'))
                    break;
                case 'cancel':
                    updatePartPub(0, item.uuid, Kf.t('Cancel'))
                    break;
                case 'create':
                    copyToMyProject(item)
                    break;
                case 'delete':
                    doDeleteScene(item)
                    break;
                case 'share':
                    editSceneVisible.value = true;
                    getCategoryList()
                    break;
                case 'publish':
                    if (contentModel.currHeaderTab.value.code === 'scene') {
                        shareLink(item, 'presenter')
                    }
                    if (contentModel.currHeaderTab.value.code === 'widget') {
                        if (item.source == null) {
                            let name = item.label[locale] ? item.label[locale] : item.name;
                            ElMessage({
                                type: "warning",
                                message: name + Kf.t('ComponentDoesNotSupportSharing'),
                                grouping: true
                            })
                        } else {
                            shareLink(item, 'widgetPresenter')
                        }

                    }
                    break;
                case 'publishWidget':
                    doPublishWidget(item)
                    break;
                case 'exportSpaceData':
                    //导出点表数据
                    // doExport(item)
                    doExportScene(item)
                    break;

                case 'exportScene':

                    doExport3dcScene(item)
                    break;
                case 'open3':
                    // 打开 主题资产 详情页
                    currTronTheme.value = item;
                    isTronThemeDetails.value = true;
                    const {projectId, uuid} = item;
                    queryPartListByTheme(projectId, uuid);
                    showEdit.value = false;
                    break;
                case 'copyTheme':
                    contentModel.copyTronTheme(item)
                    break;
            }
        }
        const doExport3dcScene = function (data) {
            if (data?.projectUuid && data?.uuid) {
                projectService.getSceneByUuid(data.uuid).then(res => {
                    let textures = []
                    let meshes = []
                    let config = []
                    if (res?.data) {
                        let _data = JSON.parse(res.data);
                        config = _data.config || []
                        if (_data?.assets?.geometries) {
                            meshes = _data.assets.geometries
                        }
                        if (_data?.geometries) {
                            meshes = _data.geometries
                        }
                        if (_data?.assets?.textures) {
                            textures = _data.assets.textures
                        }
                        if (_data?.textures) {
                            textures = _data.textures
                        }
                    }

                    let resource = {
                        meshes: meshes,
                        textures: textures,
                        projectId: data.projectUuid,
                        sceneId: data.uuid,
                        config: JSON.stringify(config)

                    };
                    projectService.getSceneZip(resource).then(response => {

                    });
                })

            }
        }

        const doExportScene = function (space) {
            if (space?.projectId && space?.buildingId) {
                let p = {
                    projectId: space.projectId,
                    spaceBuildingId: space.buildingId
                }
                spaceService.exportScene(space.projectId, space.uuid).then(res => {
                    if (res?.status === 404) {
                        ElMessage({
                            type: 'error',
                            message: res?.data?.message || Kf.t('DataNotAvailable') + Kf.t('Download')
                        });
                    }
                })
            }
        }
        const doExport = function (space) {
            if (space?.projectId && space?.buildingId) {
                let p = {
                    projectId: space.projectId,
                    spaceBuildingId: space.buildingId
                }
            }
        }
        let showDialog = ref(false)

        const doPublishWidget = function (item) {
            showDialog.value = true;
        }
        const change = function (val) {
            showDialog.value = val;
        }
        const shareLink = function (item, targetName) {
            let message2 = ''
            let origin = window.location.origin;
            let pathname = window.location.pathname;
            pathname = pathname.length > 1 ? pathname + "#" : "/#";
            let url = origin + pathname + `/${targetName}/${item.projectUuid}/${item.uuid}`;
            let t2 = localStorage.getItem("KC_TOKEN");
            //message2 =url+`/${t2}`
            message2 = url;
            publishData.value = {
                publishVisible: true,
                publishUrl: message2,
                publishMode: 'auto',
                default: true,
                showUser: false,
                user: '',
                pwd: '',
                deleLink: false,
            }
            getPublishHisList()
        }


        const copyToMyProject = function (item) {
            if (contentModel.myFileData.value?.uuid) {
                let p = JSON.parse(JSON.stringify(item))
                p.projectUuid = contentModel.myFileData.value.uuid;
                doCopyScene(p, Kf.t('Create'), 'library')
            }

        }


        const getShowBtn = function (item) {
            let res = true;
            if (!contentModel.isProject.value && item?.isProject) {
                res = false
            }
            if (contentModel.currMenu.value.code != 'library') {
                if (item?.showFlag != undefined) {
                    res = false
                }
            }
            if (contentModel.currMenu.value.code == 'library') {
                if (item?.enableTemplate == 'library') {
                    res = false
                }
                if (!contentModel.currUser.value.manager || (currEditWidget.value?.showFlag != undefined && item?.showFlag != undefined && currEditWidget.value.showFlag != item.showFlag)) {
                    res = false
                }
            }
            return res;
        }
        const handleEditPart = function (item, event) {
            editItem.value = item;
            currEditWidget.value = JSON.parse(JSON.stringify(item));
            editWidgetOrScene.value = item;
            let _height = document.documentElement.clientHeight;
            let _width = document.documentElement.clientWidth;
            if (event.y > _height - 210) {
                editStyle.value.top = (_height - 210) + 'px'
            } else {
                editStyle.value.top = event.y + 'px'
            }
            if (event.x > _width - 120) {
                editStyle.value.left = (_width - 120) + 'px'
            } else {
                editStyle.value.left = event.x + 'px'
            }
            showEdit.value = true;
            if (contentModel.currMenu.value.code === 'library') {
                sceneOperations1.value = [
                    {name: Kf.t('Delete'), type: 'delete'}]
                tronThemeImg.value = item.screenshotPath;
                TronThemeName.value = item.name;
            } else {
                // 空间
                if (contentModel.currHeaderTab.value?.code === 'space') {
                    sceneOperations1.value = [{name: Kf.t('SetSpace'), type: 'edit'},
                        // { name: '移动到项目', type: 'moveToProject' },
                        {name: Kf.t('OnBoard'), type: 'shangjia', showFlag: 0},
                        {name: Kf.t('Clear'), type: 'xiajia', showFlag: 1},
                        // {name: Kf.t('ExportPointTableData'), type: 'exportPointTableData'},
                        {name: Kf.t('Rename'), type: 'rename'},
                        {name: Kf.t('Delete'), type: 'delete'}];
                }

            }

        }

        let moveGroupDefaultName = ref('')
        const handleEdit = function (item2) {
            showEdit.value = false;
            switch (item2.type) {
                case 'delete':
                    doDeleteScene(editItem.value, () => {
                        projectModel.switchProject(projectModel.currProject.value)
                    })
                    break;
                case 'edit':
                    editWidgetVisible.value = true;
                    break;
                case 'moveGroup':
                    moveGroupData.value = {
                        moveGroupVisible: true,
                        moveGroupTitle: Kf.t('MoveGroup'),
                        moveToProject: false,
                        moveGroupName: editItem.value.category,
                        moveGroupList: contentModel.myProjectList.value,
                    }

                    moveGroupData.value.moveGroupToProject = false;
                    moveGroupDataParam.value = JSON.parse(JSON.stringify(editItem.value))

                    break;
                case 'moveToProject':
                    moveGroupData.value = {
                        moveGroupVisible: true,
                        moveGroupTitle: Kf.t('MoveToItem'),
                        moveGroupName: '',
                        moveGroupList: [],
                        projectUuid: '',
                    }
                    moveGroupDefaultName.value = Kf.t('Default')
                    moveGroupData.value.moveGroupToProject = true;
                    break;
                case 'moveToFile':
                    moveToFile.value = {
                        moveToFileVisible: true,
                        moveCategory: '',
                        list: []
                    }
                    if (contentModel.myFileData.value?.uuid) {
                        projectService.getCategoryPart(contentModel.myFileData.value.uuid, contentModel.currHeaderTab.value.code).then(result => {
                            let defaultData = {category: Kf.t('Default')}
                            let currIndex = null
                            result.some((item, index) => {
                                if (item?.category === Kf.t('Default')) {
                                    defaultData = Object.assign(item, defaultData);
                                    currIndex = index;
                                    return true
                                } else {
                                    return false
                                }
                            })
                            if (currIndex != null) {
                                result.splice(currIndex, 1)
                            }
                            result.unshift(defaultData);
                            moveToFile.value.list = result

                        })
                    }
                    break;
                case 'shangjia':
                    updatePartFlag(1, editItem.value.uuid, Kf.t('OnBoard'))
                    break;
                case 'xiajia':
                    updatePartFlag(0, editItem.value.uuid, Kf.t('Clear'))
                    break;
                case 'copy':
                    let _param = Object.assign({}, editItem.value)
                    _param.name = getNameByCopy(_param.name, getSceneList());
                    doCopyScene(_param, Kf.t('CreateCopy'), null, () => {
                        projectModel.switchProject(projectModel.currProject.value)
                    })
                    break;
                case 'rename':
                    renameVisible.value = true;
                    partName.value = editItem.value.name
                    break;
                case "delTheme":
                    deleteTronThemeAssets(editItem.value);
                    break;
                case "setTheme":
                    isSetTronTheme.value = true;
                    editTheme.value = JSON.parse(JSON.stringify(editItem.value))
                    break;
                case "moveTheme":
                    moveTronThemeAssets(editItem.value);
                    break;
                case 'exportPointTableData':
                    doExport(editItem.value)
                    break;
                case 'copyTheme':
                    copyThemeVisible.value = true;
                    editTheme.value = JSON.parse(JSON.stringify(editItem.value));
                    if (contentModel.currUser.value.manager) {
                        copyThemeData.value.systemFlagList = [
                            {name: Kf.t('OfficialComponent'), value: 1},
                            {name: Kf.t('UserContents'), value: 2}
                        ]
                        copyThemeData.value.systemFlag = 1;
                        copyThemeData.value.groups = ''
                    } else {
                        copyThemeData.value.systemFlag = 2;
                        copyThemeData.value.groups = ''
                    }
                    break;
            }
        }
        const updatePartPub = function (pub, uuid, msg) {
            projectService.updatePub(pub, uuid, contentModel.currHeaderTab.value.code).then(result => {
                if (result?.code == 200) {
                    ElMessage({
                        type: "success",
                        customClass: 'kf-3dc-create-success',
                        message: msg + Kf.t('Success'),
                        grouping: true
                    })
                    projectModel.switchProject(contentModel.myProject.value, contentModel.currLeftContent.value)
                } else {
                    ElMessage({type: "warning", message: msg + Kf.t('FailPleaseContactAdministrator'), grouping: true})
                }
            })
        }
        const updatePartFlag = function (state, uuids, msg) {
            projectService.updateFlag(state, uuids, contentModel.currHeaderTab.value.code).then(result => {
                if (result?.code == 200) {
                    projectModel.switchProject(contentModel.myProject.value, contentModel.currLeftContent.value);
                    ElMessage({
                        type: "success",
                        customClass: 'kf-3dc-create-success',
                        message: msg + Kf.t('Success'),
                        grouping: true
                    })
                } else {
                    ElMessage({type: "warning", message: msg + Kf.t('FailPleaseContactAdministrator'), grouping: true})
                }
            })
        }


        const doCopyScene = function (item, msg, code, callback) {
            moveGroupData.value.moveGruopVisible = false;
            projectService.copyPart(item, contentModel.currHeaderTab.value.code).then(res => {
                if (callback) {
                    callback()
                }
                if (res?.code == 200) {
                    if (code == 'library') {
                        projectModel.switchProject(contentModel.myProject.value, contentModel.currLeftContent.value);
                        ElMessage({
                            type: "success",
                            customClass: 'kf-3dc-create-success',
                            message: msg + Kf.t('Success') + ',' + Kf.t('MoveToMyFile2') + ` "${contentModel.myFileData.value.name}" ` + Kf.t('ViewingInTheProject'),
                            grouping: true
                        })
                    } else {
                        ElMessage({
                            type: "success",
                            customClass: 'kf-3dc-create-success',
                            message: msg + Kf.t('Success'),
                            grouping: true
                        })
                    }
                } else {
                    if (res?.status == '406') {
                        ElMessage({type: "error", message: Kf.t('UserSRemainingSpaceIsNotEnough'), grouping: true})
                    } else {
                        ElMessage({
                            type: "warning",
                            message: msg + Kf.t('FailPleaseContactAdministrator'),
                            grouping: true
                        })
                    }
                }
            }).catch((error) => {
                if (error.code == 2301) {
                    ElMessage({
                        type: 'warning',
                        message: Kf.t('LimitedNumberOfProjects') + '，' + Kf.t('PleaseContactTheAdministrator'),
                        grouping: true
                    });
                }
            });
        }
        const deleteScene = function (scene, callback) {
            projectService.updateDeleteScene(projectModel.currProject.value.uuid, scene.uuid, 1).then(result => {
                if (callback) {
                    callback()
                }
                let index1 = null;
                projectModel.sceneList.value.forEach((item, index) => {
                    if (item.uuid === scene.uuid) {
                        index1 = index;
                    }
                });
                if (index1 != null) {
                    projectModel.sceneList.value.splice(index1, 1);
                }
            });
        }

        const deleteArtistScene = function (scene) {
            projectService.reqDelArtistScene(projectModel.currProject.value.uuid, scene.uuid, 1).then(result => {
                if (result.code === "200") {
                    ElMessage.success("删除成功")
                }
                let index1 = null;
                projectModel.artSceneList.value.forEach((item, index) => {
                    if (item.uuid === scene.uuid) {
                        index1 = index;
                    }
                });
                if (index1 != null) {
                    projectModel.artSceneList.value.splice(index1, 1);
                }
            });
        }


        const deleteSpace = async (page, callback) => {
            let result = null, delIndex;
            try {
                result = await spaceService.deleteSpace(page.uuid);
                if (callback) {
                    callback()
                }
                projectModel.sceneList.value.forEach((item, index) => {
                    if (item.uuid === page.uuid) {
                        projectModel.sceneList.value.splice(index, 1);
                        ElMessage({
                            type: "success",
                            customClass: 'kf-3dc-create-success',
                            message: Kf.t('Deleted'),
                            grouping: true
                        })
                    }
                });
            } catch (e) {
                console.error(e + Kf.t('FailedToDeletePage'))
            }
        }
        const doDeleteScene = function (scene, callback) {
            ElMessageBox.confirm(
                Kf.t('AreYouSureYouWantToDeleteItDeleteItAndFindItInTheWastebin') + Kf.t('ThirtyDays'),
                Kf.t('Hint'),
                {
                    confirmButtonText: Kf.t('Confirm'),
                    cancelButtonText: Kf.t('Cancel'),
                    type: 'warning',
                    draggable: true,
                    customClass: 'kf-content-msg-confirm'
                })
                .then(() => {
                    if (contentModel.currHeaderTab.value.code === 'scene') {
                        if (scene.type === "artist") {
                            // 删除 artist scene
                            deleteArtistScene(scene);
                        } else {
                            deleteScene(scene, () => {
                                if (callback) {
                                    callback()
                                }
                            });
                        }
                    }
                    if (contentModel.currHeaderTab.value.code === 'widget') {
                        deleteWidget(scene)
                    }
                    if (contentModel.currHeaderTab.value.code === 'page') {
                        deletePage(scene)
                    }
                    if (contentModel.currHeaderTab.value.code === 'space') {
                        deleteSpace(scene, () => {
                            if (callback) {
                                callback()
                            }
                        })
                    }
                })
                .catch(() => {

                })
        }
        const doMoveToFile = function () {
            if (moveToFile.value.moveCategory != '') {
                moveToFile.value.moveToFileVisible = false;
                projectService.moveToFile(editItem.value.uuid, contentModel.myProject.value.uuid, moveToFile.value.moveCategory, contentModel.currHeaderTab.value.code).then(result => {
                    if (result?.code == 200) {
                        ElMessage({
                            type: "success",
                            customClass: 'kf-3dc-create-success',
                            message: Kf.t('MoveSuccessfully') + "," + Kf.t('MoveToMyFile2') + '-' + Kf.t('GroupAs') + ':' + moveToFile.value.moveCategory + Kf.t('ViewDown'),
                            grouping: true
                        })
                        projectModel.switchProject(projectModel.currProject.value)
                    } else {
                        ElMessage({
                            type: "warning",
                            message: Kf.t('MoveFailPleaseContactAdministrator'),
                            grouping: true
                        })
                    }
                })
            } else {
                ElMessage({type: "warning", message: Kf.t('SelectAGroup'), grouping: true})
            }

        }
        const doMoveGroup = function () {
            moveGroupData.value.moveGruopVisible = false;
            if (contentModel.currMenu.value?.code === 'file') {//项目分组下
                if (moveGroupData.value.projectUuid !== '') {
                    moveGroupData.value.moveGroupVisible = false
                    contentModel.doMoveToProject(editItem.value.uuid, moveGroupData.value.projectUuid, editItem)
                } else {
                    ElMessage({type: "warning", message: Kf.t('YouHaveToChooseAProject'), grouping: true})
                }
            } else {
                if (moveGroupData.value.moveGroupName) {
                    moveGroupDataParam.value.category = moveGroupData.value.moveGroupName;
                    moveGroupData.value.moveGroupVisible = false;
                    updateInfo(moveGroupDataParam.value, 'category', true)
                }
            }
        }

        const updateInfo = function (param, key, isMoveGroup,callback) {
            if (contentModel.newGroup.value.category === param.category) {
                param.systemFlag = contentModel.newGroup.value.systemFlag
            }
            if (!param.projectUuid) {
                param.projectUuid = 8402;
            }
            projectService.updatePartInfo(param, contentModel.currHeaderTab.value.code).then(result => {
                if (result?.code == 200) {
                    editWidgetOrScene.value = result.data;
                    editItem.value[key] = param[key]
                    ElMessage.success(Kf.t('ModifiedSuccessfully'));
                    if (isMoveGroup) {
                        if (contentModel.isProject.value) {
                            projectModel.switchProject(projectModel.currProject.value)
                        } else {
                            projectModel.switchProject(projectModel.currProject.value, contentModel.currLeftContent.value)
                        }
                    }
                } else {
                    if (result?.status === '406') {
                        ElMessage({type: "error", message: Kf.t('UserSRemainingSpaceIsNotEnough'), grouping: true})
                    } else {
                        ElMessage({
                            type: "warning",
                            message: Kf.t('UpdateFailPleaseContactAdministrator'),
                            grouping: true
                        })
                    }

                }
                if(callback){
                    callback(res)
                }
            });
        }


        const updateSpaceInfo = function (param, key,) {
            spaceService.updateSpace(param).then(res => {
                editItem.value[key] = param[key];
                ElMessage({
                    type: "success",
                    customClass: 'kf-3dc-create-success',
                    message: Kf.t('ModifiedSuccessfully'),
                    grouping: true
                })
            })
        }

        // 更新 update artist 艺术家数据
        const updateArtistSceneInfo = function (param, key) {
            projectService.reqUpdateArtistScene(param).then(res => {
                editItem.value[key] = param[key];
            })
        }

        const getEditTime = contentModel.getEditTime;
        let publishHisList = ref([])
        let publishHisLists = ref([])
        let hisDialogVisible = ref(false)

        const doPublish = function () {
            let url = publishData.value.publishUrl + '/';
            let params = {};
            if (publishData.value.user && publishData.value.pwd) {
                params.user = publishData.value.user;
                params.password = publishData.value.pwd;

            }
            url += tools.replaceAll(window.Kf.kfSaveContent(JSON.stringify(params)), "/", "====");
            buildPubLine(url)
            //window.open(url);
        }
        const doPublishTab = function (val) {
            publishData.value.default = val;
            getPublishHisList()
        }
        const getPublishHisList = function () {
            publishHisList.value = [];
            projectService.getShareList(currEditScene.value.projectUuid, currEditScene.value.uuid).then(res => {
                res = res ? res : [];
                res.forEach(_his => {
                    const op = _his.overflowPolicy;
                    const org = publishModeList.value.find(_o => _o.value === op);
                    if (org) {
                        _his.overflowPolicyName = org.name;
                    }
                    let link = _his.publishLink;
                    if (link.indexOf('/') !== -1) {
                        let arr = link.split('/')
                        link = arr[arr.length - 1];
                        if (link !== '') {
                            let p = tools.replaceAll(link, "====", "/");
                            let p1 = JSON.parse(window.Kf.kfLoadContent(p));
                            _his.user = p1.user
                        } else {
                            _his.user = p.loginUserId
                        }
                    } else {
                        _his.user = p.loginUserId
                    }
                });
                publishHisList.value = res;
            })
        }

        const copyHisLine = (his, short) => {
            let link = his.shortLink;
            let currUrl = window.location.href;
            let index = currUrl.indexOf("#/") + 2;
            link = currUrl.substring(0, index) + link;

            const page = document.createElement('input');
            page.value = link;
            document.body.appendChild(page);
            page.select();
            document.execCommand('Copy');
            page.style.display = 'none';
            document.body.removeChild(page);
            ElMessage({
                type: 'success',
                message: Kf.t('PostLinksCopiedToClipboard'),
                grouping: true
            });
        };

        const openHisLine = (his, short) => {
            let link = his.shortLink;
            let currUrl = window.location.href;
            let index = currUrl.indexOf("#/") + 2;
            link = currUrl.substring(0, index) + link;
            window.open(link, "_blank", {'incognito': true, 'noopener': true, 'noreferrer': true});
            publishData.value.publishVisible = false;
        }
        const buildPubLine = (pubLink) => {
            const data = {};
            data.projectUuid = currEditScene.value.projectUuid;
            data.pageUuid = currEditScene.value.uuid;
            data.device = 'pc';
            data.useShort = true;
            data.description = contentModel.isProject.value ? (contentModel.currGroup.value + '-' + contentModel.currLeftContentName.value + '-' + currEditScene.value.name) : (contentModel.currMenu.value.name + '-' + contentModel.currLeftContentName.value + '-' + currEditScene.value.name);
            data.version = currEditScene.value.version;
            data.loginUserId = publishData.value.user;
            data.overflowPolicy = publishData.value.publishMode;
            const idx = pubLink.indexOf("#/") + 2;
            data.publishLink = pubLink.substring(idx);
            let token = data.publishLink;
            data.token = token.substring(token.lastIndexOf('/') + 1);
            projectService.addShareList(data).then(res => {
                if (res && res.shortLink) {
                    window.open('#/' + res.shortLink);
                    publishData.value.publishVisible = false;
                } else {
                    ElMessage({type: 'warning', message: Kf.t('PublishFailPleaseContactAdministrator')});
                    projectService.getShareHis().then(result => {
                        if (result?.length > 0) {
                            hisDialogVisible.value = true;
                            publishHisLists.value = loadPublishHisLists(result)


                        }
                    })
                }
            });
        }
        const loadPublishHisLists = function (res) {
            res = res ? res : [];
            res.forEach(_his => {
                const op = _his.overflowPolicy;
                const org = publishModeList.value.find(_o => _o.value === op);
                if (org) {
                    _his.overflowPolicyName = org.name;
                }
                const op1 = _his.publishLink;
                if (op1.indexOf("widgetPresenter") != -1) {
                    _his.type = Kf.t('3dComponent');
                } else {
                    _his.type = Kf.t('3dScene');
                }

            });
            return res;
        }

        const deleteLink = function (val) {
            ElMessageBox.confirm(
                Kf.t('DeterminesWhetherThisReleaseLinkShouldBePlacedNext'),
                Kf.t('Hint'),
                {
                    confirmButtonText: Kf.t('Yes'),
                    cancelButtonText: Kf.t('No'),
                    type: 'warning',
                    draggable: true,
                    customClass: 'kf-content-msg-confirm'
                })
                .then(() => {
                    publishData.value.deleLink = val
                    projectService.deleteShareList(currEditScene.value.projectUuid, currEditScene.value.uuid).then(res => {
                        if (res == 'success') {
                            ElMessage({type: 'success', message: Kf.t('Discontinue'), grouping: true});
                            getPublishHisList()
                        } else {
                            ElMessage({type: 'warning', message: res, grouping: true});
                        }
                    })

                })
                .catch(() => {

                })

        }
        let uploadImgBox = ref(null)
        let uploadImgBox1 = ref(null)

        const uploadResource1 = function (item) {
            let param = new FormData();
            param.append('file', item.file);
            projectService.uploadImg(param).then(result => {
                if (result) {
                    doUploadSuccess(result)
                } else {
                    ElMessage({type: 'warning', message: result.message, grouping: true});
                }
            })
        }
        const doUploadSuccess = function (response, file, fileList) {
            uploadImgBox.value.clearFiles();
            if (response) {
                currEditSceneImg.value = response;
                currEditScene.value.screenshotPath = response
            } else {
                ElMessage({type: 'warning', message: response.message, grouping: true});
            }
        }
        const doUploadError = function (error, uploadFile, fileList) {
            ElMessage({type: 'error', message: error, grouping: true});
        }


        const changeTronThemeImg = (item) => {
            let param = new FormData();
            param.append('file', item.file);
            projectService.updateTronThemeScreenShot(editTheme.value.uuid, param).then(res => {

                if (res) {
                    doUploadThemeSuccess(res)
                } else {
                    ElMessage({type: 'warning', message: res.message, grouping: true});
                }
            })

        }

        const doUploadThemeSuccess = function (response, file, fileList) {
            uploadThemeImgBox.value.clearFiles();
            if (response) {
                editTheme.value.screenshotPath = response;
            } else {
                ElMessage({type: 'warning', message: response.message, grouping: true});
            }
        }


        const uploadResource = function (item) {
            let param = new FormData();
            param.append('file', item.file);///cloud/api/repo/kf-resource/
            projectService.uploadImg(param).then(result => {
                if (result) {
                    doUploadSuccess1(result)
                } else {
                    ElMessage({type: 'warning', message: result.message, grouping: true});
                }
            })
        }
        const doUploadSuccess1 = function (response, file, fileList) {
            uploadImgBox1.value.clearFiles();
            if (response) {
                editWidgetOrScene.value.screenshotPath = response
            } else {
                ElMessage({type: 'warning', message: response.message, grouping: true});
            }
        }
        const doEditScene = function () {
            if (currEditScene.value.name == null || currEditScene.value.name === '') {
                ElMessage({type: 'warning', message: Kf.t('NameCannotBeEmpty2'), grouping: true});
                return false;
            }
            if (currEditScene.value.category == null || currEditScene.value.category === '') {
                ElMessage({type: 'warning', message: Kf.t('TheLibraryCantBeEmpty'), grouping: true});
                return false;
            }
            if (currEditScene.value.category !== '' && currEditScene.value.name !== '') {
                publishWidget()
                editSceneVisible.value = false;
            }
        }
        const doEditWidget = function () {
            if (currEditWidget.value?.name === '') {
                ElMessage({type: 'warning', message: Kf.t('NameCannotBeEmpty2'), grouping: true});
                return;
            }
            editWidgetVisible.value = false

            if (currEditWidget.value.type === "artist") {
                currEditWidget.value.screenshot = editWidgetOrScene.value.screenshotPath;
                updateArtistSceneInfo(currEditWidget.value, 'name');
            } else {
                currEditWidget.value.screenshotPath = editWidgetOrScene.value.screenshotPath;
                updateInfo(currEditWidget.value, 'name',true)
            }
        }
        const publishWidget = function () {
            let p = JSON.parse(JSON.stringify(currEditScene.value))
            p.projectUuid = contentModel.libraryData.value.uuid;
            if (currEditSceneImg.value !== '') {
                p.screenshotPath = currEditSceneImg.value;
            }
            projectService.copyPart(p, contentModel.currHeaderTab.value.code).then(res => {
                currEditSceneImg.value = ''
                if (res?.code === 200) {
                    ElMessage({
                        type: "success",
                        customClass: 'kf-3dc-create-success',
                        message: Kf.t('PostedSuccessfully') + "， " + Kf.t('PleaseMoveToLibrary') + p.category + Kf.t('MainBody'),
                        grouping: true
                    })
                } else {
                    if (res?.status === '406') {
                        ElMessage({type: "error", message: Kf.t('UserSRemainingSpaceIsNotEnough'), grouping: true})
                    } else {
                        ElMessage({
                            type: "warning",
                            message: Kf.t('PublishFailPleaseContactAdministrator'),
                            grouping: true
                        })
                    }

                }
            })
        }
        watch(contentModel.currMenu.value, new_v => {

        })
        window.addEventListener('click', function (e) {
            if (!editDom.value || (editDom.value && editDom.value.contains && !editDom.value.contains(e.target)) || !editIconDom.value || (editIconDom.value && editIconDom.value.contains && !editIconDom.value.contains(e.target))) {
                showEdit.value = false;
            }
        });

        const doRename = function () {
            let p = JSON.parse(JSON.stringify(editItem.value))
            p.name = partName.value;
            renameVisible.value = false;
            if (contentModel.currHeaderTab.value.code === 'space') {
                updateSpaceInfo(p, 'name');
            } else {
                updateInfo(p, 'name');
            }
        }

        const editGroups = function (item, event) {
            editGroup.value = true;
            editGroupName.value = event.target.innerText;
            event.target.contentEditable = true;
            event.target.focus();
            document.execCommand('selectAll');
        }
        const changeGroup = (item, event) => {
            let _currText=event && event.target&&event.target.innerText?event.target.innerText.trim():''
            if (_currText === '') {
                event.target.innerHTML = item.name;
                ElMessage({
                    type: "warning",
                    customClass: 'kf-3dc-create-success',
                    message: Kf.t('CannotBeEmpty'),
                    grouping: true
                })
                return;
            }
            if (!(_currText !== "" && contentModel.checkNameLength(_currText))) {
                event.target.innerHTML = item.name;
                ElMessage({
                    type: "warning",
                    customClass: 'kf-3dc-create-success',
                    message: Kf.t('NameMayNotBeLongerThan50Bytes'),
                    grouping: true
                })
                return ;
            }
            if (event?.target?.innerText.trim() === item.name) {
                return;
            }
            editGroup.value = false;
            let p = JSON.parse(JSON.stringify(item))
            p.name = event.target.innerText.trim();
            if (contentModel.currHeaderTab.value.code === 'space') {
                spaceService.updateSpace(p).then(res => {
                    if (res?.code == 200) {
                        item.name = event.target.innerText;
                        event.target.innerHTML = item.name.trim();
                        ElMessage({
                            type: "success",
                            customClass: 'kf-3dc-create-success',
                            message: Kf.t('ModifiedSuccessfully'),
                            grouping: true
                        })
                    } else {
                        if (result?.status === '406') {
                            ElMessage({type: "error", message: Kf.t('UserSRemainingSpaceIsNotEnough')})
                        } else {
                            ElMessage({type: "warning", message: Kf.t('UpdateFailPleaseContactAdministrator')})
                        }
                    }

                })
            } else if (contentModel.currHeaderTab.value.code === 'scene' && contentModel.currMenu.value.code === 'file') {
                projectService.updatePartInfo(p, contentModel.currHeaderTab.value.code).then(result => {
                    if (result?.code == 200) {
                        item.name = event.target.innerText;
                        event.target.innerHTML = item.name.trim();
                    } else {
                        if (result?.status == '406') {
                            ElMessage({type: "error", message: Kf.t('UserSRemainingSpaceIsNotEnough')})
                        } else {
                            ElMessage({type: "warning", message: Kf.t('UpdateFailPleaseContactAdministrator')})
                        }

                    }
                });
            } else {
                // START 2024.06.18 LIBRARY 库改造 修改主题的name
                TronThemeName.value = event.target.innerText;
                item.name = TronThemeName.value;
                setTronThemeAssets(item);
                // START LIBRARY 库改造 修改主题的name
            }

        }

        const createCategory = function () {
            categoryName.value = '';
            createCategoryVisible.value = true;
        }
        const doCreateCategory = function () {
            createCategoryVisible.value = false;
            if (categoryNameOfficial.value) {
                currEditScene.value.category = categoryName.value + '(' + Kf.t('Official') + ')';
            } else {
                currEditScene.value.category = categoryName.value;
            }

            currEditScene.value.systemFlag = categoryNameOfficial.value ? 1 : 2;
            libraryCategoryList.value.unshift(categoryName.value)
        }

        const getShowImg = function (item) {
            let res = false;
            if (contentModel.currMenu.value.code === 'tutorial') {
                res = true;
            } else if (contentModel.currHeaderTab.value.code === 'space') {
                res = item.buildingId === item.nanoId || item.screenshotPath;
            }else{
                res = item.screenshotPath !== undefined && item.screenshotPath !== null && item.screenshotPath !== '';
            }
            return res;
        }

        const getThemeImage = function (item) {
            let url = require('../../../styles/img/defaultSceneImg.png');
            if (item.screenshotPath) {
                url = host.value + Const.servicePath.value + '/api/repo/kf-pub-resource/load-file?uuid=' + item.screenshotPath + '&projectId=' + item.projectId;
            }
            return url;
        }

        const getImageUrl1 = function (item) {
            let url = require('../../../styles/img/defaultSceneImg.png');
            if (contentModel.currMenu.value.code === 'tutorial') {
                if (item.screenshot != null) {
                    url = host.value + Const.servicePath.value + '/api/repo/kf-part/find-resource?path=' + item.screenshot;
                }
            } else {
                if (contentModel.currHeaderTab.value.code === 'space') {
                    if (item.buildingId === item.nanoId) {
                        url = Const.servicePath.value + '/api/repo/kf-space/find-file?path=' + '/' + item.projectId + '/' + item.buildingId + '.png';
                    }else if (item?.screenshotPath != null) {
                        url = host.value + Const.servicePath.value + '/api/repo/kf-part/find-resource?path=' + item.screenshotPath + '&projectId=' + projectModel.currProject.value.uuid
                    }
                } else {
                    if (item?.screenshotPath != null) {
                        url = host.value + Const.servicePath.value + '/api/repo/kf-part/find-resource?path=' + item.screenshotPath + '&projectId=' + projectModel.currProject.value.uuid
                    }
                }
            }

            return url;
        }
        const handleClick = function (item) {
            if (contentModel.currMenu.value.code === 'tutorial') {
                videoDialog.value = true;
                currVideoData.value = JSON.parse(JSON.stringify(item))
            }
        }
        const changeVideoDialog = function (val) {
            videoDialog.value = val;
        }
        const changePreviewDialog = function (val) {
            previewDialog.value = val;
        }

        const doOpenScene = function (scene, version = 'last') {
            let currInfo = {
                isProject: contentModel.isProject.value,
                currLeftContent: contentModel.currLeftContent.value
            };
            //将currInfo存入到浏览器缓存中
            localStorage.setItem('currInfo', JSON.stringify(currInfo));
            router.replace({
                path: '/main/' + projectModel.currProject.value.uuid + '/' + scene.uuid + '/' + scene.version,
            });
        }
        const handleDragStart = function (item, event) {
            contentModel.currDragItem.value = item
        }

        const getItemName = (item) => {
            let name = "";
            if (contentModel.currMenu.value.code === 'library') {
                if (item.labelInterpret && typeof item.labelInterpret === "string") {
                    let _label = JSON.parse(item.labelInterpret)
                    name = _label[locale] ? _label[locale] : item.name;
                } else {
                    name = item.name;
                }
            } else {
                name = item.name;
            }
            return name;
        }

        const setTronThemeBydialog = () => {
            updateLoading.value = true;
            setTronThemeAssets(editTheme.value, (res) => {
                updateLoading.value = false
                if (res === 'success') {
                    isSetTronTheme.value = false;
                    if (editTheme.value.groups !== contentModel.currLeftContentName.value) {
                        contentModel.loadPart(contentModel.myProject.value, null, null, (result) => {
                            if (result && result.indexOf(contentModel.currLeftContentName.value) === -1) {
                                contentModel.currLeftContentName.value = editTheme.value.groups;
                                contentModel.currLeftContent.value = {category: editTheme.value.groups}
                            }
                            projectModel.switchProject(projectModel.currProject.value, {category: contentModel.currLeftContentName.value});
                        })
                    } else {
                        projectModel.switchProject(projectModel.currProject.value, {category: contentModel.currLeftContentName.value});
                    }
                } else {
                    ElMessage.error(res);
                }
            });
        }


        const setTheme = function (val) {
            isSetTronTheme.value = val;
            editTheme.value = JSON.parse(JSON.stringify(currTronTheme.value))
        }


        const initNewCategory = (val) => {
            tronThemeCategoryList.value.push({label: val, value: val});
            editTheme.value.category = val;
            newCategory.value = "";
        }

        const myProjectList1 = computed(() => {
            let arr = []
            if (contentModel.myProjectList.value?.length > 0) {
                arr = [...arr, ...contentModel.myProjectList.value];
            }

            return arr;
        })
        const createThemeCategory = function () {
            ElMessageBox.prompt(Kf.t('Name'), Kf.t('CreateClassification'), {
                confirmButtonText: Kf.t('Confirm'),
                cancelButtonText: Kf.t('Cancel'),
                draggable: true,
                customClass: 'kf-artist-confirm-box2',
            }).then(({value}) => {
                if (value && value != '') {
                    editTheme.value.groups = value;
                }
            })
                .catch(() => {
                })
        }

        const closeDialog = function (val) {
            widgetPreviewDialog.value = val
        }
        const imageError = function (event) {
            event.target.src = emptySceneImg;
        }
        return {
            imageError,
            handleDragStart,
            loading: projectModel.loadingData,
            currMenu: contentModel.currMenu,
            currGroup: contentModel.currGroup,
            projectList: projectModel.projectList,
            sceneList: projectModel.sceneList,
            currHeaderTab: contentModel.currHeaderTab,
            isProject: contentModel.isProject,
            myProjectList: contentModel.myProjectList,
            currProject: projectModel.currProject,
            currLeftContentName: contentModel.currLeftContentName,
            libraryCategoryList,
            currUser: contentModel.currUser,
            sceneOperations,
            sceneOperations1,
            editOperation,
            currEditScene,
            publishData,
            editSceneVisible,
            createProjectVisible,
            createProjectData,
            moveGroupData,
            handleEdit,
            getEditTime,
            getShowBtn,
            doMoveGroup,
            publishModeList,
            publishUserList,
            doPublish,
            doUploadSuccess,
            doUploadError,
            uploadImgBox,
            uploadImgBox1,
            doEditScene,
            getSceneList,
            showEdit,
            editStyle,
            editDom,
            handleEditPart,
            editIconDom,
            renameVisible,
            partName,
            doRename,
            editGroupName,
            editGroup,
            editGroups,
            editItem,
            changeGroup,
            createCategory,
            categoryName,
            createCategoryVisible,
            doCreateCategory,
            getImageUrl1, getShowImg,
            moveGroupDefaultName,
            getShow,
            handleClick,
            categoryNameOfficial,
            videoDialog,
            changeVideoDialog,
            currVideoData,
            currEditSceneImg,
            editWidgetVisible,
            doEditWidget,
            doUploadSuccess1,
            currEditWidget,
            editWidgetOrScene,
            uploadResource,
            moveToFile,
            doMoveToFile,
            doPublishTab,
            publishHisList,
            copyHisLine,
            openHisLine,
            deleteLink,
            previewDialog,
            changePreviewDialog,
            publishHisLists,
            hisDialogVisible,
            host,
            uploadResource1,
            showDialog, doPublishWidget, change,
            getItemName, artSceneData, artistPreview,
            isSetTronTheme, setTronThemeBydialog, TronThemeName,
            changeTronThemeImg, tronThemeImg, isTronThemeDetails,
            setTheme, currTronTheme, tronThemeCategoryList,
            initNewCategory, newCategory, getThemeImage, editTheme, updateLoading,
            createThemeCategory, myProjectList1, doUploadThemeSuccess, uploadThemeImgBox,
            copyThemeVisible, copyThemeData,
            themeGroup: contentModel.themeGroup,
            doCopyTheme,
            widgetPreviewDialog,
            closeDialog
        }
    }
}
</script>

<style lang="scss">
.kf-tron-theme {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 0.5vw;
  gap: 15px;

  .kf-tron-theme-img {
    width: 100%;
    height: auto;
    min-height: 320px;
    display: flex;
    align-items: center;
    justify-content: center;
    border: 1px solid #222;
    background-color: #181818;
    border-radius: 5px;

    img {
      width: 100%;
      height: auto;
    }

    &:hover {
      border: 1px solid rgba(41, 120, 236, 0.5);
    }

    i {
      color: #888;
      font-size: 25px;
    }
  }
}
</style>
