import _defineProperty from "F:/workspace/\u7A7A\u95F4\u6CBB\u7406\u5DE5\u5177/\u7A7A\u95F4\u6CBB\u7406CAD/space-management/spaceManager/node_modules/@babel/runtime/helpers/esm/defineProperty.js";
import "core-js/modules/es.array.push.js";
import Axios from 'axios';
import oemManager from '@/utils/oem';
export const init = options => {
  const go = new window.Go();
  let mod, inst;
  WebAssembly.instantiateStreaming(fetch('static/kingfisher.wasm'), go.importObject).then(result => {
    mod = result.module;
    inst = result.instance;
    run();
  }).catch(err => {
    console.error(err);
  });
  async function run() {
    await go.run(inst);
    inst = await WebAssembly.instantiate(mod, go.importObject);
  }
  window.Kf = window.Kf || {};

  // 授权检查失败处理方法
  window.Kf.onLicenseCheckFail = function () {
    console.error('没有有效授权, 请联系管理员');
  };
  if (options.licenseServer) {
    let config = false;
    let setLicenseServer = function () {
      if (window.Kf.setServer && !config) {
        window.Kf.setServer(options.licenseServer);
        config = true;
        console.log('设置授权服务器', options.licenseServer);
      }
      if (window.Kf.kfIsReady && window.Kf.kfIsReady()) {
        console.log('场景准备完成');
        clearInterval(timer);
      }
    };
    let timer = setInterval(setLicenseServer, 50);
  }
};
const develop = 'development' === process.env.NODE_ENV;
function uuid() {
  let s = [];
  let hexDigits = '0123456789abcdef';
  for (let i = 0; i < 32; i++) {
    s[i] = hexDigits.substr(Math.floor(Math.random() * 0x10), 1);
  }
  s[14] = '4';
  s[19] = hexDigits.substr(s[19] & 0x3 | 0x8, 1);
  s[8] = s[13] = s[18] = s[23] = '-';
  let uuid = s.join('');
  return uuid;
}
const runUntil = function (condition, callback, timeout = 30 * 1000) {
  let existTime = new Date().getTime() + timeout;
  let timer = window.setInterval(() => {
    if (condition()) {
      clearInterval(timer);
      if (callback) {
        callback();
      }
    } else if (new Date().getTime() > existTime) {
      clearInterval(timer);
    }
  }, 50);
};
export class Web3dManager {
  constructor(domId, options) {
    _defineProperty(this, "doFullscreen", function () {
      if (this.fullscreen) {
        this.fullscreen = false;
        this.exitFullscreen();
      } else {
        this.fullscreen = true;
        this.requestFullscreen(this.getCanvas());
      }
    });
    window.Kf.listeners = window.Kf.listeners || {};
    this.id = uuid();
    this.options = options;
    this.domCanvas = document.getElementById(domId);
    this.netMsgRouter = null;
    this.currSceneId = 0;
    this.puzzle = null;
    this.loaded = false;
    this.currScene = null;
    this.loadStartTime = 0;
    this.expectedLoadingTime = 0;
    this.puzzleLogic = null;
    this.loadingTextTimer = null;
    runUntil(() => {
      return options.isReady || window.Kf.kfIsReady && window.Kf.kfIsReady();
    }, () => this.init());
    this.infoPoints = [];
    window.Kf.listeners[this.id] = {};
  }
  registerWeb3dListener(listeners) {
    window.Kf.listeners[this.id] = listeners;
  }
  issueEvent(event, params) {
    let args = null;
    if (params) {
      args = JSON.parse(JSON.stringify(params));
    }
    window.postMessage({
      action: 'puzzleEvent',
      event: {
        name: event,
        args: args
      }
    }, '*');
  }
  createEngine() {
    const _self = this;
    let global = window.Kc || {};
    window.Kc = global;
    let canvas = _self.domCanvas;
    canvas.oncontextmenu = function (e) {
      e.preventDefault();
      e.stopPropagation();
    };
    global[_self.id + '_canvas'] = canvas;
    let engine = new window.Kingfisher.Engine(canvas, true);
    engine.netMsgRouter = this.netMsgRouter;
    try {
      window.Kf.requireLib(engine);
    } catch (e) {
      console.log(e);
    }
    global[this.id + '_engine'] = engine;
    let scalingLevel = this.getHardwareScalingLevel(canvas.clientWidth, canvas.clientHeight);
    engine.setHardwareScalingLevel(scalingLevel);
    function resizeHandler() {
      let scaling = _self.getHardwareScalingLevel(canvas.clientWidth, canvas.clientHeight);
      engine.setHardwareScalingLevel(scaling);
      engine.resize(canvas.clientWidth, canvas.clientHeight);
    }
    window.addEventListener('resize', resizeHandler);
    let resizeObserver = new ResizeObserver(resizeHandler);
    resizeObserver.observe(canvas);
    this.initLoader();
  }
  getCanvas() {
    window.Kc = window.Kc || {};
    return window.Kc[this.id + '_canvas'];
  }
  getEngine() {
    window.Kc = window.Kc || {};
    return window.Kc[this.id + '_engine'];
  }
  getSceneManager() {
    window.Kc = window.Kc || {};
    return window.Kc[this.id + '_sceneManager'];
  }
  getScene() {
    window.Kc = window.Kc || {};
    return window.Kc[this.id + '_scene'];
  }
  getHardwareScalingLevel(width, height) {
    let r = window.devicePixelRatio;
    width *= r;
    height *= r;
    const w = Math.max(16, Math.min(1920 * 2, width));
    const h = Math.max(16, Math.min(1080 * 2, height));
    if (width <= w && height <= h) {
      return 1 / r;
    }
    if (width / height > w / h) {
      return width / w / r;
    } else {
      return height / h / r;
    }
  }
  onLoadingHide() {
    if (window.onSceneLoaded) {
      window.onSceneLoaded();
    }
    if (window.Kf.listeners[this.id] && window.Kf.listeners[this.id].onLoadingHide) {
      window.Kf.listeners[this.id].onLoadingHide(this.currScene);
    }
  }
  updatePuzzleLogicCode(code) {
    if (code.indexOf('let logic = {') == -1) {
      ['registerContext', 'puzzleDispose', 'fetchCallback', 'invokeEvent', 'onSceneLoaded'].forEach(s => {
        code = code.replace('window.' + s, 'logic.' + s);
      });
      code = code.replace('let _pGlob = {disposed: false};', 'let _pGlob = {disposed: false};\r\n    let logic = {_pGlob: _pGlob};');
      code = code.replace(/window.kingfisherPickListener/g, '_pGlob.kingfisherPickListener');
      code = code.replace('window.puzzleLogic =', 'let initLogic =');
      code = code.replace('window.scene.onPointerObservable', '_pGlob.scene.onPointerObservable');
      code = code.replace('call("registerContext")', 'call("registerContext", newScene.subScene)');
      code = code.replace('registerContext(\'params\'', 'logic.registerContext(\'params\'');
      code = code.replace('console.log("翠鸟拼图已经加载");', 'console.log("翠鸟拼图已经加载");\r\n    window.puzzleLogic = logic;\r\n    return logic;');
      code = code.replace('window.puzzleLogic();', 'window.puzzleLogic = initLogic(\'__sceneId\');');
      code = code.replace('eval(res);', 'eval(window.updatePuzzleLogicCode(res));');
    }
    return code;
  }
  showLoadingText() {
    let engine = this.getEngine();
    if (engine != null && engine.loadingScreen != null) {
      let loadingTime = new Date().getTime() - this.loadStartTime;
      let percentage = (loadingTime / 10).toFixed(2);
      if (percentage > 0.99) {
        percentage = 0.99;
      }
      engine.loadingScreen.updateProgress(Math.floor(percentage * 100));
    }
  }
  initLoader() {
    let canvas = this.getCanvas();
    let engine = this.getEngine();
    let loadingScreen = new window.Kingfisher.Runtime.LoadingScreenV2(canvas);
    loadingScreen.hideMiniLogo = true;
    if (!oemManager.beSame('product', 'logo')) {
      loadingScreen.logoSrc = oemManager.getImage('product', 'logo', 'space');
    }
    if (this.options.logo) {
      loadingScreen.customLogoSrc = this.options.logo;
      loadingScreen.style = 'custom';
      if (this.options.logoSize) {
        let size = this.options.logoSize.split(',');
        if (size.length === 2) {
          loadingScreen.logoSize = {
            width: size[0],
            height: size[1]
          };
        }
      }
    }
    engine.loadingScreen = loadingScreen;
    loadingScreen.onHide = () => {
      this.onLoadingHide();
    };
  }
  pickPoiNode(pickResult) {
    if (pickResult == null) {
      return;
    }
    let currNode = pickResult.pickedMesh;
    while (currNode != null) {
      if (currNode.EnableTouch) {
        return currNode;
      }
      currNode = currNode.parent;
    }
    return currNode;
  }
  pointHandler(pointerInfo) {
    switch (pointerInfo.type) {
      case window.Kingfisher.PointerEventTypes.POINTERTAP:
        this.onTap(pointerInfo);
        break;
    }
  }
  onTap(pointerInfo) {
    if (window.Kf.listeners[this.id] && window.Kf.listeners[this.id].onTap) {
      window.Kf.listeners[this.id].onTap(pointerInfo);
    }
  }
  onLoad(scene) {
    if (window.Kf.listeners[this.id] && window.Kf.listeners[this.id].onLoad) {
      window.Kf.listeners[this.id].onLoad(scene);
    }
  }
  playAnimation(animationGroup, toState, fromState) {
    if (this.currScene) {
      let animationGroups = this.currScene.animationGroups;
      if (animationGroups) {
        let animationGroup = animationGroups.find(animation => {
          return animation.name === animationGroup;
        });
        if (animationGroup) {
          if (fromState) {
            animationGroup.goToState(fromState);
          }
          animationGroup.playToState(toState);
        }
      }
    }
  }
  loadSceneById(sceneId, animation, loadListener, tapListener) {
    this.loadSceneFromUrl(sceneId, animation, loadListener, tapListener);
  }
  registerSchemes(sceneId) {
    if (this.options.schemes) {
      window.Kingfisher.FileTools.RegisterSchemes(this.options.schemes);
    } else {
      let rootPath = 'static/scenes/' + sceneId + "/";
      window.Kingfisher.FileTools.RegisterSchemes({
        'root://': rootPath
      });
      window.Kingfisher.FileTools.RegisterSchemes({
        'lib://': '/static'
      });
    }
  }
  loadSceneFromUrl(sceneId, animation, loadListener, tapListener) {
    const _self = this;
    if (typeof sceneId === 'string') {
      sceneId = [sceneId];
    }
    let rootPath = 'static/scenes/' + sceneId[0] + "/";
    _self.registerSchemes(sceneId[0]);
    this.loadScene(sceneId, '', function (scene) {
      try {
        if (loadListener) {
          loadListener(_self.currScene);
        }
      } catch (e) {
        console.error(e);
      }
    }, tapListener);
  }
  loadScene(id, animation, onLoad, onTap) {
    const _self = this;
    _self.currSceneId = id[0];
    let sceneManager = window.Kc[_self.id + '_sceneManager'];
    window.updatePuzzleLogicCode = this.updatePuzzleLogicCode;
    clearInterval(_self.loadingTextTimer);
    _self.loadingTextTimer = setInterval(() => {
      _self.showLoadingText();
    }, 1000);
    _self.loadStartTime = new Date().getTime();
    if (window.scene) {
      try {
        sceneManager.disposeAllScene();
        if (window.scene.puzzleLogic) {
          window.scene.puzzleLogic.puzzleDispose();
          delete window.scene.puzzleLogic;
        }
        window.scene = null;
        window.scenes = [];
      } catch (e) {
        console.error(e);
      }
    }
    let scenes = [];
    let promises = [];
    for (let i = 0; i < id.length; i++) {
      let uuid = id[i];
      if (!uuid) {
        continue;
      }
      let scenePath = uuid;
      if (scenePath.indexOf('.') === -1) {
        scenePath += ".json";
      }
      promises.push(new Promise((resolve, reject) => {
        //Kingfisher.SceneLoader.Load(root + _self.project.uuid + "/" + uuid + ".json", "", window.engine, function(res) {
        try {
          window.sceneManager.loadScene('root://', 'root://', scenePath, '', function (res, firstTimeLoaded) {
            let scene = res.subScene;
            scenes[i] = scene;
            window.needFireAfterLoad = true;
            res.setRenderEnabled(true, 0.5, () => {});
            scene.activeCamera.attachControl(_self.domCanvas, true);
            if (firstTimeLoaded) {
              scene.onPointerObservable.add(pointerInfo => {
                _self.pointHandler(pointerInfo);
              });
            }
            let loadLogic = code => {
              try {
                window.scene = scene;
                window.onSceneLoaded = null;
                window.Kc[_self.id + '_scene'] = scene;
                code = window.updatePuzzleLogicCode(code);
                eval(code);
                scene.puzzleLogic = window.puzzleLogic;
                delete window.puzzleLogic;
                _self.registerContext(scene);
                if (scene.puzzleLogic.onSceneLoaded) {
                  scene.puzzleLogic.onSceneLoaded();
                }
                resolve();
              } catch (e) {
                console.error(code);
                console.error(e);
                resolve();
              }
            };
            if (scene.logicJS !== undefined) {
              loadLogic(scene.logicJS);
            } else {
              let logicPath = scenePath.split(".")[0] + "_logic.js";
              let logic = window.Kingfisher.FileTools.GetAbsoluteUrl('root://' + logicPath);
              logic += (logic.indexOf('?') > 0 ? '&' : '?') + 't=' + new Date().getTime();
              let cookie = window.Kingfisher.getToken ? {
                headers: {
                  Authentication: window.Kingfisher.getToken()
                }
              } : {};
              Axios.get(logic, cookie).then(data => {
                if (data && data.data) {
                  loadLogic(data.data);
                }
              }).catch(error => {
                console.error(error);
                resolve();
              });
            }
          }, e => {
            resolve();
          });
        } catch (e) {
          console.error(e);
          resolve();
        }
      }).catch(e => console.error(e)));
    }
    Promise.all(promises).then(() => {
      window.scenes = scenes;
      if (scenes.length > 0) {
        window.scene = scenes[0];
        _self.currScene = window.scene;
        _self.puzzleLogic = window.scene.puzzleLogic;
        _self.afterSceneLoaded();
        if (_self.onLoad) {
          _self.onLoad(_self.currScene);
        }
      }
    }).catch(e => {
      console.error(e);
      window.scenes = scenes;
      if (scenes.length > 0) {
        window.scene = scenes[0];
        _self.currScene = window.scene;
        _self.afterSceneLoaded();
        if (_self.onLoad) {
          _self.onLoad(_self.currScene);
        }
      }
    });

    /*_self.puzzle.switchScene(window.scene, sceneManager, id, rootUrl, sceneFile,
        1, 1,
        (loadedScene, isFirstTime) => {
          _self.currScene = loadedScene.subScene;
            window.scene = _self.currScene;
          window.needFireAfterLoad = true;
          window.Kc[_self.id + '_scene'] = _self.currScene;
            if (_self.currScene) {
            if (isFirstTime) {
              _self.currScene.onPointerObservable.add(
                  (pointerInfo) => {_self.pointHandler(pointerInfo);});
              _self.currScene.activeCamera.attachControl(_self.getCanvas(),
                  true);
            }
              let logic = window.Kingfisher.FileTools.GetAbsoluteUrl(
                rootUrl + sceneFile.replace('.json', '_logic.js'));
            Axios.get(logic).then(function(res) {
              if (res && res.data) {
                if (window.puzzleDispose) {
                  window.puzzleDispose();
                }
                if (_self.puzzleLogic && _self.puzzleLogic.puzzleDispose) {
                  _self.puzzleLogic.puzzleDispose();
                }
                try {
                  window.Kingfisher.Runtime.Puzzle = window.Kingfisher.Runtime.PuzzleInterface;
                  window.onSceneLoaded = null;
                  let logic = res.data.replace('__sceneId', sceneFile);
                  eval(logic);
                  _self.puzzleLogic = window.puzzleLogic;
                  delete window.puzzleLogic;
                  _self.registerContext();
                  if (_self.currScene.puzzleLogic &&
                      _self.currScene.puzzleLogic.onSceneLoaded) {
                    _self.currScene.puzzleLogic.onSceneLoaded();
                  }
                  _self.afterSceneLoaded();
                } catch (e) {
                  console.error([res.data]);
                  console.error(e);
                }
                  if (_self.onLoad) {
                  _self.onLoad(_self.currScene, isFirstTime);
                }
              }
            }).catch(function() {
              if (_self.onLoad) {
                _self.onLoad(_self.currScene, isFirstTime);
              }
            });
          }
        });*/
  }
  changeCamera(cameraName) {
    const _self = this;
    _self.currScene.cameraArgsArray.forEach(camera => {
      if (camera.name === cameraName) {
        _self.currScene.activeCamera.focusOn(camera, 1);
      }
    });
  }
  afterSceneLoaded() {
    const _self = this;
    if (_self.options.camera) {
      _self.currScene.cameraArgsArray.forEach(camera => {
        if (camera.name === _self.options.camera) {
          _self.currScene.activeCamera.focusOn(camera, 1);
        }
      });
    }
    if (_self.options.puzzleEvent) {
      _self.issueEvent(_self.options.puzzleEvent);
    }
  }
  getCameras() {
    if (this.currScene) {
      return this.currScene.cameraArgsArray;
    } else {
      return [];
    }
  }
  getInfoPoints() {
    let result = {};
    if (this.infoPoints != null) {
      this.infoPoints.forEach(function (infoPoint) {
        let _infoPoint = {};
        Object.assign(_infoPoint, infoPoint);
        _infoPoint.url = _infoPoint.url.replace('resources/', 'resources://');
        let currCategory = _infoPoint.category;
        result[currCategory] = result[currCategory] || [];
        result[currCategory].push(_infoPoint);
      });
    }
    return result;
  }
  registerContext(scene) {
    let register = scene.puzzleLogic.registerContext;
    if (register) {
      register('contextPath', '');
      register('context', this);
      register('canvas', this.getCanvas());
      register('axios', Axios);
      register('kingfisher', window.Kingfisher);
      register('infoPoints', this.getInfoPoints());
    }
  }
  setSceneRenderEnabled(id, value, duration, callback) {
    let sceneManager = window.Kc[this.id + '_sceneManager'];
    if (sceneManager) {
      sceneManager.setRenderEnabled(id, value, duration, callback);
    }
  }
  initScene() {
    const _self = this;
    window.Kingfisher.Runtime.Puzzle = window.Kingfisher.Runtime.PuzzleInterface;
    this.puzzle = window.Kingfisher.Runtime.Puzzle;
    if (_self.options.msgSync) {
      try {
        _self.netMsgRouter = new window.Kingfisher.Runtime.NetMsgRouter(_self.options.group, _self.options.role, _self.findWsUrl());
      } catch (e) {
        console.error(e);
      }
    }
    this.createEngine();
    let sceneManager = window.Kc[_self.id + '_sceneManager'] || new window.Kingfisher.SceneManager(_self.getEngine());
    window.sceneManager = sceneManager;
    window.Kc[_self.id + '_sceneManager'] = sceneManager;
    window.Kingfisher.Database.IDBStorageEnabled = true;
    let engine = _self.getEngine();
    if (_self.options.sceneId) {
      _self.loadSceneById(_self.options.sceneId, '', window.Kf.listeners[_self.id].onLoad, window.Kf.listeners[_self.id].onTap);
    }
    engine.runRenderLoop(() => {
      try {
        let currEngine = _self.getEngine();
        if (currEngine && !currEngine.isDisposed) {
          try {
            _self.onRender(engine, sceneManager);
          } catch (e) {
            console.error(e);
          }
          sceneManager.rootScene.render();
        }
      } catch (e) {
        console.error(e);
      }
    });
  }
  findWsUrl() {
    if (develop) {
      return 'ws://dev.kingfisher.live:8000/ws';
    } else {
      let host = window.location.href.substring(window.location.href.indexOf('://') + 3);
      let isHttps = window.location.href.startsWith('https://');
      return (isHttps ? 'wss://' : 'ws://') + host.substring(0, host.indexOf('/')) + '/ws';
    }
  }
  requestFullscreen(elem) {
    try {
      if (elem.requestFullscreen) {
        elem.requestFullscreen();
      } else if (elem.mozRequestFullScreen) {
        elem.mozRequestFullScreen();
      } else if (elem.webkitRequestFullscreen) {
        elem.webkitRequestFullscreen();
      } else if (elem.msRequestFullscreen) {
        elem.msRequestFullscreen();
      }
    } catch (e) {
      console.log(e);
    }
  }
  exitFullscreen() {
    if (document.exitFullScreen) {
      document.exitFullScreen();
    } else if (document.mozCancelFullScreen) {
      document.mozCancelFullScreen();
    } else if (document.webkitExitFullscreen) {
      document.webkitExitFullscreen();
    } else if (document.msExitFullscreen) {
      document.msExitFullscreen();
    }
  }
  handleMessage(e) {
    if (typeof e.data == 'object') {
      let message = e.data;
      if (message.action === 'puzzleEvent') {
        if (window.Kf.listeners[this.id] && window.Kf.listeners[this.id].onEvent) {
          window.Kf.listeners[this.id].onEvent(message.event);
        }
      }
    }
  }
  init() {
    console.log('创建三维组件');
    let loadingTime = localStorage.getItem(this.id + '_loadingtime');
    if (loadingTime == null) {
      loadingTime = 10 * 1000;
    }
    this.expectedLoadingTime = loadingTime;
    window.initScene = () => {
      this.initScene();
    };
    window.addEventListener('message', this.handleMessage);
    function startLoadLib() {
      if (window.Kf && window.Kf.loadLib) {
        clearInterval(timer);
        window.Kf.loadLib('static/kingfisher.lib', '', 'initScene');
      }
    }
    let timer = setInterval(startLoadLib, 50);
  }
  onRender(engine, sceneManager) {
    if (window.Kf.listeners[this.id] && window.Kf.listeners[this.id].onRender) {
      window.Kf.listeners[this.id].onRender(engine, sceneManager);
    }
  }
  destroy() {
    try {
      console.log('销毁三维组件');
      let engine = this.getEngine();
      if (engine) {
        if (this.loadingTextTimer) {
          clearInterval(this.loadingTextTimer);
        }
        if (engine.loadingScreen) {
          engine.loadingScreen.hideLoadingUI();
        }
        engine.dispose();
        console.log('引擎已经销毁');
        delete window.Kc[this.id + '_engine'];
        delete window.Kc[this.id + '_scene'];
        delete window.Kc[this.id + '_sceneManager'];
        delete window.Kc[this.id + '_container'];
        delete window.Kf.listeners[this.id];
      }
      console.log('组件已经销毁');
    } catch (e) {
      console.error(e);
    }
  }
}